import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import theme from './theme.js';

export default function Footer(props) {
  // console.log(theme);
  console.log(props.user);
  var user = {};
  const userRolesString = userArg => {
    var ret = "";
    if (userArg.roles) {
      console.log(userArg);
      userArg.roles.forEach(role => {
        ret = ret.concat(role.name + ((role.is_main_role) ? "*" : "") + ", ")
      });
    } else {
      ret = "";
    }
    return ret;
  }
  if (props.user && props.user.email) {
    user = JSON.parse(props.user);
  }
  return (
    <ThemeProvider theme={theme}>

        {/* <Box px={{xs: 1, sm: 5}} py={{xs:3, sm: 5}} fullWidth> */}
            <Grid container style={{backgroundColor: theme.palette.primary.main, color: theme.palette.neutral.main}} justifyContent="space-evenly">
              <Grid item xs={12} sm={3}>
                <Box borderBottom={1}>
                  <Typography>
                    Hilfe
                  </Typography>
                </Box>
                <Box>
                  <Link href="/" >
                    <Typography sx={{color: "neutral.main"}}>
                      Kontakt
                    </Typography>
                  </Link>
                </Box>
                <Box>
                  { (user) &&
                  <Link href={"mailto:" + "incoming+w010-topas-topas-215-issue-@git.dkfz.de" + "?subject=<Fehlermeldung Titel>&body=" + encodeURIComponent("Benutzer:\t" + user.name + "\nGruppen:\t" + userRolesString(user) + "\nURL:\t\t" + props.root_url + "\nController:\t" + props.controller + "\nAction:\t\t" + props.action + "\n\n<Beschreibung des Problems, wie ist es zu reproduzieren, Screenshots, etc.>")} target="_blank" sx={{color: "neutral.main"}}>
                    <Typography >
                      Bugtracker/Fehlermeldung
                    </Typography>
                  </Link>}
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box borderBottom={1}>
                  <Typography >
                    Über
                  </Typography>
                </Box>
                <Box>
                  <Link href="https://www.nct-heidelberg.de/fuer-aerzte/service/studienzentrale.html" target="_blank" sx={{color: "neutral.main"}}>
                    <Typography >
                      Über die NCT Studienzentrale
                    </Typography>
                  </Link>
                </Box>
                <Box>
                  {/* <Link href="/" target="_blank" sx={{color: "neutral.main"}}> */}
                    <Typography >
                      Über NCT TOPAS
                    </Typography>
                  {/* </Link> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box textAlign="center" pt={{xs: 1, sm: 2}} pb={{xs: 1, sm: 0}}>
                  <Typography >
                    NCT Studienzentrale TOPAS (Trial Operations Planning and Administration System) @ {new Date().getFullYear()} V2.0.0
                  </Typography>
                </Box>
              </Grid>
            </Grid>
        {/* </Box> */}
    </ThemeProvider>
  )
}