import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientProgressionFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
        >
          {/* <TextField
            margin="dense"
            id="pseudonym"
            label="Pseudonym"
            value={obj.pseudonym}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          /> */}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        > 
        <TextField
          margin="dense"
          id="tumorid"
          label="Tumor-ID"
          value={obj.tumorid}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="examination_date"
          label="Untersuchungsdatum"
          value={obj.examination_date}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="date"
          size="small"
        />
        <TextField
          margin="dense"
          id="overall_assessment"
          label="Gesamtbeurteilung"
          value={obj.overall_assessment}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="state_primary_tumor"
          label="Status_Primärtumor"
          value={obj.state_primary_tumor}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="residual_tumor_primary_tumor"
          label="Residualtumor Primärtumor"
          value={obj.residual_tumor_primary_tumor}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="status_regional_lymph_nodes"
            label="Status regionäre LK"
            value={obj.status_regional_lymph_nodes}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="residual_tumor_lymphatic_nodes"
            label="Residualtumor LK"
            value={obj.residual_tumor_lymphatic_nodes}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="status_distant_metastases"
            label="Status Fernmetastasen"
            value={obj.status_distant_metastases}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="residua_distant_metastases"
            label="Residuen Fernmetastasen"
            value={obj.residua_distant_metastases}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="date_of_diagnosis_1"
            label="Diagnosedatum 1"
            value={(obj.date_of_diagnosis_1) ? moment(obj.date_of_diagnosis_1).format("DD.MM.YYYY") : null}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="metastasis_1"
            label="Metastase 1"
            value={obj.metastasis_1}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="distant_metastasis_localization_1"
            label="Lokalisation FM 1"
            value={obj.distant_metastasis_localization_1}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="residual_tumor_distant_metastasis_1"
            label="Residualtumor FM"
            value={obj.residual_tumor_distant_metastasis_1}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="date_of_diagnosis_2"
            label="Diagnosedatum 2"
            value={(obj.date_of_diagnosis_2) ? moment(obj.date_of_diagnosis_2).format("DD.MM.YYYY") : null}
            fullWidth
            variant="outlined"
            style={{zIndex: 2}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="metastasis_2"
            label="Metastase 2"
            value={obj.metastasis_2}
            fullWidth
            variant="outlined"
            style={{zIndex: 2}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="distant_metastasis_localization_2"
            label="Lokalisation FM 2"
            value={obj.distant_metastasis_localization_2}
            fullWidth
            variant="outlined"
            style={{zIndex: 2}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="residual_tumor_distant_metastasis_2"
            label="Residualtumor FM"
            value={obj.residual_tumor_distant_metastasis_2}
            fullWidth
            variant="outlined"
            style={{zIndex: 2}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="date_of_diagnosis_3"
            label="Diagnosedatum 3"
            value={(obj.date_of_diagnosis_3) ? moment(obj.date_of_diagnosis_3).format("DD.MM.YYYY") : null}
            fullWidth
            variant="outlined"
            style={{zIndex: 3}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="metastasis_3"
            label="Metastase 3"
            value={obj.metastasis_3}
            fullWidth
            variant="outlined"
            style={{zIndex: 3}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="distant_metastasis_localization_3"
            label="Lokalisation FM 3"
            value={obj.distant_metastasis_localization_3}
            fullWidth
            variant="outlined"
            style={{zIndex: 3}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="residual_tumor_distant_metastasis_3"
            label="Residualtumor FM"
            value={obj.residual_tumor_distant_metastasis_3}
            fullWidth
            variant="outlined"
            style={{zIndex: 3}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="date_of_diagnosis_4"
            label="Diagnosedatum 4"
            value={(obj.date_of_diagnosis_4) ? moment(obj.date_of_diagnosis_4).format("DD.MM.YYYY") : null}
            fullWidth
            variant="outlined"
            style={{zIndex: 4}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="metastasis_4"
            label="Metastase 4"
            value={obj.metastasis_4}
            fullWidth
            variant="outlined"
            style={{zIndex: 4}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="distant_metastasis_localization_4"
            label="Lokalisation FM 4"
            value={obj.distant_metastasis_localization_4}
            fullWidth
            variant="outlined"
            style={{zIndex: 4}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="residual_tumor_distant_metastasis_4"
            label="Residualtumor FM"
            value={obj.residual_tumor_distant_metastasis_4}
            fullWidth
            variant="outlined"
            style={{zIndex: 4}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="date_of_diagnosis_5"
            label="Diagnosedatum 5"
            value={obj.date_of_diagnosis_5}
            fullWidth
            variant="outlined"
            style={{zIndex: 5}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="metastasis_5"
            label="Metastase 5"
            value={obj.metastasis_5}
            fullWidth
            variant="outlined"
            style={{zIndex: 5}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="distant_metastasis_localization_5"
            label="Lokalisation FM 5"
            value={obj.distant_metastasis_localization_5}
            fullWidth
            variant="outlined"
            style={{zIndex: 5}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="residual_tumor_distant_metastasis_5"
            label="Residualtumor FM"
            value={obj.residual_tumor_distant_metastasis_5}
            fullWidth
            variant="outlined"
            style={{zIndex: 5}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="date_of_diagnosis_6"
            label="Diagnosedatum 6"
            value={obj.date_of_diagnosis_6}
            fullWidth
            variant="outlined"
            style={{zIndex: 6}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="metastasis_6"
            label="Metastase 6"
            value={obj.metastasis_6}
            fullWidth
            variant="outlined"
            style={{zIndex: 6}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="distant_metastasis_localization_6"
            label="Lokalisation FM 6"
            value={obj.distant_metastasis_localization_6}
            fullWidth
            variant="outlined"
            style={{zIndex: 6}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="residual_tumor_distant_metastasis_6"
            label="Residualtumor FM"
            value={obj.residual_tumor_distant_metastasis_6}
            fullWidth
            variant="outlined"
            style={{zIndex: 6}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="date_of_diagnosis_7"
            label="Diagnosedatum 7"
            value={obj.date_of_diagnosis_7}
            fullWidth
            variant="outlined"
            style={{zIndex: 7}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="metastasis_7"
            label="Metastase 7"
            value={obj.metastasis_7}
            fullWidth
            variant="outlined"
            style={{zIndex: 7}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="distant_metastasis_localization_7"
            label="Lokalisation FM 7"
            value={obj.distant_metastasis_localization_7}
            fullWidth
            variant="outlined"
            style={{zIndex: 7}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="residual_tumor_distant_metastasis_7"
            label="Residualtumor FM"
            value={obj.residual_tumor_distant_metastasis_7}
            fullWidth
            variant="outlined"
            style={{zIndex: 7}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
