import React, { Component } from 'react';
import { render, hydrate } from 'react-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { EcrfApplicationPDF } from './EcrfApplicationPDF.jsx';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LoadingButton from '@mui/lab/LoadingButton';
// import LoadingButton from '@mui/lab/LoadingButton';


export default class PDFLink extends Component {
  state = {
    loading: false,
  };

  download = (filename, url) => {
    const element = document.createElement('a');
    element.setAttribute('href', `${url}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  createAndDownloadPDF = (pdfContent, filename, divId, callback) => {
    setTimeout(() => {
      const link = (
        <div id={divId}>
          <PDFDownloadLink document={pdfContent} fileName={filename}>
            {({ blob, url, loading, error }) => {
              console.log(filename, blob, loading, url, error);
              if (!loading && blob != null) {
                // console.log(filename, blob);
                this.download(filename, URL.createObjectURL(blob));
                callback();
              }
            }}
          </PDFDownloadLink>
        </div>
      );
      const elem = document.createElement('div');
      document.getElementById('pdfButton').appendChild(elem);
      hydrate(link, elem);
    }, 500);
  };

  buildPDF = (filename) => {
    const { loading } = this.state;
    if (!loading) {
      this.setState({ loading: true }, () => {
        this.createAndDownloadPDF(this.generatePDF(), filename, `file`, () =>
          this.setState({ loading: false })
        );
      });
    }
  };

  generatePDF = () => {
    // CertificatePDF is a component that returns a PDF <Document />
    return <EcrfApplicationPDF {...this.props} />;
  };

  render() {
    const { loading } = this.state;
    const { obj, user } = this.props;
    // <LoadingButton
    //       size="small"
    //       onClick={handleClick}
    //       loading={loading}
    //       variant="outlined"
    //       disabled
    // >

    // </LoadingButton>
    console.log(obj)
    
    return (
      <LoadingButton
        id="pdfButton"
        loading={loading}
        variant="contained"
        // loadingIndicator="Loading…"
        size="small"
        onClick={() => this.buildPDF("Antrag_" + obj.ecrf_trial_configuration.short + "_" + user.name, obj, user)}
      >
        PDF zur Unterschrift herunterladen
      </LoadingButton>
  )}
}