import React, {useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import { useSnackbar } from 'notistack'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import DropZone from './DropZone.jsx';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Toolbar from '@mui/material/Toolbar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import BlockIcon from '@mui/icons-material/Block';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileUploadDialog from './FileUploadDialog.jsx';
import FilePdf from 'mdi-material-ui/FilePdfBox.js';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CommentIcon from '@mui/icons-material/Comment';
import GeneratePDF from './GeneratePDF.jsx';
import EcrfApplicationFields from './EcrfApplicationFields.jsx';
import EcrfApplicationStatusFields from './EcrfApplicationStatusFields.jsx';
import EditDialog from './EditDialog.jsx';
import EditNoteDialog from './EditNoteDialog.jsx';
import NewNoteDialog from './NewNoteDialog.jsx';
import TextField from './TextField.jsx';
import EnhancedTable from './EnhancedTable.jsx';
import ConfirmationDialog from './CustomComponents/ConfirmationDialog';
import ListOfFiles from './ListOfFiles.jsx';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: "#f5f5f5",
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function downloadFile(link) {
  window.open(link)
}

function handleUploadOpen(application) {
  props.handleUploadOpen(application);
}

const steps = [
  'Beantragt',
  'Account erstellt',
  'Studiendokumente (siehe unten) hochgeladen',
  'Geprüft und genehmigt',
  'Berechtigt',
];

const noteColumns = [
  {
   id: "entry_date",
   label: "Datum",
   numeric: false,
   disablePadding: false,
   date: true,
   type: "date",
  },
  {
   id: "note",
   label: "Notiz",
   numeric: false,
   disablePadding: false,
  },
 ];

export default function ListOfEcrfApplications(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const files = [{name: "bla", type: "xls"}, {name: "bla2", type: "docx"}, {name: "PdfTest", type: "pdf"}];
  const { user } = props;
  const [openDialogs, setOpenDialogs] = useState({fileUpload: false});
  const [selectedApplication, setSelectedApplication] = useState({});
  const [selectedIds, setSelectedIds] = useState({Notizen: []});
  const [files, setFiles] = useState({});
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationApplicationDelete, setConfirmationApplicationDelete] = useState(false);
  const [fileDelete, setFileDelete] = useState();
  const [applicationDelete, setApplicationDelete] = useState();
  const [expanded, setExpanded] = React.useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false)

  // const statusColors = {granted: '#00ff00', applied: '#ffff00', waiting: '#ffff00', denied: '#ff0000'};
  const bla = (data) => {
    setOpenDialogs({...openDialogs, fileUpload: null});
    props.handleApplicationFileUpload(data.ecrf_user);
  }

  const handleConfirmationDeleteFile = (file) => {
    setFileDelete(file);
    setConfirmation(true);
    // props.handleFileDelete(file);
  }

  const handleConfirmationDeleteApplication = (application) => {
    setApplicationDelete(application);
    setConfirmationApplicationDelete(true);
    // props.handleFileDelete(file);
  }

  const handleConfirm = (file) => {
    setConfirmation(false);
    props.handleFileDelete(file);
    setFileDelete();
  }

  const handleConfirmApplication = (application) => {
    setConfirmationApplicationDelete(false);
    props.handleDelete(application)
    setFileDelete();
  }

  const handleOpenEditApplication = (application) => {
    setOpenDialogs({...openDialogs, editApplication: true});
    setSelectedApplication({...application});
    // console.log("test");
  }

  const handleEditApplicationSave = application => {
    fetch('/ecrf_management/update_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_application: application,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        setOpenDialogs({...openDialogs, editApplication: false});
        // currentComponent.setState({user: data.ecrf_user});
        enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  const handleNewNoteSave = note => {
    console.log(note);
    const currentComponent = this;
    fetch('/polymorphic_notes/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note: note,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        setOpenDialogs({...openDialogs, ["newNote_" + note.noteable_id]: false});
        // handleClose("newNote");
        // currentComponent.setState({user: data.ecrf_user});
        enqueueSnackbar('Erfolgreich hinzugefügt.', {variant: 'success'});
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  const handleEditNoteSave = note => {
    console.log(note);
    const currentComponent = this;
    fetch('/polymorphic_notes/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note: note,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // handleClose("editStory Board");
        setOpenDialogs({...openDialogs, editNotizen: false});
        // currentComponent.setState({user: data.ecrf_user});
        enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  const handleDoubleClick = (event, obj, name) => {
    let newSelectedIds = {...selectedIds};
    newSelectedIds[name] = [obj.id];
    setSelectedIds(newSelectedIds);
    setOpenDialogs({...openDialogs, ["edit" + name]: true});
  }

  const handleDeleteNotes = event => {
    // const currentComponent = this;
    fetch('/polymorphic_notes/delete', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        polymorphic_note_ids: selectedIds["Notizen"],
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ user: data.ecrf_user });
        enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  const handleFileUpload = (name, user, application) => {
    console.log("test");
    if (files[name] == null || files[name].length == 0) {
      // catch empty save
      return;
    }
    // const currentComponent = this;
    const payload = new FormData();
    payload.append('file', files[name][0]);
    payload.append('name', name);
    payload.append('ecrf_user_id', user.id);
    payload.append('application_id', application.id);
    // payload.append('file_kind')

    fetch("/ecrf_management/application_file_upload", {
      method: 'POST',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: payload,
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data);
      if (data.response == 1) {
        props.handleApplicationFileUpload(data.ecrf_user);
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  const handleDrop = (name, application, acceptedFiles, rejectedFiles) => {
    // console.log(acceptedFiles, rejectedFiles, event);
    let newFiles = files;
    newFiles[name] = acceptedFiles;
    setFiles(newFiles);
    handleFileUpload(name, props.user, application)
    // this.setState({
    //   files: acceptedFiles
    // });
  }

  const handleChange = (event, meta=null) => {
    let newObj = obj;
    const target = event.target;
    let value = target.value;
    if (target.type == "checkbox") {
      const name = target.name;
      newObj[name] = meta;
    } else {
      const name = target.id || target.name;
      newObj[name] = value;
    }
    // console.log("newObj", newObj);
    setObj({...newObj});
  };

  // const handleSelected = (event, obj, name) => {
  //   console.log(event, obj, name);
  //   // let newSelectedIds = {...selectedIds};
  //   // newSelectedIds[name] = [obj.id]
  //   // setSelectedIds(newSelectedIds);
  // }

  // const handleDoubleClick = (event, obj, name) => {
  //   console.log(event, obj, name);
  //   // let newSelectedIds = {...selectedIds};
  //   // newSelectedIds[name] = [obj.id]
  //   // // this.setState({selectedIds: newSelectedIds});
  //   // setSelectedIds(newSelectedIds);
  //   // // this.handleOpen("edit" + name)
  //   // setOpenDialogs({...openDialogs, ["edit" + name]: true});
  // }

  const handleExpandedChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    props.handleChangeExpanded(panel);
  };

  const handleChangeChecked = (file_id, application_id) => {
    // const currentComponent = this;
    const ecrf_user = props.user;
    console.log(ecrf_user);
    // const application = selectedUser.ecrf_applications.find(app => app.files.map(file => file.id).includes(file_id));

    fetch('/ecrf_management/toggle_application_file_checked', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        file_id: file_id,
        ecrf_user_id: ecrf_user.id,
        application_id: application_id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ user: data.ecrf_user });
        enqueueSnackbar('Erfolgreich akzeptanz zurückgenommen.', {variant: 'success'});
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  const testFunction = event => {
    console.log("test");
  }

  // handleFileDelete = file => {
  //   const currentComponent = this;
  //   fetch('/ecrf_management/' + this.state.user.id + '/delete_file', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-Transaction': 'POST Example',
  //       'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  //     },
  //     body: JSON.stringify({
  //       file_id: file.id,
  //       // trial_id: this.state.trial.id,
  //     }),
  //     credentials: 'include',
  //   })
  //   .then(function(response){return response.json();})
  //   .then(function(data){
  //     console.log(data);
  //     if (data.response == 1) {
  //       // var newTrial = data.trial;
  //       currentComponent.setState({ user: data.ecrf_user });
  //       currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
  //     } else {
  //       currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
  //     }
  //   });
  // }

  const requiredFields = [];
  const accountStatusOptions =  [{value: "in preparation", label: "in Vorbereitung", tooltipText: "Antragsteller bereitet Antrag/Daten vor", backgroundColor: "#FFFFFF", stepnr: 1}, {value: "checking", label: "zu prüfen durch DM", tooltipText: "Datensatz muss von DM geprüft werden", backgroundColor: "#E29393", stepnr: 2}, {value: "user interaction necessary", label: "User Eingabe nötig", tooltipText: "Antragsteller muss Antrag überarbeiten", backgroundColor: "#37ADF5", stepnr: 2}, {value: "ready", label: "Bereit", tooltipText: "Antrag ist genehmigt, aber Prüfzentrum oder Studie ist nicht freigeschaltet", backgroundColor: "#f5b556", stepnr: 3}, {value: "active", label: "Aktiv", tooltipText: "eCRF-Account ist freigeschaltet und aktiv.", backgroundColor: "#76d658", stepnr: 4}, {value: "locked", label: "Gesperrt", tooltipText: "eCRF-Account ist vom DM gesperrt worden", backgroundColor: "#FF4917", stepnr: 3}, {value: "misc", label: "Sonstiges", tooltipText: "Bitte wenden Sie sich an das DM für Fragen.", backgroundColor: "#ADADAD", stepnr: 3}];
  // console.log(props.trials);
  // const appliedTrialIds = (props.user.ecrf_applications) ? props.user.ecrf_applications.map(appl => appl.ecrf_trial_configuration.id) : [];
  const trialsOptions = props.trials.map(trial => {return {value: trial.id, label: trial.short}});
  let functionsOptions = [];
  props.trials.forEach(conf => {
    // let ret = [];
    const func_ret = conf.ecrf_trial_functions.map(func => {
      return ({value: func.id, label: func.name});
    });
    functionsOptions = {...functionsOptions, [conf.id]: func_ret};
  })
  let rolesOptions = {};
  props.trials.forEach(conf => {
    let func_ret = [];
    conf.ecrf_trial_functions.forEach(func => {
      const roles_ret = func.ecrf_trial_roles.map(role => {
        return ({value: role.id, label: role.name});
      })
      func_ret = {...func_ret, [func.id]: roles_ret};
    });
    rolesOptions[conf.id] = func_ret;
  })
  let sitesOptions = {}
  props.trials.forEach(conf => {
    const sites = conf.trial.sites.map(site => {
      return {value: site.id, label: site.inst_description};
    })
    sitesOptions[conf.id] = sites;
  })
  const applicationSelectOptions = {ecrf_trial_configuration_id: trialsOptions || "", ecrf_trial_role_id: rolesOptions, site_id: sitesOptions, ecrf_trial_function_id: functionsOptions, status: accountStatusOptions}
  // console.log(applicationSelectOptions);
  const applicationListItems = user.ecrf_applications.map(application => {
    const stat = (application.status) ? props.statusOptions.find(opt => opt.value == application.status) : {};
    const bg = (stat) ? stat.backgroundColor: "#FFFFFF";
    const statusText = (stat) ? stat.label : "";
    const statusTooltip = (stat) ? stat.tooltipText : "";
    const selectedNotes = (application.polymorphic_notes) ? application.polymorphic_notes.filter(note => selectedIds["Notizen"].includes(note.id)) : [];
    // console.log(props.handleChangeSubmitted);
    // if (!props.admin || (props.admin && (application.status == "applied" || application.status == "granted"))) {
    console.log(application.status, props.admin, application.status != "ready", application.status != "active", application.status != "locked", ((props.admin) && (application.status != "ready") && (application.status != "active") && (application.status != "locked")), ((!props.admin && (application.status != "in preparation")) || ((props.admin) && (application.status != "ready") && (application.status != "active") && (application.status != "locked"))) );
    var appl = [];
    var delegation_log = [];
    var training = [];
    var applLink = "";
    var delegationLogLink = "";
    var trainingLink = "";
    var marvin_training = [];
    var marvinTrainingLink = "";
    // console.log(application, user.annotated_attached_files.filter(aaf => (aaf.version === application.ecrf_trial_configuration.marvin_version)));
    // if (user.annotated_attached_files && user.annotated_attached_files.length > 0  && user.annotated_attached_files.filter(aaf => (aaf.file_kind == "marvin_training" && aaf.version === application.ecrf_trial_configuration.marvin_version)).length > 0) {
    //   marvin_training = user.annotated_attached_files.filter(aaf => (aaf.file_kind == "marvin_training" && aaf.version === application.ecrf_trial_configuration.marvin_version))
    //   if (marvin_training.length > 0) {
    //     marvinTrainingLink = ((window.location.hostname == "topas.nct-heidelberg.de" || window.location.hostname == "topastest.nct-heidelberg.de") ? "https://" + window.location.hostname : "http://" + window.location.hostname + ((window.location.hostname == "localhost") ? ":3000" : null)) + "/ecrf_management/download_ecrf_file/" + marvin_training[0].file.id;
    //   }
    // }
    // console.log(marvin_training[0].version, application.ecrf_trial_configuration, marvin_training[0].version === application.ecrf_trial_configuration.marvin_version)
    if (application.attached_application_files && application.attached_application_files.length > 0) {
      appl = application.attached_application_files.filter(aaf => aaf.file_kind == "application");
      if (appl.length > 0) {
        applLink = ((window.location.hostname == "topas.nct-heidelberg.de" || window.location.hostname == "topastest.nct-heidelberg.de") ? "https://" + window.location.hostname : "http://" + window.location.hostname + ((window.location.hostname == "localhost") ? ":3000" : null)) + "/ecrf_management/download_ecrf_file/" + appl[0].id;
      }
      delegation_log = application.attached_application_files.filter(aaf => aaf.file_kind == "delegation_log");
      if (delegation_log.length > 0) {
        delegationLogLink = ((window.location.hostname == "topas.nct-heidelberg.de" || window.location.hostname == "topastest.nct-heidelberg.de") ? "https://" + window.location.hostname : "http://" + window.location.hostname + ((window.location.hostname == "localhost") ? ":3000" : null)) + "/ecrf_management/download_ecrf_file/" + delegation_log[0].id;
      }
      training = application.attached_application_files.filter(aaf => aaf.file_kind == "trial_training");
      if (training.length > 0) {
        trainingLink = ((window.location.hostname == "topas.nct-heidelberg.de" || window.location.hostname == "topastest.nct-heidelberg.de") ? "https://" + window.location.hostname : "http://" + window.location.hostname + ((window.location.hostname == "localhost") ? ":3000" : null)) + "/ecrf_management/download_ecrf_file/" + training[0].id;
      }
    }
    console.log(applLink, delegationLogLink, trainingLink, marvinTrainingLink);
    console.log(appl, delegation_log, training)
    return (
      // <ListItem button>
      <>
        {confirmation ?
          <ConfirmationDialog
            open={confirmation}
            title={fileDelete.filename}
            text={'Sind Sie sicher, dass Sie dieses Dokument löschen wollen?'}
            data={fileDelete}
            handleConfirm={() => handleConfirm(fileDelete)}
            handleClose={() => setConfirmation(false)}
          /> : null}
        {confirmationApplicationDelete ?
          <ConfirmationDialog
            open={confirmationApplicationDelete}
            title={""}
            text={'Sind Sie sicher, dass Sie diesen Antrag löschen wollen?'}
            // data={fileDelete}
            handleConfirm={() => handleConfirmApplication(applicationDelete)}
            handleClose={() => setConfirmationApplicationDelete(false)}
          /> : null}

        <Accordion expanded={props.expandedApplication === application.id} onChange={() => props.handleChangeExpanded(application.id)}>
     
          <AccordionSummary expandIcon={<ExpandMoreIcon />} >
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={3}>
                <Typography variant="h6" color="inherit" noWrap style={{marginLeft: 5, fontWeight: 'bold'}}>
                  Studie {application.ecrf_trial_configuration.short}: {application.ecrf_trial_role.name}
                </Typography>
              </Grid>

              { (!props.admin) &&
              <Grid item xs={2}>
                <Tooltip title={statusTooltip}>
                  <Typography variant="h7" color="inherit" noWrap style={{marginLeft: 5, fontWeight: 'bold'}}>
                    {statusText}
                  </Typography>
                </Tooltip>
              </Grid>}
              { (props.admin) &&
              <Grid item xs={2}>
                <TextField
                  margin="dense"
                  id="status"
                  label="Status"
                  value={application.status}
                  fullWidth
                  // style={{zIndex: 988}}
                  handleChange={props.handleChangeApplicationStatus}
                  // required={props.requiredFields.includes("short")}
                  disabled={false}
                  // (readOnly || (props.disabledFields && props.disabledFields.includes("status"))) && (props.admin == null || props.admin == false)
                  type="select"
                  options={props.statusOptions}
                  size="small"
                  targetId={application.id}
                />
              </Grid>}
              <Grid item xs={5}>
                {/* <Box>
                  <Stepper activeStep={stat.stepnr} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box> */}
              </Grid>
              <Box display="flex" alignItems="left">
                <Tooltip title="Antrag löschen">
                    <Button 
                      variant="contained"
                      onClick={() => handleConfirmationDeleteApplication(application)}
                      size="small"
                      sx={{marginRight: '20px'}}
                      disabled={!((!props.admin && (application.status == "in preparation")) || ((props.admin) && (application.status != "ready") && (application.status != "active") && (application.status != "locked")))}
                    > 
                      Antrag löschen
                    </Button>
                </Tooltip>
              </Box>

            </Grid>
            
          </AccordionSummary>
            <Grid container direction="row" justify="center" alignItems="center">
              { (openDialogs.fileUpload && openDialogs.fileUpload == application.id) &&
                <FileUploadDialog
                  open={(openDialogs.fileUpload) && openDialogs.fileUpload == application.id}
                  saveUrl={'/ecrf_management/' + application.id + '/upload_application'}
                  handleFileUpload={bla}
                  handleClose={() => setOpenDialogs({...openDialogs, fileUpload: null})}
                />
              }
             
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" >
                  { (props.admin && application.attached_application_file) && 
                    <Tooltip title="Antrag editieren"  style={{marginRight: 5}}>
                      <IconButton id={application.id} edge="end" aria-label="delete" onClick={() => handleOpenEditApplication(application)}>
                        <EditIcon/>
                      </IconButton>
                    </Tooltip>
                  }
                  {/* { (application.attached_application_file) &&
                    <Tooltip title="Unterschriebenen Antrag herunterladen">
                      <IconButton id={application.id} edge="end" aria-label="delete" onClick={() => downloadFile(application.attached_application_file.link)}>
                        <FilePdf/>
                      </IconButton>
                    </Tooltip>
                  } */}
                  {/* { (props.handleDeleteApplicationFile && i(application.status != "ready" && application.status != "active") && application.attached_application_file ) &&
                      <Tooltip title="Hochgeladenen Antrag löschen">
                        <IconButton id={application.id} edge="end" aria-label="delete" >
                          <Button 
                            variant="contained"
                            onClick={() => props.handleDeleteApplicationFile(application)}
                            size="small"
                          > 
                            Hochgeladenen Antrag löschen
                          </Button>
                        </IconButton>
                      </Tooltip>
                  } */}
                  {/* { (props.handleRemove && (application.status == "active") && !application.applied_for_removal) &&
                      <Tooltip title="Löschung beantragen">
                        <IconButton id={application.id} edge="end" aria-label="delete" onClick={() => props.handleRemove(application)} >
                          <RemoveIcon />
                        </IconButton>
                      </Tooltip>
                  }
                  { (props.handleRemove && !props.admin && (application.status != "ready" && application.status != "active") && application.applied_for_removal) &&
                      <Tooltip title="Löschungsantrag zurückziehen">
                        <IconButton id={application.id} edge="end" aria-label="delete" onClick={() => props.handleRemove(application)} >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                  } */}
                  {/* { (!props.admin && (application.status != "ready" && application.status != "active") && !application.attached_application_file) &&
                      <Tooltip title="Unterschriebenen Antrag hochladen">
                        <IconButton id={application.id} edge="end" aria-label="upload" onClick={() => setOpenDialogs({...openDialogs, fileUpload: application.id})} disabled={props.disabled || application.status == "granted"}>
                          <FileUploadIcon />
                        </IconButton>
                      </Tooltip>
                  } */}
                    <>
                      {/* <br/> */}
                      
                      
                    </>
                </Box>
              </Grid>
              <List>
              Weitere Schritte zur Beantragung

                
                <ListItem>
                  <ListItemText primary="1. Antrag zur Unterschrift herunterladen" secondary={
                  <>
                  <Tooltip title="Antrag zur Unterschrift herunterladen">
                    <IconButton id={application.id} edge="end" aria-label="delete" >
                      <GeneratePDF
                        obj={application}
                        user={user}
                      />
                    </IconButton>
                  </Tooltip>
                  </>
                  } />
                </ListItem>
                <ListItem>
                  <ListItemText primary={"2. Antrag unterschreiben (elektronisch oder \"wet-ink\")"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary={
                      <Box >
                      3. Unterschriebenen Antrag hochladen
                      { (props.admin && appl && appl[0]) &&
                        <FormControlLabel control={<Switch size="small" checked={appl && appl[0] && appl[0].checked} onChange={() => handleChangeChecked(appl[0].id, application.id)}/>} label="Geprüft und Akzeptiert" />
                      }
                      </Box>
                    } 
                  secondary={
                    <Box sx={{border: 1, padding: '5px'}}>
                      { (appl.length == 0) &&
                        <>
                          <Box display="flex" alignItems="left">
                            <DropZone
                              onDrop={(acceptedFiles, rejectedFiles) => handleDrop("application", application, acceptedFiles, rejectedFiles)}
                              noFile
                            />
                          </Box>
                          {/* <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", flexShrink: true, flexWrap: "nowrap", alignItems: "center", paddingBottom: '20px'}}>
                            <Button
                              onClick={() => handleFileUpload("application", props.user, application)}
                              variant="contained"
                              color="primary">
                              Speichern
                            </Button>
                          </Box> */}
                        </>
                      }
                      { (application.attached_application_files && application.attached_application_files.length > 0 && application.attached_application_files.filter(aaf => aaf.file_kind == "application") && appl && appl[0]) &&
                      <List component="nav" aria-label="secondary mailbox folders">
                      <ListItem
                        // key={value}
                        secondaryAction={
                          <Box>
                            { (!appl[0].checked) &&
                            <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                              <Typography style={{fontWeight: '300', fontSize: '15px', marginLeft: 5}} color="success">
                              erfolgreich hochgeladen
                            </Typography>
                            <IconButton edge="end" id={appl[0].id} aria-label="delete" onClick={() => handleConfirmationDeleteFile(appl[0])} disabled={appl[0].checked}>
                              <DeleteIcon />
                            </IconButton>
                            </Box>
                            }
                            { (appl[0].checked) &&
                              <CheckIcon color="success"/>
                            }
                          </Box>
                        }
                        disablePadding
                      >
                        <ListItemLink href={applLink} target="_blank" rel="noreferrer noopener">
                          <ListItemIcon>
                            <PictureAsPdfIcon/>
                          </ListItemIcon>
                          <ListItemText primary={appl[0].filename} />
                        </ListItemLink>
                        </ListItem>
                      </List> 
                      }
                    </Box>
                    }
                   
                  />
                  
                </ListItem>
                <ListItem>
                  <ListItemText 
                    primary={
                      <Box >
                        4. Personal- und Delegierungsliste hochladen
                        { (props.admin && delegation_log && delegation_log[0]) &&
                          <FormControlLabel control={<Switch size="small" checked={delegation_log && delegation_log[0] && delegation_log[0].checked} onChange={() => handleChangeChecked(delegation_log[0].id, application.id)}/>} label="Geprüft und Akzeptiert" />
                        }
                      </Box> }
                    secondary={
                    <Box sx={{border: 1, padding: '5px'}}>
                      { (delegation_log.length == 0) &&
                        <>
                          <Box display="flex" alignItems="left">
                            <DropZone
                              onDrop={(acceptedFiles, rejectedFiles) => handleDrop("delegation_log", application, acceptedFiles, rejectedFiles)}
                              noFile
                            />
                          </Box>
                          {/* <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", flexShrink: true, flexWrap: "nowrap", alignItems: "center", paddingBottom: '20px'}}>
                            <Button
                              onClick={() => handleFileUpload("delegation_log", props.user, application)}
                              variant="contained"
                              color="primary">
                              Speichern
                            </Button>
                          </Box> */}
                        </>
                      }
                      { (application.attached_application_files && application.attached_application_files.length > 0 && application.attached_application_files.filter(aaf => aaf.file_kind == "delegation_log") && delegation_log && delegation_log[0]) &&
                      <List component="nav" aria-label="secondary mailbox folders">
                        <ListItem
                          // key={value}
                          secondaryAction={
                            <Box>
                              { (!delegation_log[0].checked) &&
                              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                              <Typography style={{fontWeight: '300', fontSize: '15px', marginLeft: 5}} color="success">
                                erfolgreich hochgeladen
                              </Typography>
                              <IconButton edge="end" id={delegation_log[0].id} aria-label="delete" onClick={() => handleConfirmationDeleteFile(delegation_log[0])} disabled={delegation_log[0].checked}>
                                <DeleteIcon />
                              </IconButton>
                              </Box>
                              }
                              { (delegation_log[0].checked) &&
                                <CheckIcon color="success"/>
                              }
                            </Box>
                          }
                          disablePadding
                        >
                          <ListItemLink href={delegationLogLink} target="_blank" rel="noreferrer noopener">
                            <ListItemIcon>
                              <PictureAsPdfIcon/>
                            </ListItemIcon>
                            <ListItemText primary={delegation_log[0].filename} />
                          </ListItemLink>
                        </ListItem>
                      </List> 
                      }
                    </Box>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary={
                      <Box >
                        5. Studienspezifisches Log of Training hochladen
                        { (props.admin && training && training[0]) &&
                          <FormControlLabel control={<Switch size="small" checked={training && training[0] && training[0].checked} onChange={() => handleChangeChecked(training[0].id, application.id)}/>} label="Geprüft und Akzeptiert" />
                        }
                      </Box> } secondary={
                    <Box sx={{border: 1, padding: '5px'}}>
                      { (training.length == 0) &&
                        <>
                          <Box display="flex" alignItems="left">
                            <DropZone
                              onDrop={(acceptedFiles, rejectedFiles) => handleDrop("trial_training", application, acceptedFiles, rejectedFiles)}
                              noFile
                            />
                          </Box>
                          {/* <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", flexShrink: true, flexWrap: "nowrap", alignItems: "center", paddingBottom: '20px'}}>
                            <Button
                              onClick={() => handleFileUpload("trial_training", props.user, application)}
                              variant="contained"
                              color="primary">
                              Speichern
                            </Button>
                          </Box> */}
                        </>
                      }
                      { (application.attached_application_files && application.attached_application_files.length > 0 && training.length > 0) &&
                        <List component="nav" aria-label="secondary mailbox folders">
                          <ListItem
                          // key={value}
                          secondaryAction={
                            <Box>
                              { (!training[0].checked) &&
                              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                              <Typography style={{fontWeight: '300', fontSize: '15px', marginLeft: 5}} color="success">
                                erfolgreich hochgeladen
                              </Typography>
                              <IconButton edge="end" id={training[0].id} aria-label="delete" onClick={() => handleConfirmationDeleteFile(training[0])} disabled={training[0].checked}>
                                <DeleteIcon />
                              </IconButton>
                              </Box>
                              }
                              { (training[0].checked) &&
                                <CheckIcon color="success"/>
                              }
                            </Box>
                          }
                          disablePadding
                        >
                          <ListItemLink href={trainingLink} target="_blank" rel="noreferrer noopener">
                            <ListItemIcon>
                              <PictureAsPdfIcon/>
                            </ListItemIcon>
                            <ListItemText primary={training[0].filename} />
                          </ListItemLink>
                          </ListItem>
                        </List>
                      }
                    </Box>
                    
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="6. Antragstellung vorläufig abgeschlossen. Sie werden vom eCRF Account Support per E-Mail benachrichtigt." />
                </ListItem>
                <ListItem>
                  <ListItemText primary={"7. Marvin eLearning absolvieren"} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={"8. Bestätigung, dass das MARVIN eLearning durchgeführt wurde: Klicken Sie dazu den Button unten oder senden Sie eine E-Mail an den eCRF Account Support."} secondary={
                    (application.marvin_training_completed == false || application.marvin_training_completed == null) ?
                    <Tooltip title="Marvin Training abgeschlossen">
                      <LoadingButton
                        id="pdfButton"
                        loading={notificationLoading}
                        variant="contained"
                        // loadingIndicator="Loading…"
                        size="small"
                        onClick={() => props.handleSendNotification(user, application)}
                      >
                        Das Marvin Training wurde absolviert
                      </LoadingButton>
                    </Tooltip>
                    :
                    <CheckIcon color="success"/>
                    // <Box sx={{border: 1, padding: '5px'}} 
                    //   { (marvin_training.length == 0) &&
                    //   <>
                    //   <Box display="flex" alignItems="left">
                    //     <DropZone
                    //       onDrop={(acceptedFiles, rejectedFiles) => handleDrop("marvin_training", acceptedFiles, rejectedFiles)}
                    //     />
                    //   </Box>
                    //   <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", flexShrink: true, flexWrap: "nowrap", alignItems: "center", paddingBottom: '20px'}}>
                    //     <Button
                    //       onClick={() => handleFileUpload("marvin_training", props.user, application)}
                    //       variant="contained"
                    //       color="primary">
                    //       Speichern
                    //     </Button>
                    //   </Box>
                    //   </>
                    //   }
                    //   { (user.annotated_attached_files && user.annotated_attached_files.length > 0 && marvin_training.length > 0) &&
                      // <List component="nav" aria-label="secondary mailbox folders">
                      //   <ListItem
                      //     // key={value}
                      //     secondaryAction={
                      //       <Box>
                      //         { (!marvin_training[0].checked) &&
                      //         <IconButton edge="end" id={marvin_training[0].file.id} aria-label="delete" onClick={() => handleConfirmationDeleteFile(marvin_training[0].file)} disabled={marvin_training[0].checked}>
                      //           <DeleteIcon />
                      //         </IconButton>
                      //         }
                      //         { (marvin_training[0].checked) &&
                      //           <CheckIcon color="success"/>
                      //         }
                      //       </Box>
                      //     }
                      //     disablePadding
                      //   >
                      //     <ListItemLink href={marvinTrainingLink} target="_blank" rel="noreferrer noopener">
                      //       <ListItemIcon>
                      //         <PictureAsPdfIcon/>
                      //       </ListItemIcon>
                      //       <ListItemText primary={marvin_training[0].file.filename} />
                      //     </ListItemLink>
                      //   </ListItem>
                      // </List> 
                    //   }
                    // </Box>
                    }
                  />
                </ListItem>
              </List>
            {/* <Grid item xs={12}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                <Typography style={{fontWeight: '300', fontSize: '20px', marginLeft: 5, width: '90%'}}>
                  {application.ecrf_trial_configuration.short} Antrag/Nachweise
                </Typography>
                <Toolbar
                  variant='dense'
                >
                  <Tooltip title="Datei hochladen">
                    <IconButton aria-label="Edit" onClick={() => setOpenDialogs({...openDialogs, fileUpload: application.id})}>
                      <Button 
                        variant="contained"
                        onClick={() => setOpenDialogs({...openDialogs, fileUpload: application.id})}
                        size="small"
                      > 
                        Nachweis hochladen
                      </Button>
                    </IconButton>
                  </Tooltip>
                </Toolbar>
              </Box>
              { (props.admin) &&
              <FormControlLabel control={<Switch size="small" checked={application.accepted_trial_documentation} onChange={() => props.handleChangeAcceptedTrialDocumentation(application.id)}/>} label="Alle Dokumente sind vollständig und akzeptiert" />}

            </Grid> */}
            { (props.admin) &&
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="nowrap" alignItems="center">
                <Typography style={{fontWeight: '300', fontSize: '20px', marginLeft: 5, width: '90%'}}>
                  {application.ecrf_trial_configuration.short} Antrag/Nachweise
                </Typography>
                <Toolbar
                  variant='dense'
                >
                  <Tooltip title="Datei hochladen">
                    <IconButton aria-label="Edit" onClick={() => setOpenDialogs({...openDialogs, fileUpload: application.id})}>
                      <Button 
                        variant="contained"
                        onClick={() => setOpenDialogs({...openDialogs, fileUpload: application.id})}
                        size="small"
                      > 
                        Nachweis hochladen
                      </Button>
                    </IconButton>
                  </Tooltip>
                </Toolbar>
              </Box>
              
              <FormControlLabel control={<Switch size="small" checked={application.accepted_trial_documentation} onChange={() => props.handleChangeAcceptedTrialDocumentation(application.id)}/>} label="Alle Dokumente sind vollständig und akzeptiert" />
              <ListOfFiles 
                associatedObjectId={application.id}
                files={application.files}
                admin={props.admin}
                checkable
                submittable
                handleDelete={props.handleFileDelete}
                handleFileDelete={props.handleFileDelete}
                disabled={application.status == "ready" || application.status == "active"} 
                submitted_file_ids={application.submitted_file_ids || []} 
                checked_file_ids={application.checked_file_ids || []} 
                handleChangeChecked={props.handleChangeChecked}
                handleChangeSubmitted={props.handleChangeSubmitted}
                controller="ecrf_management"
                action="old_download_ecrf_file"
              />
            </Grid>}
          </Grid>
        </Accordion>
      { (props.admin) &&
        <>
      <Grid item xs={12}>
        { (openDialogs["newNote_" + application.id]) &&
          <NewNoteDialog
            // handleOpen={this.props.handleOpen}
            noteableId={application.id}
            noteableType={"EcrfApplication"}
            // handleClose={() => this.handleClose("newNote")}
            handleClose={() => setOpenDialogs({...openDialogs, ["newNote_" + application.id]: false})}
            handleSave={handleNewNoteSave}
            // trialId={this.state.trial.id}
            // selectedSite={selectedSitesWithStatus[0]}
            // selectedDepartment={this.state.selectedDepartments[0]}
            open={openDialogs["newNote_" + application.id]}
          />
        }
        { (selectedNotes.length == 1 && openDialogs["editNotizen"]) &&
          <EditNoteDialog
            open={openDialogs["editNotizen"]}
            // trialId={this.state.trial.id}
            // handleClose={() => this.handleClose("editNotizen")}
            handleClose={() => setOpenDialogs({...openDialogs, editNotizen: false})}
            // sites={this.state.sites}
            handleSave={handleEditNoteSave}
            // this.selectedSites[0].inst_description
            selectedNote={selectedNotes[0]}
          />
        }
        <EnhancedTable
          title={"Notizen: " + application.ecrf_trial_configuration.short}
          data={(application.polymorphic_notes) ? application.polymorphic_notes : []}
          columns={noteColumns}
          // singleSelect={!this.state.multipleSelectMode}
          multipleSelectable
          selectedIds={selectedIds["Notizen"]}
          selectedObjects={selectedNotes}
          onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => setSelectedIds({...selectedIds, "Notizen": allRowsSelected})}
          handleAddClick={() => setOpenDialogs({...openDialogs, ["newNote_" + application.id]: true})}
          // handleInfoClick={this.handleInfoSiteClick}
          // handleEditClick={this.handleEditSiteClick}
          // handleRemoveClick={this.handleRemoveSiteClick}
          handleDeleteClick={handleDeleteNotes}
          // handleFilterClick={this.handleSitesFilterClick}
          // filters={this.state.siteFilters}
          rowsPerPage={50}
          orderBy="entry_date"
          order="desc"
          onDoubleClick={(event, obj) => handleDoubleClick(event, obj, "Notizen")}
          // markedIds={this.state.selectedDepartmentsSitesIds}
          // onFilterChange={this.handleSitesFilterChange}
          allData={application.polymorphic_notes}
          selectable
          expanded={true}
          // handleExpandChange={this.handleExpandChange}
          superType="EcrfTrialConfiguration"
        />
      </Grid>
      </>
      }
   </>
    )}
  );
  return (
    <div className={classes.root}>
      { (openDialogs.editApplication) &&
        <EditDialog
          title="Antrag editieren"
          obj={selectedApplication}
          open={openDialogs.editApplication}
          handleSave={handleEditApplicationSave}
          handleClose={() => setOpenDialogs({...openDialogs, editApplication: false})}
          Fields={EcrfApplicationFields}
          requiredFields={requiredFields}
          selectOptions={applicationSelectOptions}
          admin={props.admin}
        />
      }
      <List dense>
        {applicationListItems}
      </List>
    </div>
  );
}
