import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientRadiotherapyFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row"
            >
              {/* <TextField
                margin="dense"
                id="pseudonym"
                label="Pseudonym"
                value={obj.pseudonym}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              /> */}
              <TextField
                margin="dense"
                id="tumorid"
                label="Tumor-ID"
                value={obj.tumorid}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="therapy_place"
                label="Therapieort"
                value={obj.therapy_place}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="intension"
                label="Intention"
                value={obj.intension}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="operation_role"
                label="Stellung Op"
                value={obj.operation_role}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
               <TextField
                margin="dense"
                id="begin"
                label="Beginn-Datum"
                value={moment(obj.begin).format("YYYY-MM-DD")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              <TextField
                margin="dense"
                id="end"
                label="Ende-Datum"
                value={moment(obj.end).format("YYYY-MM-DD")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              <TextField
                margin="dense"
                id="end_reason"
                label="Grund Ende"
                value={obj.end_reason}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="abort_reason"
                label="Abbruchsgrund"
                value={obj.abort_reason}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="total_dose"
                label="Gesamtdosis"
                value={obj.total_dose}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="begin_radiation_1"
                label="Beginn Einzelbestrahlung 1"
                value={moment(obj.begin_radiation_1).format("YYYY-MM-DD")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              <TextField
                margin="dense"
                id="end_radiation_1"
                label="Ende Einzelbestrahlung 1"
                value={moment(obj.end_radiation_1).format("YYYY-MM-DD")}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              <TextField
                margin="dense"
                id="route_of_administration_1"
                label="Applikationsart 1"
                value={obj.route_of_administration_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="boost_1"
                label="Boostbestrahlung 1"
                value={obj.boost_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiated_tumor_1"
                label="Tumor bestrahlt 1"
                value={obj.radiated_tumor_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="radiated_distant_metastasis_1"
                label="Fernmetastase bestrahlt 1"
                value={obj.radiated_distant_metastasis_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="localization_distant_metastasis_1"
                label="Lokalisation Fernmetastase 1"
                value={obj.localization_distant_metastasis_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="target_zone_1"
                label="Zielgebiet 1"
                value={obj.target_zone_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="laterality_1"
                label="Seite 1"
                value={obj.laterality_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiation_lymph_node_region_1"
                label="Radiatio Lymphknotenregion 1"
                value={obj.radiation_lymph_node_region_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="technique_1"
                label="Technik 1"
                value={obj.technique_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiation_type_1"
                label="Strahlenart 1"
                value={obj.radiation_type_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="single_dose_1"
                label="Einzeldosis 1"
                value={obj.single_dose_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="applied_dose_1"
                label="Applizierte Dosis 1"
                value={obj.applied_dose_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="contingents_1"
                label="Anzahl Fraktionen 1"
                value={obj.contingents_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="begin_radiation_2"
                label="Beginn Einzelbestrahlung 2"
                value={moment(obj.begin_radiation_2).format("YYYY-MM-DD")}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              <TextField
                margin="dense"
                id="end_radiation_2"
                label="Ende Einzelbestrahlung 2"
                value={moment(obj.end_radiation_2).format("YYYY-MM-DD")}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              <TextField
                margin="dense"
                id="route_of_administration_2"
                label="Applikationsart 2"
                value={obj.route_of_administration_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="boost_2"
                label="Boostbestrahlung 2"
                value={obj.boost_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiated_tumor_2"
                label="Tumor bestrahlt 2"
                value={obj.radiated_tumor_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="radiated_distant_metastasis_2"
                label="Fernmetastase bestrahlt 2"
                value={obj.radiated_distant_metastasis_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="localization_distant_metastasis_2"
                label="Lokalisation Fernmetastase 2"
                value={obj.localization_distant_metastasis_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="target_zone_2"
                label="Zielgebiet 2"
                value={obj.target_zone_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="laterality_2"
                label="Seite 2"
                value={obj.laterality_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiation_lymph_node_region_2"
                label="Radiatio Lymphknotenregion 2"
                value={obj.radiation_lymph_node_region_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="technique_2"
                label="Technik 2"
                value={obj.technique_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiation_type_2"
                label="Strahlenart 2"
                value={obj.radiation_type_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="single_dose_2"
                label="Einzeldosis 2"
                value={obj.single_dose_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="applied_dose_2"
                label="Applizierte Dosis 2"
                value={obj.applied_dose_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="contingents_2"
                label="Anzahl Fraktionen 2"
                value={obj.contingents_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 2}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="begin_radiation_3"
                label="Beginn Einzelbestrahlung 3"
                value={(obj.begin_radiation_3) ? moment(obj.begin_radiation_3).format("YYYY.MM.DD") : null}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              <TextField
                margin="dense"
                id="end_radiation_3"
                label="Ende Einzelbestrahlung 3"
                value={(obj.end_radiation_3) ? moment(obj.end_radiation_3).format("YYYY.MM.DD") : null}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              <TextField
                margin="dense"
                id="route_of_administration_3"
                label="Applikationsart 3"
                value={obj.route_of_administration_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="boost_3"
                label="Boostbestrahlung 3"
                value={obj.boost_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiated_tumor_3"
                label="Tumor bestrahlt 3"
                value={obj.radiated_tumor_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="radiated_distant_metastasis_3"
                label="Fernmetastase bestrahlt 3"
                value={obj.radiated_distant_metastasis_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="localization_distant_metastasis_3"
                label="Lokalisation Fernmetastase 3"
                value={obj.localization_distant_metastasis_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="target_zone_3"
                label="Zielgebiet 3"
                value={obj.target_zone_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="laterality_3"
                label="Seite 3"
                value={obj.laterality_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiation_lymph_node_region_3"
                label="Radiatio Lymphknotenregion 3"
                value={obj.radiation_lymph_node_region_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="technique_3"
                label="Technik 3"
                value={obj.technique_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiation_type_3"
                label="Strahlenart 3"
                value={obj.radiation_type_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="single_dose_3"
                label="Einzeldosis 3"
                value={obj.single_dose_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="applied_dose_3"
                label="Applizierte Dosis 3"
                value={obj.applied_dose_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="contingents_3"
                label="Anzahl Fraktionen 3"
                value={obj.contingents_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 3}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="begin_radiation_4"
                label="Beginn Einzelbestrahlung 4"
                value={(obj.begin_radiation_3) ? moment(obj.begin_radiation_4).format("YYYY.MM.DD") : null}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              <TextField
                margin="dense"
                id="end_radiation_4"
                label="Ende Einzelbestrahlung 4"
                value={(obj.end_radiation_4) ? moment(obj.end_radiation_4).format("YYYY.MM.DD") : null}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="date"
                size="small"
              />
              <TextField
                margin="dense"
                id="route_of_administration_4"
                label="Applikationsart 4"
                value={obj.route_of_administration_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="boost_4"
                label="Boostbestrahlung 4"
                value={obj.boost_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiated_tumor_4"
                label="Tumor bestrahlt 4"
                value={obj.radiated_tumor_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="radiated_distant_metastasis_4"
                label="Fernmetastase bestrahlt 4"
                value={obj.radiated_distant_metastasis_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="localization_distant_metastasis_4"
                label="Lokalisation Fernmetastase 4"
                value={obj.localization_distant_metastasis_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="target_zone_4"
                label="Zielgebiet 4"
                value={obj.target_zone_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="laterality_4"
                label="Seite 4"
                value={obj.laterality_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiation_lymph_node_region_4"
                label="Radiatio Lymphknotenregion 4"
                value={obj.radiation_lymph_node_region_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="technique_4"
                label="Technik 4"
                value={obj.technique_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="radiation_type_4"
                label="Strahlenart 4"
                value={obj.radiation_type_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="single_dose_4"
                label="Einzeldosis 4"
                value={obj.single_dose_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="applied_dose_4"
                label="Applizierte Dosis 4"
                value={obj.applied_dose_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="contingents_4"
                label="Anzahl Fraktionen 4"
                value={obj.contingents_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 4}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="adverse_effect_1"
                label="Nebenwirkung 1"
                value={obj.adverse_effect_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="miscellaneous_adverse_effect_1"
                label="Sonstige Nebenwirkung 1"
                value={obj.miscellaneous_adverse_effect_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="degree_1"
                label="Schweregrad 1"
                value={obj.degree_1}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="adverse_effect_2"
                label="Nebenwirkung 2"
                value={obj.adverse_effect_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="miscellaneous_adverse_effect_2"
                label="Sonstige Nebenwirkung 2"
                value={obj.miscellaneous_adverse_effect_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="degree_2"
                label="Schweregrad 2"
                value={obj.degree_2}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="adverse_effect_3"
                label="Nebenwirkung 3"
                value={obj.adverse_effect_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="miscellaneous_adverse_effect_3"
                label="Sonstige Nebenwirkung 3"
                value={obj.miscellaneous_adverse_effect_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="degree_3"
                label="Schweregrad 3"
                value={obj.degree_3}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="adverse_effect_4"
                label="Nebenwirkung 4"
                value={obj.adverse_effect_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="miscellaneous_adverse_effect_4"
                label="Sonstige Nebenwirkung 4"
                value={obj.miscellaneous_adverse_effect_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="degree_4"
                label="Schweregrad 4"
                value={obj.degree_4}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <TextField
                margin="dense"
                id="adverse_effect_5"
                label="Nebenwirkung 5"
                value={obj.adverse_effect_5}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="miscellaneous_adverse_effect_5"
                label="Sonstige Nebenwirkung 5"
                value={obj.miscellaneous_adverse_effect_5}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
              <TextField
                margin="dense"
                id="degree_5"
                label="Schweregrad 5"
                value={obj.degree_5}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
            </Box>
          </Grid>
        </Grid>
  );
}
