import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientBloodSampleFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;
  // console.log(props);

  var currentComponent = this;

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        {/* <TextField
          margin="dense"
          id="master_pseudonym"
          label="Master_Pseudonym"
          value={obj.master_pseudonym}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        /> */}
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="status"
            label="Status"
            value={obj.status}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="sampleid"
            label="Proben-ID"
            value={obj.sampleid}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="full_amount"
            label="Füllmenge"
            value={obj.full_amount}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
            <TextField
            margin="dense"
            id="unit"
            label="Einheit"
            value={obj.unit}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <TextField
            margin="dense"
            id="material_type"
            label="Materialtyp"
            value={obj.material_type}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        <TextField
          margin="dense"
          id="storage_location"
          label="Lagerort"
          value={obj.storage_location}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="rack_id"
          label="Rack_ID"
          value={obj.rack_id}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="row"
          label="Zeile"
          value={obj.row}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="column"
          label="Spalte"
          value={obj.column}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        {/* <TextField
          margin="dense"
          id="pseudonym"
          label="Pseudonym"
          value={obj.pseudonym}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
          InputLabelProps={{ shrink: true }}
        /> */}
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="study"
            label="Studie"
            value={obj.study}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="case_number"
            label="Fallnummer"
            value={obj.case_number}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="visit_time"
            label="Visit_Time"
            value={obj.visit_time}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="acceptance_date"
            label="Abnahmezeitpunkt"
            value={(obj.acceptance_date) ? moment(obj.acceptance_date).format('DD.MM.YYYY, h:mm') : null}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="string"
            size="small"
          />
          <TextField
            margin="dense"
            id="entry_time"
            label="Eingangszeitpunkt"
            value={(obj.entry_time) ? moment(obj.entry_time).format('DD.MM.YYYY, h:mm') : null}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="string"
            size="small"
          />
          <TextField
            margin="dense"
            id="registration_time"
            label="Registrierungszeitpunkt"
            value={(obj.registration_time) ? moment(obj.registration_time).format('DD.MM.YYYY, h:mm') : null} 
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="string"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
          <Box
          display="flex"
          flexDirection="row"
          >
          <TextField
            margin="dense"
            id="centrifugation_start"
            label="Start_Zentrifugation"
            value={(obj.centrifugation_start) ? moment(obj.centrifugation_start).format('DD.MM.YYYY, h:mm') : null}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="string"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="freeze_time"
            label="Einfrierzeitpunkt"
            value={(obj.freeze_time) ? moment(obj.freeze_time).format('DD.MM.YYYY, h:mm') : null}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="string"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="blood_sample_status"
            label="Blutprobe_Status"
            value={obj.blood_sample_status}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="blood_sample_level"
            label="Blutprobe_Fuellstand"
            value={obj.blood_sample_level}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="lipaemic_blood_sample"
            label="Blutprobe_Lipaemisch"
            value={obj.lipaemic_blood_sample}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="checkbox"
            size="small"
          />
          <TextField
            margin="dense"
            id="icteric_blood_sample"
            label="Blutprobe_Ikterisch"
            value={obj.icteric_blood_sample}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="checkbox"
            size="small"
          />
          <TextField
            margin="dense"
            id="blood_clot_sample"
            label="Blutprobe_Gerinnsel"
            value={obj.blood_clot_sample}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="checkbox"
            size="small"
          />
        </Box>
        <TextField
            margin="dense"
            id="advanced_tumor"
            label="Fortgesch_Tumor"
            value={obj.advanced_tumor}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="checkbox"
            size="small"
          />
          <TextField
            margin="dense"
            id="metastasized"
            label="Metastasiert"
            value={obj.metastasized}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="checkbox"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="relapsed"
            label="Rezidiviert"
            value={obj.relapsed}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="checkbox"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="diagnosis"
            label="Diagnose"
            value={obj.diagnosis}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          <Box
            display="flex"
            flexDirection="row"
          >
          <TextField
            margin="dense"
            id="icd_o3"
            label="ICD-O3"
            value={obj.icd_o3}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="icd_10"
            label="ICD-10"
            value={obj.icd_10}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="test_center"
            label="Pruefzentrum"
            value={obj.test_center}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          </Box>
          <TextField
            margin="dense"
            id="comment"
            label="Kommentar"
            value={obj.comment}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          {/* <TextField
            margin="dense"
            id="date_birth"
            label="Geburtsdatum"
            value={obj.date_birth}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="date"
            size="small"
          /> */}
      </Grid>
    </Grid>
  );
}
