import React, {useCallback, useMemo} from 'react';
// import Dropzone from 'react-dropzone';
import {useDropzone} from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
// import Paper from '@mui/material/Paper';
// import RootRef from '@mui/material/RootRef'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export default function DropZone(props) {
  // const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  const propOnDrop = props.onDrop;
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({onDrop: ( acceptedFiles, rejectedFiles, event) => propOnDrop(acceptedFiles), multiple: false

  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject
  ]);

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="container" >
      <div {...getRootProps({
        style
      })} data-cy="dropzone">
        <input {...getInputProps()}/>
        <p ><FileUploadIcon fontSize='small'/>Ziehen Sie eine Datei hier hinein, oder klicken Sie, um eine Datei auszuwählen.</p>
      </div>
      { (!props.noFile) &&
      <aside>
        <h4 style={{marginTop: '2px'}}>Datei</h4>
        <ul>{files}</ul>
      </aside>}

    </section>
  );
}
