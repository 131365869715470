import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientMisellaneousTherapyFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="procedureid"
            label="ProzedurID"
            value={obj.procedureid}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="tumorid"
            label="Tumor-ID"
            value={obj.tumorid}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="route_of_administration"
            label="Applikationsart-ID"
            value={obj.route_of_administration}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="therapy_place"
            label="Therapieort"
            value={obj.therapy_place}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="begin"
            label="Beginn"
            value={obj.begin}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="date"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="end"
            label="Ende"
            value={obj.end}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="date"
            size="small"
          />
          <TextField
            margin="dense"
            id="termination_status"
            label="Beendigungsstatus"
            value={obj.termination_status}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="abortion_reason"
            label="Abbruchgrund"
            value={obj.abortion_reason}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="total_dose"
            label="Gesamtdosis"
            value={obj.total_dose}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="intension"
            label="Intention"
            value={obj.intension}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="operation_role"
            label="Stellung Op"
            value={obj.operation_role}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
