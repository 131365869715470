import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { AbilityContext } from './ability-context.jsx';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { Can } from './Can';
import Moment from 'moment'

import { save } from 'save-file';
// import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CheckingIcon from '@mui/icons-material/ContentPasteSearch';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import FilePdf from 'mdi-material-ui/FilePdfBox.js';
import GeneratePDF from './GeneratePDF.jsx';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import EnhancedTable from './EnhancedTable.jsx';
import EcrfUserFields from './EcrfUserFields.jsx';
import EcrfApplicationFields from './EcrfApplicationFields.jsx';
import NewDialog from './NewDialog.jsx';
import EditDialog from './EditDialog.jsx';
import FileUploadDialog from './FileUploadDialog.jsx';
import ListOfFiles from './ListOfFiles.jsx';
import ListOfEcrfApplications from './ListOfEcrfApplications.jsx';
import EditNoteDialog from './EditNoteDialog.jsx';
import NewNoteDialog from './NewNoteDialog.jsx';

const applicationColumns = [
  {
    id: "trial",
    secondary: "short",
    label: "Akronym",
    numeric: false,
    padding: 'none',
    searchable: true,
    type: "subObject",
  },
  {
    id: "site",
    label: "Zentrum",
    numeric: false,
    padding: 'none',
    searchable: true,
  },
  {
    id: "username",
    label: "User",
    numeric: false,
    padding: 'none',
    searchable: true,
  },
  {
    id: "ecrf_user",
    secondary: "name",
    label: "Name",
    numeric: false,
    padding: 'none',
    searchable: true,
    type: "subObject",
  },
  {
    id: "role",
    label: "Rolle",
    numeric: false,
    padding: 'none',
    searchable: true,
  },
  {
    id: "personStatusArray",
    label: "Status Person",
    numeric: false,
    padding: 'none',
    searchable: true,
    type: "statusSelection"
  },
  {
    id: "applicationStatusArray",
    label: "Status Account",
    numeric: false,
    padding: 'none',
    searchable: true,
    type: "statusSelection"
  },
];

const noteColumns = [
  {
   id: "entry_date",
   label: "Datum",
   numeric: false,
   disablePadding: false,
   date: true,
   type: "date",
  },
  {
   id: "note",
   label: "Notiz",
   numeric: false,
   disablePadding: false,
  },
 ];

class EcrfManagementAccounts extends React.Component {

  constructor(props) {
    super(props);
    let user = props.user;
    this.state = {ecrf_applications: props.ecrf_applications, user: user, expanded: null, openDialogs: {editUser: false}, expandedTable: {Personen: true, Abteilungen: true, Zentren: true, Dokumententracking: true, Notizen: true}};
    this.form = React.createRef();
  }

  componentDidMount(props) {
  }

  handlePanelChange = panel => (event, expanded) => {
    let newExpanded = expanded;
    if (!this.state.user.name) {
      newExpanded = "user_details";
    } else if (!this.state.user.submitted_file_ids && expanded == "application") {
      newExpanded = "documents";
    }
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  handleRowsSelect = (rowData, allRowsSelected, allObjectsSelected, objName) => {
    
    this.setState({
      selectedIds: allRowsSelected,
    });
    // this.updateDepartments(allRowsSelected);
  };

  handleDoubleClick = (event, obj, name) => {
    this.setState({selectedIds: [obj.id]});
    this.handleOpen(name)
  }

  handleOpen = (name) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleClose = name => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditEcrfApplicationSave = application => {
    const currentComponent = this;
    fetch('/ecrf_management/' + application.id + '/update_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_application: application,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("editApplication");
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleNewApplicationSave = application => {
    console.log(application);
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/create_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_application: application,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("newApplication");
        console.log(data.ecrf_user);
        currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich abgeschickt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleEditApplicationStatusSave = application => {
    console.log(application);
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/update_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_application: application,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("editApplication");
        // currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleFileUpload = event => {
    this.setState({user: event});
    this.handleClose("fileUpload");
  }

  handleFileDelete = file => {
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/delete_file', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        file_id: file.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleApplicationDelete = application => {
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/delete_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        application_id: application.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // console.log(data);
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleApplicationRemove = application => {
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/delete_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        application_id: application.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleChangeAccepted = (user_id) => {
    const currentComponent = this;
    console.log(this.state.user);
    const ecrf_user = this.state.ecrf_users.find(user => user.id == user_id)
    fetch('/ecrf_management/' + ecrf_user.id + '/mark_certificates_checked', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Allgemeine Dokumente erfolgreich akzeptiert.', {variant: 'success'});
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Akzeptanz der allgemeinen Dokumente zurückgenommen.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleChangeChecked = (user_id, file_id) => {
    const currentComponent = this;
    const ecrf_user = this.state.ecrf_users.find(user => user_id == user.id);
    if (ecrf_user.checked_file_ids && ecrf_user.checked_file_ids.includes(file_id)){
      fetch('/ecrf_management/' + ecrf_user.id + '/mark_file_unchecked', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich akzeptanz zurückgenommen.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else {
      fetch('/ecrf_management/' + ecrf_user.id + '/mark_file_checked', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich als akzeptiert markiert.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
  }

  handleEditEcrfApplicationNoteSave = (note) => {
    let currentComponent = this;
    this.setState({ editNoteOpen: false});
    fetch('/ecrf_management/'+this.state.selectedApplicationIds[0]+'/update_note', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        note_id: note.id,
        note: note.note,
        entry_date: note.entry_date,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        // var newNotes = [].concat(currentComponent.state.notes);
        // const noteIndex = newNotes.findIndex(note => note.id === data.note.id );
        // newNotes[noteIndex] = data.note;
        // currentComponent.setState({ notes: newNotes });
        // // currentComponent.updateNotes(currentComponent.state.selectedDepartmentIds);
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
    // this.updateNotes(this.state.selectedDepartmentIds);
  }

  handleEcrfApplicationReceived = message => {
    console.log("received Application");
    console.log(message);
    if (message.ecrf_application) {
      console.log("update")
      const ecrf_application = message.ecrf_application;
      let newEcrfApplications = [...this.state.ecrf_applications]
      const ind = this.state.ecrf_applications.findIndex(application => application.id == ecrf_application.id);
      newEcrfApplications[ind] = ecrf_application;
      this.setState({ ecrf_applications: newEcrfApplications});
      this.props.enqueueSnackbar('eCRF User wurde von Benutzer ' + message.username + ' aktualisiert: ' + message.ecrf_application.username, {variant: 'success'});
    }
    if (message.removed_pta_ids) {
      const newPeopleTrialsAssociations = this.state.people_trials_associations.filter(pta => !message.removed_pta_ids.includes(pta.id));
      const removedPtas = this.state.people_trials_associations.filter(pta => message.removed_pta_ids.includes(pta.id));
      const namesArray = removedPtas.map(pta => pta.first_name + ' ' + pta.last_name);
      let pta_filtered = [];
      if (newPeopleTrialsAssociations) {
        pta_filtered = newPeopleTrialsAssociations.filter(newPta => newPta.overview_show);
      }
      this.setState({ people_trials_associations: newPeopleTrialsAssociations, people_trials_associations_filtered: pta_filtered });
      const added_person = this.state.people_trials_associations.find(person => person.person_id == message.person_id);
      this.props.enqueueSnackbar('Studie wurde von Benutzer ' + message.username + ' aktualisiert. Person(en) entfernt: ' + namesArray, {variant: 'success'});
    }
  }

  render() {
    const { openDialogs } = this.state;
    const personStatusOptions = [{value: "in preparation", label: "in Vorbereitung", tooltipText: "Antragsteller bereitet Antrag/Daten vor", backgroundColor: "#FFFFFF"}, {value: "checking", label: "zu prüfen durch DM", tooltipText: "Datensatz muss von DM geprüft werden", backgroundColor: "#E29393"}, {value: "user interaction necessary", label: "User Eingabe nötig", tooltipText: "Antragsteller muss Antrag überarbeiten", backgroundColor: "#37ADF5"}, {value: "all complete", label: "Alles vollständig", tooltipText: "Alle Angaben und Nachweise der Person sind vollständig", backgroundColor: "#76d658"}, {value: "misc", label: "Sonstiges", tooltipText: "Bitte wenden Sie sich an das DM für Fragen.", backgroundColor: "#ADADAD"}];
    const accountStatusOptions =  [{value: "in preparation", label: "in Vorbereitung", tooltipText: "Antragsteller bereitet Antrag/Daten vor", backgroundColor: "#FFFFFF", stepnr: 1}, {value: "checking", label: "zu prüfen durch DM", tooltipText: "Datensatz muss von DM geprüft werden", backgroundColor: "#E29393", stepnr: 2}, {value: "user interaction necessary", label: "User Eingabe nötig", tooltipText: "Antragsteller muss Antrag überarbeiten", backgroundColor: "#37ADF5", stepnr: 2}, {value: "ready", label: "Bereit", tooltipText: "Antrag ist genehmigt, aber Prüfzentrum oder Studie ist nicht freigeschaltet", backgroundColor: "#f5b556", stepnr: 3}, {value: "active", label: "Aktiv", tooltipText: "eCRF-Account ist freigeschaltet und aktiv.", backgroundColor: "#76d658", stepnr: 4}, {value: "locked", label: "Gesperrt", tooltipText: "eCRF-Account ist vom DM gesperrt worden", backgroundColor: "#FF4917", stepnr: 3}, {value: "misc", label: "Sonstiges", tooltipText: "Bitte wenden Sie sich an das DM für Fragen.", backgroundColor: "#ADADAD", stepnr: 3}];
    const annotatedEcrfApplications = this.state.ecrf_applications.map(application => {
      const user = application.ecrf_user;
      const ret = {...application, personStatusArray: personStatusOptions.find(opt => opt.value == user.person_status), applicationStatusArray: accountStatusOptions.find(opt => opt.value == application.status)}
      return ret;
    })
    // console.log(annotatedEcrfApplications);
    const selectedApplications = (this.state.selectedIds) ? annotatedEcrfApplications.filter(ecrfa => this.state.selectedIds.includes(ecrfa.id )) : null;
    const requiredFields = [];
    const handleDoubleClick = () => {

    }

    const trialsOptions = this.props.trials.map(trial => {return {value: trial.id, label: trial.short}});
    // const rolesOptions = [{value: "TestRolle1", label: "TestRolle1"}, {value: "TestRolle2", label: "TestRolle2"}, {value: "TestRolle3", label: "TestRolle3"}, {value: "TestRolle4", label: "TestRolle4"}];
    // const functionsOptions = [{value: "Prüfstelle", label: "Prüfstelle"}, {value: "TestFunction2", label: "TestFunction2"}, {value: "TestFunction3", label: "TestFunction3"}, {value: "TestFunction4", label: "TestFunction4"}];
    // const sitesOptions = [{value: "TestSite1", label: "TestSite1"}, {value: "TestSite2", label: "TestSite2"}, {value: "TestSite3", label: "TestSite3"}, {value: "TestSite4", label: "TestSite4"}];
    // // const statusOptions = [{value: "applied", label: "Eingereicht", stepnr: 2}, {value: "granted", label: "Genehmigt", stepnr: 3}, {value: "denied", label: "Abgelehnt", stepnr: 3}, {value: "waiting", label: "Warten auf Antragsupload", stepnr: 1}];
    let functionsOptions = [];
    this.props.trials.forEach(conf => {
      // let ret = [];
      const func_ret = conf.ecrf_trial_functions.map(func => {
        return ({value: func.id, label: func.name});
      });
      functionsOptions = {...functionsOptions, [conf.id]: func_ret};
    })
    let rolesOptions = {};
    this.props.trials.forEach(conf => {
      let func_ret = [];
      conf.ecrf_trial_functions.forEach(func => {
        const roles_ret = func.ecrf_trial_roles.map(role => {
          return ({value: role.id, label: role.name});
        })
        func_ret = {...func_ret, [func.id]: roles_ret};
      });
      rolesOptions[conf.id] = func_ret;
    })
    console.log(rolesOptions);
    let sitesOptions = {}
    this.props.trials.forEach(conf => {
      const sites = conf.trial.sites.map(site => {
        return {value: site.id, label: site.inst_description};
      })
      sitesOptions[conf.id] = sites;
    })
    
    
    const applicationSelectOptions = {trial_id: trialsOptions || "", role: rolesOptions, site: sitesOptions, function: functionsOptions, status: accountStatusOptions};
    const steps = [
      'Erstellt',
      'Studiendokumente (siehe Hinweis) hochgeladen',
      'Geprüft und genehmigt',
      'Account erstellt und berechtigt',
    ];
    // console.log((selectedApplications) ? selectedApplications[0].applicationStatusArray.stepnr : null)

    return (
      <Grid container direction="row" justify="center" alignItems="top">
        <ActionCableConsumer
          channel={{channel: "EcrfManagementChannel"}}
          onReceived={this.handleEcrfApplicationReceived}
        />
        { (openDialogs.editApplication) &&
        <EditDialog
          title="Benutzer editieren"
          obj={selectedApplications[0]}
          open={openDialogs.editApplication}
          handleSave={this.handleEditEcrfApplicationSave}
          handleClose={() => this.handleClose("editApplication")}
          Fields={EcrfApplicationFields}
          selectOptions={applicationSelectOptions}
          requiredFields={requiredFields}
        />}
        {/* { (openDialogs.newApplication) &&
        <NewDialog
          title="Studiendaten"
          obj={{date_submitted: Moment().toISOString().substr(0,10), status: "applied"}}
          open={openDialogs.newApplication}
          handleSave={this.handleNewApplicationSave}
          handleClose={() => this.handleClose("newApplication")}
          Fields={EcrfApplicationFields}
          // requiredFields={requiredFields}
          selectOptions={applicationSelectOptions}
          disabledFields={disabledFields}
          hiddenFields={hiddenFields}
        />} */}
        <Grid item xs={6}>
          <EnhancedTable
            title={"Personen"}
            data={annotatedEcrfApplications}
            columns={applicationColumns}
            // singleSelect={!this.state.multipleSelectMode}
            multipleSelectable
            selectedIds={this.state.selectedIds}
            selectedObjects={selectedApplications}
            onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleRowsSelect(rowData, allRowsSelected, allObjectsSelected, "Personen")}
            // handleAddClick={this.handleAddInstitutionOpen}
            // handleInfoClick={this.handleInfoInstitutionClick}
            handleDeleteClick={this.handleDeleteInstitutionConfirmationDialogOpen}
            handleFilterClick={this.handleInstitutionsFilterClick}
            filters={this.state.peopleFilters}
            onFilterChange={this.handlePeopleFilterChange}
            rowsPerPage={25}
            orderBy="name"
            order="asc"
            onDoubleClick={(event, obj) => this.handleDoubleClick(event, obj, "editApplication")}
            allData={annotatedEcrfApplications}
            autoFill
            selectable
            expanded={true}
            handleExpandChange={this.handleExpandChange}
            objectType="EcrfUsers"
            noAddIcon
          />
        </Grid>
        <Grid item xs={6}>
          { (this.state.selectedIds != null && this.state.selectedIds.length == 1) &&
            <>
            <Grid item xs={12}>
              <Paper style={{borderStyle: "solid", width: '100%', borderWidth: "1px"}}>
                <EcrfApplicationFields
                  obj={selectedApplications[0]}
                  readOnly 
                  requiredFields={requiredFields}
                  selectOptions={applicationSelectOptions}
                />               
              </Paper>
            </Grid>
            <ListItem 
          onDoubleClick={() => props.handleDialogOpen("editApplication")}
          key={selectedApplications[0].id}
        >
          
          <ListItemText 
            primary={selectedApplications[0].trial.short}
            primaryTypographyProps={{width: '200px'}}
            secondary={selectedApplications[0].applicationStatusArray.label}
            secondaryTypographyProps={{backgroundColor: selectedApplications[0].applicationStatusArray.backgroundColor, width: '100px'}}
            style={{ width: '30%'}}
          />
          <ListItemText 
            primary={
              <Box>
                <Stepper activeStep={selectedApplications[0].applicationStatusArray.stepnr} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            }
          />
          <ListItemButton>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row" justifyContent="right" flexShrink={true} flexWrap="nowrap" sx={{width: '350px', paddingLeft: '0px', paddingRight: '0px'}}>
                { (this.props.admin && selectedApplications[0].attached_application_file) && 
                  <Tooltip title="Antrag editieren">
                    <IconButton id={selectedApplications[0].id} edge="end" aria-label="delete" onClick={() => handleOpenEditApplication(selectedApplications[0])}>
                      <EditIcon/>
                    </IconButton>
                  </Tooltip>
                }
                { (selectedApplications[0].attached_application_file) &&
                  <Tooltip title="Unterschriebenen Antrag herunterladen">
                    <IconButton id={selectedApplications[0].id} edge="end" aria-label="delete" onClick={() => downloadFile(application.attached_application_file.link)}>
                      <FilePdf/>
                    </IconButton>
                  </Tooltip>
                }
                { (!this.props.admin && selectedApplications[0].status != "granted" && !selectedApplications[0].attached_application_file ) &&
                    <Tooltip title="Antrag zur Unterschrift herunterladen">
                      <IconButton id={selectedApplications[0].id} edge="end" aria-label="delete" >
                        <GeneratePDF
                          obj={selectedApplications[0]}
                          user={selectedApplications[0].ecrf_user}
                        />
                      </IconButton>
                    </Tooltip>

                }
                { (this.props.handleDeleteApplicationFile && selectedApplications[0].status != "granted" && selectedApplications[0].attached_application_file ) &&
                    <Tooltip title="Hochgeladenen Antrag löschen">
                      <IconButton id={selectedApplications[0].id} edge="end" aria-label="delete" >
                        <Button 
                          variant="contained"
                          onClick={() => props.handleDeleteApplicationFile(selectedApplications[0])}
                          size="small"
                        > 
                          Hochgeladenen Antrag löschen
                        </Button>
                      </IconButton>
                    </Tooltip>

                }
                {/* { (this.props.handleRemove && selectedApplications[0].status == "granted" && !selectedApplications[0].applied_for_removal) &&
                    <Tooltip title="Löschung beantragen">
                      <IconButton id={selectedApplications[0].id} edge="end" aria-label="delete" onClick={() => props.handleRemove(selectedApplications[0])} >
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                }
                { (this.props.handleRemove && !props.admin && selectedApplications[0].status == "granted" && selectedApplications[0].applied_for_removal) &&
                    <Tooltip title="Löschungsantrag zurückziehen">
                      <IconButton id={selectedApplications[0].id} edge="end" aria-label="delete" onClick={() => props.handleRemove(selectedApplications[0])} >
                        <BlockIcon />
                      </IconButton>
                    </Tooltip>
                } */}
                { (!this.props.admin && selectedApplications[0].status != "granted" && !selectedApplications[0].attached_application_file) &&
                    <Tooltip title="Unterschriebenen Antrag hochladen">
                      <IconButton id={selectedApplications[0].id} edge="end" aria-label="upload" onClick={() => setOpenDialogs({...openDialogs, fileUpload: selectedApplications[0].id})} disabled={this.props.disabled || selectedApplications[0].status == "granted"}>
                        <FileUploadIcon />
                      </IconButton>
                    </Tooltip>
                }
                { (this.props.handleDelete && selectedApplications[0].status != "granted") &&
                    <Tooltip title="Antrag löschen">
                      <IconButton id={selectedApplications[0].id} edge="end" aria-label="delete" onClick={() => this.props.handleDelete(selectedApplications[0])} disabled={this.props.disabled || selectedApplications[0].status == "granted"}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                }
              </Box>
            </Grid>
            </ListItemButton>
          </ListItem>
          </>}
        </Grid>
        <Grid item xs={12} >
            { (openDialogs.editNote && this.state.selectedNotes.length == 1) &&
              <EditDialog
                open={openDialogs.editNote}
                selectedNote={this.state.selectedNotes[0]}
                handleSave={this.handleEditEcrfApplicationNoteSave}
                handleClose={this.handleEditNoteClose}
              />
            }
            { (openDialogs.newNote) &&
              <NewNoteDialog
                open={openDialogs.newNote}
                handleSave={this.handleNewNoteSave}
                handleClose={this.handleNewNoteClose}
              />
            }
            <Grid container justify="center">
              {/* <Paper> */}
                <EnhancedTable
                  title={"Notizen"}
                  data={[]}
                  // (notes) ? notes : []
                  columns={noteColumns}
                  // singleSelect={!this.state.multipleSelectMode}
                  multipleSelectable
                  selectedIds={this.state.selectedNoteIds}
                  selectedObjects={this.state.selectedNotes}
                  onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handleNoteChange(rowData, allRowsSelected, allObjectsSelected)}
                  handleAddClick={this.handleNewNoteOpen}
                  // handleInfoClick={this.handleInfoSiteClick}
                  // handleEditClick={this.handleEditSiteClick}
                  // handleRemoveClick={this.handleRemoveSiteClick}
                  handleDeleteClick={this.handleDeleteNote}
                  // handleFilterClick={this.handleSitesFilterClick}
                  // filters={this.state.siteFilters}
                  rowsPerPage={50}
                  orderBy="entry_date"
                  order="desc"
                  onDoubleClick={this.handleNoteDoubleClick}
                  // markedIds={this.state.selectedDepartmentsSitesIds}
                  // onFilterChange={this.handleSitesFilterChange}
                  allData={[]}
                  selectable
                  expanded={this.state.expandedTable["Notizen"]}
                  handleExpandChange={this.handleExpandChange}
                  superType="Trial"
                />
              {/* </Paper> */}
            </Grid>
          </Grid>

        
      </Grid>
    )
  }
}

EcrfManagementAccounts.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withTheme(withSnackbar(EcrfManagementAccounts));
