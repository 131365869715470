import React, { useState } from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import UndoIcon from '@mui/icons-material/Undo';

export default function AuditTrailUserFields(props) {
  // const classes = useStyles();
  const { obj, currentObject, handleChange, handleSelectChange, readOnly } = props;

  // var currentComponent = this;
  // console.log(props);
  console.log(obj);

 
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        {/* <Box
          display="flex"
          flexDirection="row"
        > */}
          <TextField
            margin="dense"
            id="name"
            label="Name"
            value={obj.name}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="string"
            size="small"
          />
          
          <TextField
            margin="dense"
            id="email"
            label="E-mail"
            value={obj.email}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="string"
            size="small"
          />
          <TextField
            margin="dense"
            id="admin"
            label="Admin"
            value={obj.admin}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="checkbox"
            size="small"
          />
          <TextField
            margin="dense"
            id="status"
            label="Status"
            value={obj.status}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="string"
            size="small"
          />
          
        
      </Grid>
    </Grid>
  )
}
