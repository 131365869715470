import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientSocioFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;
  console.log(props);

  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="pseudonym"
              label="Pseudonym"
              value={obj.pseudonym}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="issue_date"
              label="Ausgabedatum"
              value={(obj.issue_date) ? moment(obj.issue_date).format("DD.MM.YYYY") : null}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="text"
              size="small"
            />
            <TextField
              margin="dense"
              id="civil_status_id"
              label="Aktuelle Lebens-/Wohnsituation"
              value={obj.civil_status_id}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="care"
              label="Pflegt Angehörige"
              value={obj.care}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              margin="dense"
              id="children_under_7"
              label="Kinder unter 7"
              value={obj.children_under_7}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              margin="dense"
              id="children_between_7_and_13"
              label="Kinder zwischen 7 und 13"
              value={obj.children_between_7_and_14}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              margin="dense"
              id="children_between_14_and_21"
              label="Kinder zwischen 14 und 21"
              value={obj.children_between_14_and_21}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              margin="dense"
              id="children_over_21"
              label="Kinder über 21"
              value={obj.children_over_21}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              margin="dense"
              id="no_children"
              label="Keine Kinder"
              value={obj.no_children}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="checkbox"
              size="small"
            />
            <TextField
              margin="dense"
              id="profession_id"
              label="Aktueller beruflicher Status"
              value={obj.profession_id}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="graduation_id"
              label="Höchster Schulabschluss"
              value={obj.graduation_id}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              type="string"
              size="small"
            />
            <TextField
              margin="dense"
              id="comment"
              label="Kommentar"
              value={obj.comment}
              fullWidth
              variant="outlined"
              style={{zIndex: 1}}
              onChange={handleChange}
              // error={!obj.name}
              InputProps={{
                readOnly: true,
              }}
              multiline
              maxRows={4}
              type="text"
              size="small"
            />
          </Grid>
        </Grid>
  );
}
