import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import FilePdf from 'mdi-material-ui/FilePdfBox.js';
import FileWord from 'mdi-material-ui/FileWord.js';
import FileExcel from 'mdi-material-ui/FileExcel.js';
import File from 'mdi-material-ui/File.js';
import FilePowerpoint from 'mdi-material-ui/FilePowerpoint.js';
import CheckIcon from '@mui/icons-material/Check';
import ConfirmationDialog from './CustomComponents/ConfirmationDialog';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: "#f5f5f5",
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function downloadFile(link) {
  // const completeLink = (window.location.hostname == "topas.nct-heidelberg.de") ? "https://" + window.location.hostname + link : link;
  // console.log(completeLink);
  // window.open(completeLink);
}

export default function ListOfFiles(props) {
  const classes = useStyles();
  // const files = [{name: "bla", type: "xls"}, {name: "bla2", type: "docx"}, {name: "PdfTest", type: "pdf"}];
  const { files } = props;
  const [confirmation, setConfirmation] = useState(false);
  const [fileDelete, setFileDelete] = useState();

  const handleConfirmationDeleteFile = (file) => {
    if (props.confirmationDialog){
      setFileDelete(file);
      setConfirmation(true);
    }else{
      handleConfirm(file)
    }
  }
  const handleConfirm = (file) => {
    setConfirmation(false);
    props.handleDelete(file);
    setFileDelete();
  }
  const fileListItems = files.map(file => {
    let icon = null;
    switch (file.type) {
      case "application/pdf":
        icon = <FilePdf />;
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        icon = <FileExcel />;
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        icon = <FileWord />;
        break;
      case "text/plain":
        icon = <File />;
        break;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        icon = <FilePowerpoint />;
        break;
      default:
        icon = <File />;
    }
    console.log("checked_file_ids", props.checked_file_ids, props.files);
    // console.log(props.checked_file_ids.includes(file.id));
    // console.log(props.admin, props.checked_file_ids, props.submitted_file_ids, props.submitted_file_ids.includes(file.id), props.checked_file_ids.includes(file.id));
    const completeLink = ((window.location.hostname == "topas.nct-heidelberg.de" || window.location.hostname == "topastest.nct-heidelberg.de") ? "https://" + window.location.hostname : "http://" + window.location.hostname + ((window.location.hostname == "localhost") ? ":3000" : null)) + "/" + props.controller + "/" + props.action + "/" + file.id;
    return (
      <div>
        {/* <ListItemLink onClick={() => downloadFile(file.id, props.controller, props.action)} key={file.id}> */}
        <ListItemLink href={completeLink} target="_blank" rel="noreferrer noopener">
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={file.filename + "(" + file.id + ")"} />
          <ListItemSecondaryAction>
            {(props.handleChangeSubmitted && ((props.checked_file_ids && !props.checked_file_ids.includes(file.id)) || !props.checked_file_ids || (props.admin && !props.checked_file_ids.includes(file.id)))) &&
              <FormControlLabel control={<Switch size="small" checked={(props.submitted_file_ids) && props.submitted_file_ids.includes(file.id)} onChange={() => props.handleChangeSubmitted(file.id)} />} label="Zur Prüfung freigeben" />
            }
            { (props.handleChangeChecked && props.admin && props.checked_file_ids) &&
              <FormControlLabel control={<Switch size="small" checked={(props.checked_file_ids) && props.checked_file_ids.includes(file.id)} onChange={() => props.handleChangeChecked(file.id)}/>} label="Geprüft und Akzeptiert" />
            }
            { (((props.checked_file_ids && props.checked_file_ids.includes(file.id)))) &&
              <CheckIcon color="success"/>
            }
            { (props.handleDelete && (!props.submittable || (!(props.checked_file_ids && props.checked_file_ids.includes(file.id))))) &&
              <IconButton id={file.id} edge="end" aria-label="delete" onClick={() => handleConfirmationDeleteFile(file)} disabled={props.disabled || (props.submitted_file_ids && props.submitted_file_ids.includes(file.id))}>
                <DeleteIcon />
              </IconButton>
            }
          </ListItemSecondaryAction>
        </ListItemLink>
        {confirmation ?
          <ConfirmationDialog
            open={confirmation}
            title={fileDelete.filename}
            text={'Sind Sie sicher, dieses Dokument zu löschen?'}
            data={fileDelete}
            handleConfirm={() => handleConfirm(fileDelete)}
            handleClose={() => setConfirmation(false)}
          /> : null}
      </div>
    )
  });
  return (
    <div className={classes.root}>
      <List component="nav" aria-label="secondary mailbox folders">
        {fileListItems}
      </List>
    </div>
  );
}
