import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import Box from '@mui/material/Box';
import moment from 'moment';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcbopProDashFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
    <> 
      <Typography variant="h6">QLQ C30</Typography>
      <Box
        display="flex"
        flexDirection="row"
      >
        <TextField
          margin="dense"
          id="dash_01"
          label="Dash_01"
          value={obj.dash_01}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_02"
          label="Dash_02"
          value={obj.dash_02}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_03"
          label="Dash_03"
          value={obj.dash_03}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="dash_04"
          label="Dash_04"
          value={obj.dash_04}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_05"
          label="Dash_05"
          value={obj.dash_05}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_06"
          label="Dash_06"
          value={obj.dash_06}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="dash_07"
          label="Dash_07"
          value={obj.dash_07}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_08"
          label="Dash_08"
          value={obj.dash_08}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_09"
          label="Dash_09"
          value={obj.dash_09}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="dash_10"
          label="Dash_10"
          value={obj.dash_10}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_11"
          label="Dash_11"
          value={obj.dash_11}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        
        <TextField
          margin="dense"
          id="dash_12"
          label="Dash_12"
          value={obj.dash_12}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="dash_13"
          label="Dash_13"
          value={obj.dash_13}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_14"
          label="Dash_14"
          value={obj.dash_14}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_15"
          label="Dash_15"
          value={obj.dash_15}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="dash_16"
          label="Dash_16"
          value={obj.dash_16}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_17"
          label="Dash_17"
          value={obj.dash_17}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_18"
          label="Dash_18"
          value={obj.dash_18}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="dash_19"
          label="Dash_19"
          value={obj.dash_19}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_beruf_txt"
          label="Beruf text"
          value={obj.dash_beruf_txt}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_beruf"
          label="Dash-Beruf"
          value={obj.dash_beruf}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="dash_sport"
          label="Dash-Sport"
          value={obj.dash_sport}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="dash_sport_txt"
          label="Dash_Sport_txt"
          value={obj.dash_sport_txt}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </>
  );
}
