import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcbopProEortcFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
    <>
      <Typography variant="h6">QLQ C30</Typography>
      <Box
        display="flex"
        flexDirection="row"
      >
        <TextField
          margin="dense"
          id="eortc_phys"
          label="Physical functioning (revised)"
          value={obj.eortc_phys}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="eortc_role"
          label="Role functioning (revised)"
          value={obj.eortc_role}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="eortc_emotion"
          label="Emotional functioning"
          value={obj.eortc_emotion}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="eortc_cogni"
          label="Cognitive functioning"
          value={obj.eortc_cogni}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="eortc_social"
          label="Social functioning"
          value={obj.eortc_social}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="eortc_total"
          label="Eortc_Total"
          value={obj.eortc_total}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
      >
        <TextField
          margin="dense"
          id="eortc_global"
          label="Eortc_Global"
          value={obj.eortc_global}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="eortc_fatigue"
          label="Fatigue"
          value={obj.eortc_fatigue}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        {/* <TextField
          margin="dense"
          id="eortc_qlq_30_nausea_and_vomiting"
          label="Nausea and vomiting"
          value={obj.eortc_qlq_30_nausea_and_vomiting}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        /> */}
        <TextField
          margin="dense"
          id="eortc_pain"
          label="Pain"
          value={obj.eortc_pain}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="eortc_dysp"
          label="Dyspnoea"
          value={obj.eortc_dysp}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="eortc_insom"
          label="Insomnia"
          value={obj.eortc_insom}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="eortc_apploss"
          label="Appetite loss"
          value={obj.eortc_apploss}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
      >
        <TextField
          margin="dense"
          id="eortc_const"
          label="Constipation"
          value={obj.eortc_const}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="eortc_diarr"
          label="Diarrhoea"
          value={obj.eortc_diarr}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="eortc_finan"
          label="Financial difficulties"
          value={obj.eortc_finan}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <TextField
          margin="dense"
          id="eortc_gastro"
          label="combine gastroenterologic"
          value={obj.eortc_gastro}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      <Typography variant="h6">EORTC FA-12</Typography>
      <Box
        display="flex"
        flexDirection="row"
      >
        <TextField
          margin="dense"
          id="eortc_fa_pf"
          label="physical fatigue"
          value={obj.eortc_fa_pf}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="eortc_fa_ef"
          label="emotional fatigue"
          value={obj.eortc_fa_ef}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        {/* <TextField
          margin="dense"
          id="eortc_fa_dl"
          label="cognitive fatigue"
          value={obj.eortc_fa_dl}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="number"
          size="small"
          InputLabelProps={{ shrink: true }}
        /> */}
        <TextField
          margin="dense"
          id="eortc_fa_dl"
          label="Interference with daily life"
          value={obj.eortc_fa_dl}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="eortc_fa_ss"
          label="Social sequelae"
          value={obj.eortc_fa_ss}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="eortc_fa_total"
          label="FA12 Summary Score"
          value={obj.eortc_fa_total}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Typography variant="h6">Fact-Cog</Typography>
      <Box
        display="flex"
        flexDirection="row"
      >
        <TextField
          margin="dense"
          id="CogPCI_sum"
          label="Perceived Cognitive Impairments"
          value={obj.CogPCI_sum}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="CogPCI_total"
          label="CogPCI_total"
          value={obj.CogPCI_total}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="CogQOL_sum"
          label="Impact on QoL"
          value={obj.CogQOL_sum}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="CogQOL_total"
          label="CogQOL_total"
          value={obj.CogQOL_total}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="CogFunctional"
          label="Functional interference"
          value={obj.CogFunctional}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Typography variant="h6">PHQ-4</Typography>
      <Box
        display="flex"
        flexDirection="row"
      >
        <TextField
          margin="dense"
          id="phq_sum"
          label="PHQ total"
          value={obj.phq_sum}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="phq_score"
          label="PHQ-Score %"
          value={obj.phq_score}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Typography variant="h6">BPI</Typography>
      <Box
        display="flex"
        flexDirection="row"
      >
        <TextField
          margin="dense"
          id="BPI_sum"
          label="BPI total"
          value={obj.BPI_sum}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          margin="dense"
          id="BPI_score"
          label="BPI-Score %"
          value={obj.BPI_score}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
          <TextField
          margin="dense"
          id="psqi_total"
          label="PSQI_Total"
          value={obj.psqi_total}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="string"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </>
  );
}
