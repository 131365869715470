import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';

import TextField from './TextField.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function EcrfUserFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly, selectOptions } = props;
  console.log(obj, readOnly, selectOptions["person_status"]);
  // const personStatusOptions = [{value: "in preparation", label: "in Vorbereitung", tooltip: "Antragsteller bereitet Antrag/Daten vor"}, {value: "checking", label: "zu prüfen durch DM", tooltip: "Datensatz muss von DM geprüft werden"}, {value: "user interaction necessary", label: "User Eingabe nötig", tooltip: "Antragsteller muss Antrag überarbeiten"}, {value: "all complete", label: "Alles vollständig", tooltip: "Alle Angaben und Nachweise der Person sind vollständig"}, {value: "misc", label: "Sonstiges", tooltip: "Bitte wenden Sie sich an das DM für Fragen."}];

  return (
        <Grid container direction="row" justify="center" alignItems="top">
          <Grid item xs={6}>
          <Box
            component="form"
            // sx={{
            //   '& .MuiTextField-root': { m: 1, width: '25ch' },
            // }}
            noValidate
            // autoComplete="off"
          >
            <div>
            <>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nachname"
                value={obj.name}
                // fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("name")}
                // error={!obj.name}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="string"
                size="small"
              />
              <TextField
                margin="dense"
                id="first_name"
                label="Vorname"
                value={obj.first_name}
                // fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("first_name")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="string"
                size="small"
              />
              { (!props.reduced) &&
              <>
              {/* <TextField
                margin="dense"
                id="gender"
                label="Geschlecht"
                value={obj.gender}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("gender")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="select"
                options={[{label: "weiblich", value: "w"}, {label: "männlich", value: "m"}, {label: "andere", value: "?"}]}
                size="small"
              /> */}
              <TextField
                margin="dense"
                id="title"
                label="Titel"
                value={obj.title}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("title")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="string"
                size="small"
              />
              </>
              }
              <TextField
                margin="dense"
                id="email"
                label="E-Mail"
                value={obj.email}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("email")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="email"
                size="small"
                disabled={!props.admin}
              />
              { (!props.reduced) &&
              <TextField
                margin="dense"
                id="phone_number"
                label="Telefonnummer"
                value={obj.phone_number}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("phone_number")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="string"
                size="small"
              />
              }
            </>
            </div>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              id="institution_name"
              label="Name Ihrer Klinik/Institution"
              value={obj.institution_name}
              fullWidth
              handleChange={handleChange}
              required={props.requiredFields.includes("institution_name")}
              InputProps={{
                readOnly: readOnly,
              }}
              type="string"
              size="small"
            />
            { (!props.reduced) &&
            <>
              <TextField
                margin="dense"
                id="department_name"
                label="Name der Abteilung"
                value={obj.department_name}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("department_nameame")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="string"
                size="small"
              />
              <TextField
                margin="dense"
                id="street"
                label="Straße und Hausnummer"
                value={obj.street}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("street")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="string"
                size="small"
              />
              <TextField
                margin="dense"
                id="zip"
                label="PLZ"
                value={obj.zip}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("zip")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="string"
                size="small"
              />
              <TextField
                margin="dense"
                id="city"
                label="Stadt"
                value={obj.city}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("city")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="string"
                size="small"
              />
              { (props.admin) &&
              <TextField
                margin="dense"
                id="person_status"
                label="Status"
                value={obj.person_status}
                fullWidth
                handleChange={handleChange}
                required={props.requiredFields.includes("person_status")}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="select"
                options={selectOptions["person_status"]}
                size="small"
                disabled={!props.admin}
              />}
            </>}
          </Grid>
          { (!props.reduced) &&
            <Grid item xs={6} sx={{marginTop: "20px"}}>
              <Link href="/TOPAS Spezi eCRF Datenschutzhinweis V1_0 20240108.pdf" target="_blank" rel="noreferrer noopener">
                <Typography >
                  Datenschutzhinweis
                </Typography>
              </Link>
              <TextField
                margin="dense"
                id="consent"
                label="Ich habe die allgemeinen Hinweise [Link oben] zur Speicherung meiner Persönlichen Daten zur Kenntnis genommen und stimme der Speicherung zu."
                value={obj.consent}
                handleChange={handleChange}
                required={props.requiredFields.includes("consent")}
                // error={true}
                InputProps={{
                  readOnly: readOnly,
                }}
                type="checkbox"
                disabled={(obj.person_status == "all complete" || obj.person_status == "misc" || readOnly)}
              />
            </Grid>
          }
          { (obj.person_status == "all complete" || obj.person_status == "misc") &&
            <Grid item xs={12}>
              <Typography style={{color: 'red', fontSize: '20px'}}>
                Hinweis: Daten können nur noch durch das Datenmanagement geändert werden.
              </Typography>
            </Grid>
          }
        </Grid>
        
  );
}
