import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import DropZone from './DropZone.jsx';

import Papa from "../../../node_modules/papaparse/";
import DataEntryEditDialog from './DataEntryEditDialog.jsx';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from './TextField.jsx';
import LoadingButton from '@mui/lab/LoadingButton';
import * as XLSX from 'xlsx';
import readXlsxFile from 'read-excel-file';
import convertToJson from "read-excel-file/map"

class SarcBobFileUploadDialog extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      files: [],
      name: null,
      type: null,
      openDialogs: {},
      uploadDisabled: true,
      fileContent: [],
      sarcbobFileKind: null
    };

    let entry;

    let currentComponent = this;
    fetch('/sarcbob_file_kind', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({sarcbobFileKind: data.sarcbob_file_kind});
    });

    this.form = React.createRef();
  }

  handleOpen = name => (event) => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleClose = name => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = false;
    this.setState({openDialogs: newOpenDialogs});

    this.setState({uploadDisabled: true});
  }

  handleCsvFile = (file) => {
    file.text().then((value) => {
      return Papa.parse(value, {header: true});
    }).then((value) => {
      var dataArray = {}
      value.data.forEach((row => {
        const pseudonym = row["Pseudonym"];
        const pid = row["PID"];
        if(pseudonym){
          if (dataArray[pseudonym] == null) {
            dataArray[pseudonym] = [];
          }
          dataArray[pseudonym].push(row);
        }
        else if(pid){
          if (dataArray[pid] == null) {
            dataArray[pid] = [];
          }
          dataArray[pid].push(row);
        }
        
      }))
      this.setState({fileContent: dataArray}, this.setState({fileLoading: false}))
    });
  }
  handleExcelFile = (file) => {
    
    var dataArray = {}
    readXlsxFile(this.state.files[0]).then((value) => {
      // value[0].forEach(header => )
      value.slice(1, value.length - 1).forEach((row => {
        // const arr = {}
        // var i = 0
        // value[0].forEach(dataArray)
        // console.log(row);
        if (dataArray[row[1]] == null) {
          dataArray[row[1]] = [];
        }
        if (row[1]) {
          dataArray[row[1]].push({"PATNR": row[0], "STUDIE": row[2], "TITEL": row[3]})
        }
        // console.log(dataArray)
        // const pseudonym = row["EXTNUM"]
        // if (dataArray[pseudonym] == null) {
        //   dataArray[pseudonym] = [];
        // }
        // dataArray[pseudonym].push(row);
      }))
    });
    console.log(dataArray);
    this.setState({fileContent: dataArray}, this.setState({fileLoading: false}))

      // this.parseExcel = function(file) {
      //   var reader = new FileReader();
    
      //   reader.onload = function(e) {
      //     var data = e.target.result;
      //     var workbook = XLSX.read(data, {
      //       type: 'binary'
      //     });
    
      //     workbook.SheetNames.forEach(function(sheetName) {
      //       // Here is your object
      //       var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
      //       var json_object = JSON.stringify(XL_row_object);
      //       console.log(json_object);
    
      //     })
    
      //   };
      //   reader(file);
      // };
   
  }

  handleConvert = async (e) => {
    console.log(e);
    const file = this.state.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const sheetValues = XLSX.utils.sheet_to_json(worksheet);
    
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   const data = e.target.result;
      //   const workbook = XLSX.read(data, { type: "binary" });
      //   const sheetName = workbook.SheetNames[0];
      //   const worksheet = workbook.Sheets[sheetName];
      //   const json = XLSX.utils.sheet_to_json(worksheet);
      //   setJsonData(JSON.stringify(json, null, 2));
      // };
    
  };

  handleDrop = (acceptedFiles, rejectedFiles, event) => {
    this.setState({
      files: acceptedFiles
    });
  }

  handleAdd = (event) => {
    var newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["dataEntryEdit"] = true;
    this.setState({openDialogs: newOpenDialogs, fileLoading: true});
    if ( this.state.sarcbobInputFileType == "excel"){
    this.handleExcelFile (this.state.files[0]);
    }
    else{ 
    this.handleCsvFile(this.state.files[0]);
    }
  }

  handleChange = (event, meta) => {
    // console.log(event, meta);
    let newObj = {...this.state};
    const target = event.target;
    let value = target.value;
    if (target.type == "checkbox") {
      const name = target.name;
      newObj[name] = meta;
    } else {
      const name = target.id || target.name;
      newObj[name] = value;
    }
    this.setState({ ...newObj });
  }

  updateAddButtonAvailability(){
    if(this.state.sarcbobInputFileKindId == null || this.state.data == null){
      this.setState({uploadDisabled: false});
    } else if (this.state.sarcbobInputFileType == null || this.state.data == null){
      this.setState({uploadDisabled: false});
    } else{
      this.setState({uploadDisabled: true});
    }
  }

  render(){
    
    const options = (this.state.sarcbobFileKind) ? this.state.sarcbobFileKind.map(sbfk => {
      return {value: sbfk.id, label: sbfk.name}
    }) : [];

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Datei hochladen</DialogTitle>
        <DialogContent>
          <DropZone
            onDrop={this.handleDrop}
          />
          <TextField
            margin="dense"
            id="sarcbobInputFileType"
            label="Excel || CSV"
            value={this.state.sarcbobInputFileType}
            fullWidth
            variant="outlined"
            handleChange={this.handleChange}
            type="select"
            options={[{value: "csv", label: "CSV"},{value: "excel", label: "Excel"}]}
            size="small"
          />
          <TextField
            margin="dense"
            id="sarcbobInputFileKindId"
            label="Dateiart"
            value={this.state.sarcbobInputFileKindId}
            fullWidth
            variant="outlined"
            handleChange={this.handleChange}
            type="select"
            options={options}
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary" data-cy="file_upload_cancel">
            Abbrechen
          </Button>
          <LoadingButton 
            disabled={((this.state.fileLoading === true) || (this.state.files.length != 1) || (this.state.sarcbobInputFileKindId == null ) || (this.state.sarcbobInputFileType == null ) || (this.state.sarcbobInputFileKindId == undefined) || (this.state.sarcbobInputFileType == null ))}
            onClick={this.handleAdd}
            //onClick={console.log(this.state.sarcbobFileKind[this.state.sarcbobInputFileKindId])}
            color="primary"
            data-cy="file_upload_accept"
            loading={this.state.fileLoading}
          >
            Hochladen
          </LoadingButton>
        </DialogActions>
        {(this.state.openDialogs.dataEntryEdit && this.state.fileLoading == false) &&
          <DataEntryEditDialog
            name={"Datei hochladen"}
            open={this.state.openDialogs.dataEntryEdit && this.state.fileLoading == false}
            handleClose={() => this.handleClose("dataEntryEdit")}
            sarcbobInputFileKindId={this.state.sarcbobInputFileKindId}
            sarcbobInputFileType={this.state.sarcbobInputFileType}
            handleSave={this.props.handleSave}
            patients={this.props.patients}
            fileContent={this.state.fileContent}
            user={this.user}
          />
        }
      </Dialog>
    )
  }
}


export default SarcBobFileUploadDialog;
