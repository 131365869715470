import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
// import { createTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { AbilityContext } from './ability-context.jsx';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import { Can } from './Can';
import Moment from 'moment'

import { save } from 'save-file';
// import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
// import Select from 'react-select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CheckingIcon from '@mui/icons-material/ContentPasteSearch';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import LoadingButton from '@mui/lab/LoadingButton';

// import EcrfUserFields from './EcrfUserFields.jsx';
// import EcrfApplicationFields from './EcrfApplicationFields.jsx';
import NewDialog from './NewDialog.jsx';
import EditDialog from './EditDialog.jsx';
import ViewDialog from './ViewDialog.jsx';
import SarcBobFileUploadDialog from './SarcBobFileUploadDialog.jsx';
import SearchBar from './SearchBar.jsx';
import ListOfFiles from './ListOfFiles.jsx';
import ListOfEcrfApplications from './ListOfEcrfApplications.jsx';
import { ImportContactsOutlined, ThreeSixty, ViewInArOutlined } from '@mui/icons-material';
import EnhancedTable from './EnhancedTable.jsx';
import SarcPatientFields from './SarcPatientFields.jsx';
import HeroesAyaNewPatientFields from './HeroesAyaNewPatientFields.jsx';
import SarcPatientDiagnosisFields from './SarcPatientDiagnosisFields.jsx';
import HeroesAyaPatientDataFields from './HeroesAyaPatientDataFields.jsx';
import SarcPatientPTnmFields from './SarcPatientPTnmFields.jsx';
import SarcPatientStadiumFields from './SarcPatientStadiumFields.jsx';
import SarcPatientStudyIdentifierFields from './SarcPatientStudyIdentifierFields.jsx';
import SarcPatientOperationFields from './SarcPatientOperationFields.jsx';
import SarcPatientSystemicTherapyFields from './SarcPatientSystemicTherapyFields.jsx';
import SarcPatientMiscellaneousTherapyFields from './SarcPatientMiscellaneousTherapyFields.jsx';
import SarcPatientRadiotherapyFields from './SarcPatientRadiotherapyFields.jsx';
import SarcPatientNuclearTherapyFields from './SarcPatientNuclearTherapyFields.jsx';
import SarcPatientProgressionFields from './SarcPatientProgressionFields.jsx';
import SarcPatientVitalStatusFields from './SarcPatientVitalStatusFields.jsx';
import SarcPatientSocioFields from './SarcPatientSocioFields.jsx';
import SarcbopProEortcFields from './SarcbopProEortcFields.jsx';
import SarcbopProDashFields from './SarcbopProDashFields.jsx';
import SarcbopProMstsuFields from './SarcbopProMstsuFields.jsx';
import SarcbopProMstsoFields from './SarcbopProMstsoFields.jsx';
import SarcbopProOdiFields from './SarcbopProOdiFields.jsx';
import SarcbopProOhsFields from './SarcbopProOhsFields.jsx';
import SarcbopProOksFields from './SarcbopProOksFields.jsx';
import SarcbopProEfasFields from './SarcbopProEfasFields.jsx';
import SarcbopProFields from './SarcbopProFields.jsx';
import SarcPatientBloodSampleFields from './SarcPatientBloodSampleFields.jsx';
import SarcPatientGeneChecklistFields from './SarcPatientGeneChecklistFields.jsx';
import SarcTissueSampleFields from './SarcTissueSampleFields.jsx';
import SarcPatientBadoQuestionnaireFields from './SarcPatientBadoQuestionnaireFields.jsx';
import moment from 'moment'
import Papa from "../../../node_modules/papaparse/";
import { display } from '@mui/system';
import * as XLSX from 'xlsx';
// import DataEntryEditDialog from './DataEntryEditDialog.jsx';

const patientsColumns = [
  {
   id: "pseudonym",
   secondary: "pseudonym",
   label: "Pseudonym",
   numeric: false,
   disablePadding: false,
   searchable: true,
  },
  {
    id: "master_pseudonym",
    secondary: "master_pseudonym",
    label: "Master Pseudonym",
    numeric: false,
    disablePadding: false,
    searchable: true,
   },
  {
    id: "heroes_aya",
    label: "HEROES-AYA",
    type: "heroes_aya_case",
  },
  {
    id: "heroes_aya_patient_data_count",
    label: "HEROES-AYA Patienten Daten",
    type: "heroes_aya_patient",
  },
  // {
  //   id: "count_therapy_data",
  //   secondary: "data2",
  //   label: "Therapien",
  //   type: "patientData",
  // },
  // {
  //   id: "count_radiology_data",
  //   secondary: "data2",
  //   label: "Radiologie",
  //   type: "patientData",
  // },
  {
    id: "sarc_diagnoses_count",
    label: "Diagnosen",
    type: "diagnosis",
  },
  {
    id: "sarc_p_tnm_inquiries_count",
    label: "pTNM",
    type: "p_tnm",
  },
  {
    id: "sarc_stadia_count",
    label: "Stadien",
    type: "stadia",
  },
  {
    id: "sarc_operations_count",
    label: "Operationen",
    type: "surgery"
  },
  {
    id: "sarc_systemic_therapies_count",
    label: "Systemische Therapien",
    type: "systemic_therapy"
  },
  {
    id: "sarc_miscellaneous_therapies_count",
    label: "Sonstige Therapien",
    type: "miscellaneous_therapy"
  },
  {
    id: "sarc_radiotherapies_count",
    label: "Strahlen­therapien",
    type: "radiotherapy"
  },
  {
    id: "sarc_nuclear_therapies_count",
    label: "Nuklearmed. Therapien",
    type: "nuclear_therapy"
  },
  {
    id: "sarc_progressions_count",
    label: "Verlauf",
    type: "progression"
  },
  {
    id: "vital_statuses_count",
    label: "Vital­status",
    type: "vital_status"
  },
  {
    id: "sarc_blood_samples_count",
    label: "Blut­proben",
    type: "sarc_blood_samples"
  },
  {
    id: "sarc_tissue_samples_count",
    label: "Gewebe-proben",
    type: "sarc_tissue_samples"
  },
  // {
  //   id: "count_sarc_extractions",
  //   label: "Entnahmen",
  //   type: "extraction"
  // },
  {
    id: "has_socio",
    secondary: "id",
    label: "Sozio",
    type: "socio",
  },
  {
    id: "pro_counter_sum",
    label: "PROs",
    type: "patient_reported_outcomes",
  },
  {
    id: "has_gene_checklist",
    label: "Gen Checkliste",
    type: "gene_checklist",
  },
  {
    id: "study_identifiers",
    label: "Studien Identifikator",
    type: "study_identifiers",
  },
  {
    id: "sarc_bado_questionnaires_count",
    label: "Bado Befund",
    type: "sarc_bado_questionnaires",
  },
  // {
  //   id: "sarcbob",
  //   label: "SarcBOB",
  //   type: "boolean"
  // }
 ];

class SarcBobIndex extends React.Component {

  constructor(props) {
    super(props);
    let user = this.props.user;
    this.state = {
      patients: (props.patients) ? props.patients : [],
      // patients2: props.patients2,
      user: user,
      // pro_data_registries: props.pro_data_registries,
      expanded: null,
      openDialogs: {},
      searchTerm: "",
      loading: true,
      uploadDates: [],
    };
    this.form = React.createRef();
  }

  componentDidMount(props) {
    let currentComponent = this;
    fetch('/institutions/index_all', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      currentComponent.setState({institutions: data.institutions,});
    });
    fetch('/sarcbop/get_patients_data', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      
      currentComponent.setState({patients: data.patients, uploadDates: data.upload_dates, loading: false});
      console.log(data);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState != this.state) {
      console.log(this.state)
    }
  }
  

  handlePanelChange = panel => (event, expanded) => {
    let newExpanded = expanded;
    if (!this.state.user.name) {
      newExpanded = "user_details";
    } else if (!this.state.user.submitted_file_ids && expanded == "application") {
      newExpanded = "documents";
    }
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  handleOpen = name => (event) => {
    console.log("handleOpen", name, event);
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    console.log("test");
    this.setState({openDialogs: newOpenDialogs});
  }

  handleOpenNewPatientObject = (patientId, name) => (event) => {
    console.log("handleOpen", name, patientId, event);
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = true;
    const selectedPatientIds = [patientId]
    this.setState({openDialogs: newOpenDialogs, selectedPatientIds: selectedPatientIds}, console.log("handler", this.state.selectedPatientIds));
    console.log(this.state.selectedPatientIds)
    event.stopPropagation();
  }

  handleClose = name => {
    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs[name] = false;
    this.setState({openDialogs: newOpenDialogs});
  }

  handleEditEcrfUserSave = user => {
    const currentComponent = this;
    fetch('/ecrf_management/' + user.id + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_user: user,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("editUser");
        //console.log(data.ecrf_user);
        currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich editiert.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleNewApplicationSave = application => {
    //console.log(application);
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/create_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        ecrf_application: application,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      if (data.response == 1) {
        currentComponent.handleClose("newApplication");
        //console.log(data.ecrf_user);
        currentComponent.setState({user: data.ecrf_user});
        currentComponent.props.enqueueSnackbar('Erfolgreich abgeschickt.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator.', {variant: 'error'});
      }
    });
  }

  handleFileUpload = event => {
    this.setState({user: event});
    this.handleClose("fileUpload");
  }

  handleFileDelete = file => {
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/delete_file', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        file_id: file.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      //console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleApplicationDelete = application => {
    const currentComponent = this;
    fetch('/ecrf_management/delete_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        application_id: application.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      //console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        // console.log(data);
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handleApplicationRemove = application => {
    const currentComponent = this;
    fetch('/ecrf_management/' + this.state.user.id + '/delete_application', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        application_id: application.id,
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      //console.log(data);
      if (data.response == 1) {
        // var newTrial = data.trial;
        currentComponent.setState({ user: data.ecrf_user });
        currentComponent.props.enqueueSnackbar('Erfolgreich gelöscht.', {variant: 'success'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    });
  }

  handlePatientChange = (rowData, allRowsSelected, allObjectsSelected) => {
    this.setState({
      // selectedPatients: allObjectsSelected,
      selectedPatientIds: allRowsSelected,
    }, console.log("handlePatientChange", this.state));
    // this.updatePeople(allRowsSelected);
  };

  handleChangeSubmitted = file_id => {
    const currentComponent = this;
    //console.log(this.state.user);
    if (this.state.user.submitted_file_ids && this.state.user.submitted_file_ids.includes(file_id)){
      fetch('/ecrf_management/' + this.state.user.id + '/mark_file_unsubmitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        //console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Freigabe zur Prüfung erfolgreich zurückgenommen.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    } else {
      fetch('/ecrf_management/' + this.state.user.id + '/mark_file_submitted', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          file_id: file_id,
          // trial_id: this.state.trial.id,
        }),
        credentials: 'include',
      })
      .then(function(response){return response.json();})
      .then(function(data){
        //console.log(data);
        if (data.response == 1) {
          // var newTrial = data.trial;
          currentComponent.setState({ user: data.ecrf_user });
          currentComponent.props.enqueueSnackbar('Erfolgreich zur Prüfung freigegeben.', {variant: 'success'});
        } else {
          currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        }
      });
    }
  }

  handleEditOpen = () => {
    this.handleOpen("editPatient");
  }

  handleSave = (obj, tableName) => {
    const currentComponent = this;
    console.log(obj, tableName);

    fetch('/sarcbop_patient_data/save_objects', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        obj,
        tableName,
      }),

      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(handledData){
      //onsole.log(handledData);
      if (handledData.response == 1) {
        //console.log(handledData);
        currentComponent.props.enqueueSnackbar("Alles korrekt gespeichert.", {variant: 'success'});
        // currentComponent.props.handleFileUpload(handledData);
        let newOpenDialogs = {... currentComponent.state.openDialogs};
        newOpenDialogs["fileUpload"] = false;
        currentComponent.setState({ openDialogs: newOpenDialogs});
        currentComponent.handleClose("dataEntryEdit");
        currentComponent.handleClose("fileUpload");
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    })
  };

  handleEditPatientOpen = (event, obj) => {
    window.location.assign('/sarcbop/patient_overview/' + obj.id );
    /* const selectedPatientIds = [obj.id];
    const selectedPatients = [obj];

    //console.log(obj);

    let newOpenDialogs = {... this.state.openDialogs};
    newOpenDialogs["editPatient"] = true;
    // this.setState({openDialogs: newOpenDialogs});
    this.setState({selectedPatients, selectedPatientIds, openDialogs: newOpenDialogs}) */
  }

  handleViewDiagnosis = (selectedPatientId) => {
    let currentComponent = this;

    const getDiagnosesData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarc_diagnoses', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({diagnosisData: data.result.sarc_diagnoses});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewDiagnosis"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });

  }
  handleHeroeayaPatientData = (selectedPatientId) => {
    let currentComponent = this;
    console.log(selectedPatientId);
    const getDiagnosesData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_heroesaya_patient_data', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({diagnosisData: data.result.sarc_diagnoses});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["heroeayaPatientData"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });

  }

  handleViewPatientData = (selectedPatientId, tableName, viewName, stateName) => {
    let currentComponent = this;

    const getDiagnosesData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/' + tableName + '/get_patient_data', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      // currentComponent.setState({patientData: data.result.patient_data});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs[viewName] = true;
      currentComponent.setState({[stateName]: data.result[tableName], openDialogs: newOpenDialogs});
    });

  }

  handleViewPTnm = (selectedPatientId) => {
    let currentComponent = this;

    const getPTnmData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarc_p_tnm_inquiries', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data)
      // data.result.sarc_p_tnm_inquiries.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({pTnmData: data.result.sarc_p_tnm_inquiries});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewPTnm"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });

  }

  handleViewStadium = (selectedPatientId) => {
    let currentComponent = this;

    const getStadiumData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarc_stadia', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({stadiumData: data.result.sarc_stadia});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewStadium"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }
  handleViewStudyIdentifier = (selectedPatientId) => {
    let currentComponent = this;

    const getStadiumData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/study_identifiers', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({studyIdentifierData: data.result.study_identifiers});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewStudyIdentifier"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }

  handleViewOperation = (selectedPatientId) => {
    let currentComponent = this;

    const getStadiumData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarc_operations', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data);
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({operationData: data.result});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewOperation"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }

  handleViewSystemicTherapy = (selectedPatientId) => {
    let currentComponent = this;

    const getStadiumData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarc_systemic_therapies', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({systemicTherapyData: data.result.sarc_systemic_therapies});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewSystemicTherapy"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }

  handleViewMiscellaneousTherapy = (selectedPatientId) => {
    let currentComponent = this;

    const getStadiumData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarc_miscellaneous_therapies', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({miscellaneousTherapyData: data.result.sarc_miscellaneous_therapies});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewMiscellaneousTherapy"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }

  handleViewRadiotherapy = (selectedPatientId) => {
    let currentComponent = this;

    const getStadiumData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarc_radiotherapies', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({radiotherapyData: data.result.sarc_radiotherapies});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewRadiotherapy"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }

  handleViewNuclearTherapy = (selectedPatientId) => {
    let currentComponent = this;

    const getStadiumData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarc_nuclear_therapies', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({nuclearTherapyData: data.result.sarc_nuclear_therapies});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewNuclearTherapy"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }

  handleViewBadoQuestionnaire = (selectedPatientId) => {
    let currentComponent = this;

    const getBadoData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarc_bado_questionnaires', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({badoData: data.result.sarc_bado_questionnaires});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewBadoQuestionnaire"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }

  handleViewProgression = (selectedPatientId) => {
    let currentComponent = this;

    const getStadiumData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarc_progressions', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({progressionData: data.result.sarc_progressions});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewProgression"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }

  handleViewVitalStatus = (selectedPatientId) => {
    let currentComponent = this;

    const getStadiumData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_vital_statuses', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({vitalStatusData: data.result.vital_statuses});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewVitalStatus"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }

  handleViewBloodSample = (selectedPatientId) => {
    let currentComponent = this;

    const getStadiumData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarcbop_blood_samples', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({bloodSampleData: data.result.sarc_blood_samples});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewBloodSample"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }
  handleViewObjects = (selectedPatientId, tableName, viewName) => {
    let currentComponent = this;
    // console.log("handleViewObjects", selectedPatientId, tableName, viewName)
    fetch('/sarcbop_patient_data/' + selectedPatientId + '/' + 'get_objects', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        tableName: tableName
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs[viewName] = true;
      var result_objects = [];
      if (Array.isArray(tableName)) {
        tableName.forEach(tn => {
          console.log(tn, data.result[tn]);
          result_objects = result_objects.concat(data.result[tn]);
        })
      } else {
        if (tableName == "sarc_socios") {
          result_objects = [data.result["sarc_socio"]];
        } else if (tableName == "sarcbop_gene_checklists") {
          result_objects = [data.result["sarcbop_gene_checklist"]];
        } else {
          result_objects = data.result[tableName];
        }
      }
      console.log(data.result);
      currentComponent.setState({objectData: result_objects, openDialogs: newOpenDialogs, primaries: data.primaries});
    });
  }

  handleViewTissueSample = (selectedPatientId) => {
    let currentComponent = this;
    fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarc_tissue_samples', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      currentComponent.setState({SampleData: data.result.sarc_tissue_samples});
  
      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewTissueSample"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs});
    });
  }

  handleViewSocio = (selectedPatientId) => {
    let currentComponent = this;

    const getSocioData = fetch('/sarcbop_patient_data/' + selectedPatientId +  '/get_sarc_socios', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){return data;});

    const allocateData = async () => {
      var data = await getSocioData;

      if(data.result.children_under_7
        || data.result.children_between_7_and_13
        || data.result.children_between_13_and_21
        || data.result.children_over_21
      ) {
        data.result.no_children = false;
      } else {
        data.result.no_children = true;
      }

      currentComponent.setState({socioData: data.result.sarc_socios});
      // console.log(currentComponent.state);

      let newOpenDialogs = {... this.state.openDialogs};
      newOpenDialogs["viewSocio"] = true;
      currentComponent.setState({openDialogs: newOpenDialogs})
    }

    allocateData(); 
  }

  handleViewPRO = (selectedPatientId) => {
    let currentComponent = this;
    //console.log(selectedPatientId)//, currentComponent.state.patients.find(x => x.sarc_patient_id = selectedPatientId));
    //console.log(currentComponent.state.patients.find(x => x.id == selectedPatientId));

    const getPROData = fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_pro_data_registries', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
     
    })
    .then(function(response){return response.json();})
    .then(function(data){return data;});

    const allocateData = async () => {
      var data = await getPROData;
      data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      let newOpenDialogs = {... this.state.openDialogs};
      newOpenDialogs["viewPRO"] = true;
      currentComponent.setState({proData: data.result, openDialogs: newOpenDialogs})
    }
    // console.log(getPROData)
    allocateData(); 
  }

  handleViewGeneChecklist = (selectedPatientId) => {
    let currentComponent = this;
    //console.log(selectedPatientId)//, currentComponent.state.patients.find(x => x.sarc_patient_id = selectedPatientId));
    //console.log(currentComponent.state.patients.find(x => x.id == selectedPatientId));

    fetch('/sarcbop_patient_data/' + selectedPatientId + '/get_sarcbop_gene_checklists', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      // console.log(data);
      // data.result.map(x => {return x.pseudonym = currentComponent.state.patients.find(x => x.id == selectedPatientId).pseudonym});

      let newOpenDialogs = {... currentComponent.state.openDialogs};
      newOpenDialogs["viewGeneChecklist"] = true;
      currentComponent.setState({geneChecklistData: data.result, openDialogs: newOpenDialogs})
    });
  }

  handleEditPatientSave = (obj) => {
    const currentComponent = this;

    fetch('/sarcbob_patient_data/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        obj: obj,
      }),

      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(handledData){
      if (handledData.response == 1) {
        currentComponent.props.enqueueSnackbar("Alles korrekt gespeichert.", {variant: 'success'});
        // currentComponent.props.handleFileUpload(handledData);
        var patients = [... currentComponent.state.patients];
        const updatedPatientIndex = currentComponent.state.patients.findIndex((obj) => handledData.patient.id === obj.id);
        patients[updatedPatientIndex] = handledData.patient;
        currentComponent.setState({patients});
        currentComponent.handleClose("editPatient");
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    })
  };
  handleSaveHeroesayaNewPatient = (obj) => {
    const currentComponent = this;
    console.log(obj);
    fetch('/sarcbob_patient_data/create_heroesaya', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        obj: obj,
        sarc_patient_id: this.state.selectedPatientIds[0],
      }),

      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(handledData){
      if (handledData.response == 1) {
        currentComponent.props.enqueueSnackbar("Alles korrekt gespeichert.", {variant: 'success'});
        let newOpenDialogs = { ... currentComponent.state.openDialogs };
        newOpenDialogs['heroeayaNewPatientData'] = false;
        currentComponent.setState({ openDialogs: newOpenDialogs });
        currentComponent.handleClose("heroeayaNewPatientData");
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        // currentComponent.handleClose("heroeayaPatientData");
      }
    })
  };
  handleEditHeroesayaPatientSave = (obj) => {
    const currentComponent = this;
    console.log();
    fetch('/sarcbob_patient_data/update_heroesaya', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        obj: obj,
      }),

      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(handledData){
      if (handledData.response == 1) {
        currentComponent.props.enqueueSnackbar("Alles korrekt gespeichert.", {variant: 'success'});
        let newOpenDialogs = { ... currentComponent.state.openDialogs };
        newOpenDialogs['heroeayaPatientData'] = false;
        currentComponent.setState({ openDialogs: newOpenDialogs });
        currentComponent.handleClose("heroeayaPatientData");
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
        // currentComponent.handleClose("heroeayaPatientData");
      }
    })
  };

  handleAddPatientSave = (obj) => {
    const currentComponent = this;

    fetch('/sarcbob_patient_data/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        sarc_patient: obj,
      }),

      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(handledData){
      if (handledData.response == 1) {
        currentComponent.props.enqueueSnackbar("Alles korrekt gespeichert.", {variant: 'success'});
        // currentComponent.props.handleFileUpload(handledData);
        // var patients = [... currentComponent.state.patients];
        // patients.push(handledData.new_patient);
        // currentComponent.setState({patients});
        let newOpenDialogs = { ... currentComponent.state.openDialogs };
        newOpenDialogs['addPatient'] = false;
        currentComponent.setState({ openDialogs: newOpenDialogs });
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein Fehler aufgetreten.', {variant: 'error'});
      }
    })

  }

  handleChange = (test, meta) => {
    console.log(test, meta);
  }

  onSearchChange = event => {
    this.setState({searchTerm: event.target.value});
    // this.handleFilterUpdate();
  }

  handleSearchClear = event => {
    this.setState({searchTerm: ""});
    // this.handleFilterUpdate();
  }

  handleSarcPatientReceived = message => {
    console.log("received Sarc Patient");
    console.log(message);
    if (message.new_sarc_patient) {
      var newPatients = [...this.state.patients];
      newPatients.push(message.new_sarc_patient);
      this.setState({ patients: newPatients });
      this.props.enqueueSnackbar('Ein Patient wurde von Benutzer ' + message.username + ' hinzugefügt.', {variant: 'success'});
    }
    if (message.sarc_patient) {
      var newPatients = [...this.state.patients];
      const patientIndex = newPatients.findIndex(pat => pat.id == message.sarc_patient.id);
      newPatients[patientIndex] = message.sarc_patient;
      this.setState({ patients: newPatients });
      this.props.enqueueSnackbar('Ein Patient wurde von Benutzer ' + message.username + ' editiert.', {variant: 'success'});
    }
    if (message.new_sarc_patients) {
      var newPatients = [...this.state.patients];
      message.new_sarc_patients.forEach((pat, ind) => {
        newPatients.push(pat);
      })
      this.setState({ patients: newPatients });
      this.props.enqueueSnackbar('Einige Patienten wurden von Benutzer ' + message.username + ' hinzugefügt.', {variant: 'success'});
    }
    if (message.sarc_patients) {
      var newPatients = message.sarc_patients;
      this.setState({ patients: newPatients });
      this.props.enqueueSnackbar('Einige Patienten wurden von Benutzer ' + message.username + ' editiert.', {variant: 'success'});
    }
    if (message.sarcbob_file_kind) {
      var newUploadDates = {...this.state.uploadDates};
      newUploadDates[message.sarcbob_file_kind.table_name] = message.sarcbob_file_kind.last_upload_date;
      this.setState({ uploadDates: newUploadDates });
      // this.props.enqueueSnackbar('Einige  wurden von Benutzer ' + message.username + ' editiert.', {variant: 'success'});
    }
    if (message.deleted_sarc_patient_ids) {
      const newPatients = this.state.patients.filter(pat => !message.deleted_sarc_patient_ids.includes(pat.id));
      const newSelectedPatientIds = this.state.selectedPatientIds.filter(i_d => !message.deleted_sarc_patient_ids.includes(i_d));
      const deletedPatientPseudonyms = this.state.patients.filter(pat => message.deleted_sarc_patient_ids.includes(pat.id)).map(pat => pat.pseudonym);
      this.setState({ patients: newPatients, selectedPatientIds: newSelectedPatientIds }, console.log("received", this.state));
      this.props.enqueueSnackbar('Patienten wurden von Benutzer ' + message.username + ' gelöscht: ' + deletedPatientPseudonyms, {variant: 'success'});
    }
  }

  render() {
    const { openDialogs } = this.state;
    const {obj} = this.props;
    // const requiredFields = ["name", "first_name", "gender", "email", "institution_name", "street", "zip", "city", "consent"];
    // const disabledFields = ["date_submitted", "status"]a
    // const hiddenFields = ["username"];
    // const rolesOptions = [{value: "TestRolle1", label: "TestRolle1"}, {value: "TestRolle2", label: "TestRolle2"}, {value: "TestRolle3", label: "TestRolle3"}, {value: "TestRolle4", label: "TestRolle4"}]
    // const functionsOptions = [{value: "Prüfstelle", label: "Prüfstelle"}, {value: "TestFunction2", label: "TestFunction2"}, {value: "TestFunction3", label: "TestFunction3"}, {value: "TestFunction4", label: "TestFunction4"}]
    // const sitesOptions = [{value: "TestSite1", label: "TestSite1"}, {value: "TestSite2", label: "TestSite2"}, {value: "TestSite3", label: "TestSite3"}, {value: "TestSite4", label: "TestSite4"}]
    // const statusOptions = [{value: "applied", label: "Eingereicht"}, {value: "granted", label: "Genehmigt"}, {value: "denied", label: "Abgelehnt"}, {value: "TestSite4", label: "TestSite4"}]
    // const applicationSelectOptions = {trial_id: trialsOptions, role: rolesOptions, site: sitesOptions, function: functionsOptions, status: statusOptions}

    const requiredPatientFields = ["gender"];
    const requiredOnePatientFields = [["master_pseudonym", "pseudonym"]];

    const patients = (this.state.patients) ? this.state.patients.map(pat => {
      return { ...pat, pro_data_registries_count: pat.sarcbop_pro_eortcs_count + pat.sarcbop_pro_dashes_count + pat.sarcbop_pro_ohs_count + pat.sarcbop_pro_oks_count + pat.sarcbop_pro_efas_count + pat.sarcbop_pro_odis_count + pat.sarcbop_pro_mstsos_count + pat.sarcbop_pro_mstsus_count }
    }) : [];

    const diagnosisSelectableSheetNumbers = (this.state.diagnosisData)
      ? this.state.diagnosisData.map(x => { return { value: x.tumorid, label: x.tumorid } })
      : [];
    const objectOptions  = (this.state.objectData && this.state.primaries)
    ? this.state.objectData.map(obj => {
      var labelStr
      var value 
      console.log(Object.keys(obj).includes("ohs_01"));
      if (Object.keys(obj).includes("msts_u1")) {
        // labelArr = "mstsu" && "dash" && "efas" && "eortc" && "mstso" && "odi" && "ohs" && "oks"
        labelStr = "mstsu"
        labelStr = labelStr + this.state.primaries.map(primary_str => { 
          if (primary_str == 'fb_date') {
              return (moment(obj[primary_str]).format("DD.MM.YYYY"));
          } else {
            return obj[primary_str]
          }
        }).join(' - ');
      } 
      else if (Object.keys(obj).includes("efas_01")) {
        labelStr = "efas"
        labelStr = labelStr + this.state.primaries.map(primary_str => { 
          if (primary_str == 'fb_date') {
              return (moment(obj[primary_str]).format("DD.MM.YYYY"));
          } else {
            return obj[primary_str]
          }
        }).join(' - ');

      }else if (Object.keys(obj).includes("eortc_phys")) {
        labelStr = "eortc"
        labelStr = labelStr + this.state.primaries.map((primary_str) => { 
          if (primary_str == 'fb_date') {
              return (moment(obj[primary_str]).format("DD.MM.YYYY"));
          } else {
            return obj[primary_str]
          }
        }).join(' - ');
      }
       else if (Object.keys(obj).includes("dash_01")) {
        labelStr = "dash"
        labelStr = labelStr + this.state.primaries.map(primary_str => { 
          if (primary_str == 'fb_date') {
              return (moment(obj[primary_str]).format("DD.MM.YYYY"));
          } else {
            return obj[primary_str]
          }
        }).join(' - ');
      }else if (Object.keys(obj).includes("msts_o1")) {
        labelStr = "mstso"
        labelStr = labelStr + this.state.primaries.map(primary_str => { 
          if (primary_str == 'fb_date') {
              return (moment(obj[primary_str]).format("DD.MM.YYYY"));
          } else {
            return obj[primary_str]
          }
        }).join(' - ');
      } else if (Object.keys(obj).includes("odi_01")) {
        labelStr = "odi"
        labelStr = labelStr + this.state.primaries.map(primary_str => { 
          if (primary_str == 'fb_date') {
              return (moment(obj[primary_str]).format("DD.MM.YYYY"));
          } else {
            return obj[primary_str]
          }
        }).join(' - ');
      } else if (Object.keys(obj).includes("ohs_01")) {
        labelStr = "ohs"
        labelStr = labelStr + this.state.primaries.map(primary_str => { 
          if (primary_str == 'fb_date') {
              return (moment(obj[primary_str]).format("DD.MM.YYYY"));
          } else {
            return obj[primary_str]
          }
        }).join(' - ');
      } else if (Object.keys(obj).includes("oks_01")) {
        labelStr = "oks"
        // labelStr = labelStr + this.state.primaries.map(primary_str => obj[primary_str]).join(' - ');
        labelStr = labelStr + this.state.primaries.map(primary_str => { 
          if (primary_str == 'fb_date') {
              return (moment(obj[primary_str]).format("DD.MM.YYYY"));
          } else {
            return obj[primary_str]
          }
        }).join(' - ');
      }
      // sarc_blood_samples
       else if (Object.keys(obj).includes("blood_sample_status")) {
        labelStr = obj.sampleid
        labelStr = labelStr + " - " + (moment(obj.acceptance_date).format('DD.MM.YYYY'));

      }
      // sarc_tissue_samples
      else if (Object.keys(obj).includes("sample_status")) {
        labelStr = obj.sampleid
        if (obj.arrival_time != null) {
          labelStr = labelStr + " - " +  moment(obj.arrival_time).format('DD.MM.YYYY');
        }
        

      }
      // systemische therapien
      else if (Object.keys(obj).includes("therapy_type")) {
        labelStr = obj.tumorid
        labelStr = labelStr + " - " + ((obj.begin) ? moment(obj.begin).format('DD.MM.YYYY') : null);
        
      }
      // sarc_stadia
      else if (Object.keys(obj).includes("c_t")) {
        labelStr = obj.tumorid
        labelStr = labelStr + " - " + (moment(obj.date_stadium).format('DD.MM.YYYY'));

      }
      // sarc_diagnoses
      else if (Object.keys(obj).includes("effective_date")) {
        labelStr = obj.tumorid
        labelStr = labelStr + " - " + (moment(obj.diagnosis_date).format('DD.MM.YYYY'));

      }
      // sarc_p_tnm_inquiries
      else if (Object.keys(obj).includes("y")) {
        labelStr = obj.tumorid
        labelStr = labelStr + " - " + (moment(obj.histology_date).format('DD.MM.YYYY'));

      }
      // sarc_miscellaneous_therapies
      else if (Object.keys(obj).includes("intention")) {
        labelStr = obj.tumorid
        labelStr = labelStr + " - " + (moment(obj.begin).format('DD-MM-YYYY'));

      }
      // sarc_operations
      else if (Object.keys(obj).includes("ops_1_id")) {
        labelStr = obj.tumorid
        labelStr = labelStr + " - " + (moment(obj.op_date).format('DD.MM.YYYY'));

      }
      // sarc_radiotherapies
      else if (Object.keys(obj).includes("radiated_tumor_3")) {
        labelStr = obj.tumorid
        labelStr = labelStr + " - " + ((obj.begin) ? moment(obj.begin).format('DD-MM-YYYY') : null) + ' - ' + ((obj.end) ? moment(obj.end).format('DD.MM.YYYY') : null);
        
      }
      // sarc_nuclear_therapies
      else if (Object.keys(obj).includes("route_of_administration")) {
        labelStr = obj.tumorid
        labelStr = labelStr + " - " + (moment(obj.begin).format('DD.MM.YYYY'));

      }
      // sarc_progressions
      else if (Object.keys(obj).includes("overall_assessment")) {
        labelStr = obj.tumorid
        labelStr = labelStr + " - " + (moment(obj.examination_date).format('DD.MM.YYYY'));

      }else if (Object.keys(obj).includes("remark")) {
        labelStr = obj.advice_offered
        // objectOptions={display: "none"};
        labelStr = labelStr + this.state.primaries.map(primary_str => obj[primary_str]).join(' - ');
      } else {
        console.log(obj);
        labelStr = this.state.primaries.map(primary_str => obj[primary_str]).join(' - ');
      }
      return { value: labelStr + obj.id, label: labelStr}
    }) : [];
    console.log(obj, this.state.objectData, this.state.primaries, objectOptions)
    const pTnmSelectableSheetNumbers = (this.state.pTnmData)
      ? this.state.pTnmData.map(x => { return { value: x.histology_date, label: x.histology_date } })
      : [];
    console.log(this.state.stadiumData);
    const stadiumSelectableProcedureNumbers = (this.state.stadiumData)
      ? this.state.stadiumData.map(x => { return { value: x.date_stadium, label: x.date_stadium } })
      : [];
    const operationSelectableProcedureNumbers = (this.state.operationData)
      ? this.state.operationData.sarc_operations.map(x => { return { value: x.procedureid, label: x.procedureid } })
      : [];
    const systemicTherapySelectableBegins = (this.state.systemicTherapyData)
      ? this.state.systemicTherapyData.map(x => { return { value: x.begin, label: x.begin } })
      : [];
    const miscellaneousTherapySelectableProcedureNumbers = (this.state.miscellaneousTherapyData)
      ? this.state.miscellaneousTherapyData.map(x => { return { value: x.procedureid, label: x.procedureid } })
      : [];
    const radiotherapySelectableBegins = (this.state.radiotherapyData)
      ? this.state.radiotherapyData.map(x => { return { value: x.begin, label: x.begin } })
      : [];
    const nuclearTherapySelectableProcedureNumbers = (this.state.nuclearTherapyData)
      ? this.state.nuclearTherapyData.map(x => { return { value: x.procedureid, label: x.procedureid } })
      : [];
    const progressionExaminationDates = (this.state.progressionData)
      ? this.state.progressionData.map(x => { return { value: x.examination_date, label: x.examination_date } })
      : [];

    const proSelectableSheetNumbers = (this.state.proData)
      ? this.state.proData.map(x => { return { value: x.fb_dat, label: x.fb_dat } })
      : [];

    const bloodSampleIds = (this.state.bloodSampleData)
      ? this.state.bloodSampleData.map(x => { return { value: x.sampleid, label: x.sampleid } })
      : [];

    const tissueSampleIds = (this.state.sampleData)
      ? this.state.sampleData.map(x => { return { value: x.sampleid, label: x.sampleid } })
      : [];
    const genecheckliste = (this.state.geneChecklistData)
      ? this.state.geneChecklistData.map(x => { return { value: x.advice_offered, label: x.advice_offered } })
      : [];

    const studyIdentifierSelectableProcedureNumbers = (this.state.studyIdentifierData)
      ? this.state.studyIdentifierData.map(x => { return { value: x.studiencockpitId, label: x.studiencockpitId } })
      : [];

    const institutionOptions = (this.state.institutions) ? this.state.institutions.map(institution => {
      return {label: institution.name, value: institution.id}
    }) : null;

    const selectedPatients = (this.state.selectedPatientIds) ? this.state.patients.filter(pat => this.state.selectedPatientIds.includes(pat.id)) : [];

    const  filteredPatients = (this.state.patients) ? this.state.patients.filter(x => {
      var ret = false;
      
      if(x.pseudonym != null){
        if(x.pseudonym.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
        ret = true;
      }
      }
      if(x.master_pseudonym != null){
        if(x.master_pseudonym.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
          ret = true;
        }
      } 
      
      return ret;
    
    }) : [];
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <ActionCableConsumer
          channel={{channel: "SarcPatientsChannel"}}
          onReceived={this.handleSarcPatientReceived}
          // onConnected={this.handleConnected}
        />
        {/* <ActionCableConsumer
          channel={{channel: "EcrfUsersChannel"}}
          onReceived={this.handleEcrfUserReceived}
        /> */}
        <Grid item xs={12}>
          <SearchBar
            onChange={this.onSearchChange}
            handleClear={this.handleSearchClear}
            searchTerm={this.state.searchTerm}
          />
        </Grid>
        <Grid item xs={12}>
          {/* {console.log(this.state.patients.filter(x => (x.pseudonym != null) ? x.pseudonym.toLowerCase().includes(this.state.searchTerm.toLowerCase()) : ""))} */}
          <EnhancedTable
            title={"Patienten"}
            data={filteredPatients}
            // data={patients.filter(x => (x.pseudonym != null) ? x.pseudonym.toLowerCase().includes(this.state.searchTerm.toLowerCase()) : patients)}
            // singleSelect={!this.state.multipleSelectMode}
            // multipleSelectable
            selectedIds={this.state.selectedPatientIds}
            selectedObjects={selectedPatients}
            columns={patientsColumns}
            onRowsSelect={(rowData, allRowsSelected, allObjectsSelected) => this.handlePatientChange(rowData, allRowsSelected, allObjectsSelected)}
            // handleMailTo={this.handleMailToDepartments}
            // handleAddClick={handleAddDepartmentOpen}
            // handleEditClick={this.handleEditDepartmentClick}
            // // handleRemoveClick={handleRemoveDepartmentClick}
            // handleDeleteClick={this.handleDeleteDepartmentClick}
            // handleFilterClick={this.handleDepartmentsFilterClick}
            // filters={this.state.departmentFilters}
            // onFilterChange={this.handleDepartmentsFilterChange}
            rowsPerPage={25}
            onDoubleClick={this.handleEditPatientOpen}
            handleViewObjects={this.handleViewObjects}
            handleViewDiagnosis={this.handleViewDiagnosis}
            handleHeroeayaPatientData={this.handleHeroeayaPatientData}
            handleSaveHeroesayaNewPatient={this.handleSaveHeroesayaNewPatient}
            handleEditHeroesayaPatientSave={this.handleEditHeroesayaPatientSave}
            handleViewPTnm={this.handleViewPTnm}
            handleViewPatientData={this.handleViewPatientData}
            handleViewStadium={this.handleViewStadium}
            handleViewOperation={this.handleViewOperation}
            handleViewSystemicTherapy={this.handleViewSystemicTherapy}
            handleViewMiscellaneousTherapy={this.handleViewMiscellaneousTherapy}
            handleViewRadiotherapy={this.handleViewRadiotherapy}
            handleViewNuclearTherapy={this.handleViewNuclearTherapy}
            handleViewProgression={this.handleViewProgression}
            handleViewVitalStatus={this.handleViewVitalStatus}
            handleViewBloodSample={this.handleViewBloodSample}
            handleViewTissueSample={this.handleViewTissueSample}
            handleViewSocio={this.handleViewSocio}
            handleViewPRO={this.handleViewPRO}
            handleViewGeneChecklist={this.handleViewGeneChecklist}
            handleViewStudyIdentifier={this.handleViewStudyIdentifier}
            // handleNewObjectClick={this.handleOpen("heroeayaNewPatientData")}
            handleOpenNewObject={this.handleOpenNewPatientObject}
            // markedIds={this.state.selectedPeopleDepartmentsIds}
            orderBy="pseudonym"
            order="asc"
            selectable  
            autoFill
            expanded={true}
            handleExpandChange={this.handleExpandChange}
            handleUploadClick={this.handleOpen("fileUpload")}
            handleAddClick={this.handleOpen("addPatient")}
            handleAddClickHeroesAya={this.handleOpen("addHeroesAyaPatient")}
            objectType="SarcPatient"
            loading={this.state.loading}
            // superType="SarcPatients"
            // handleParentSelectClick={this.handleDepartmentParentSelectClick}
            uploadDates={this.state.uploadDates}
          />
          {(this.state.openDialogs.fileUpload) &&
          <SarcBobFileUploadDialog
            name={"Datei hochladen"}
            obj={{}}
            open={this.state.openDialogs.fileUpload}
            handleClose={() => this.handleClose("fileUpload")}
            handleSave={this.handleSave}
            patients={this.state.patients}
            // pro_data_registries={this.state.pro_data_registries}
            user={this.state.user}
          />
          }
          {(this.state.openDialogs.editPatient) &&
          <ViewDialog
            title="Patient editieren"
            obj={selectedPatients[0] || {}}
            open={this.state.openDialogs.editPatient}
            // handleSave={this.handleEditPatientSave}
            handleClose={() => this.handleClose("editPatient")}
            Fields={SarcPatientFields}
            // requiredFields={requiredPatientFields}
            requiredFields={requiredPatientFields}
          />
          }
          {(this.state.openDialogs.viewDiagnosis) &&
          <ViewDialog
            title="Neoplasie-Diagnosen"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewDiagnosis}
            handleClose={() => this.handleClose("viewDiagnosis")}
            Fields={SarcPatientDiagnosisFields}
            options={objectOptions}
            selectionBoxTitle="Tumor-ID"
            selectionVariableName="tumorid"
            maxWidth="lg"
          />
          }
          {(this.state.openDialogs.heroeayaPatientData) &&
          <ViewDialog
            title="HeroesAya Patienten Daten"
            obj={this.state.objectData}
            open={this.state.openDialogs.heroeayaPatientData}
            handleSave={this.handleEditHeroesayaPatientSave}
            handleOpenNewObject={this.handleOpen("heroeayaNewPatientData")}
            handleClose={() => this.handleClose("heroeayaPatientData")}
            Fields={HeroesAyaPatientDataFields}
            options={objectOptions}
            selectionBoxTitle="OP Datum"
            selectionVariableName="op_date"
            maxWidth="lg"
          />
          }
          {(this.state.openDialogs.heroeayaNewPatientData) &&
          <NewDialog
            title="HeroesAya Patienten Daten"
            open={this.state.openDialogs.heroeayaNewPatientData}
            obj={{new_patient:true}}
            handleSave={this.handleSaveHeroesayaNewPatient}
            handleClose={() => this.handleClose("heroeayaNewPatientData")}
            Fields={HeroesAyaPatientDataFields}
            options={objectOptions}
            maxWidth="lg"
          />
          }
          {/* <EditDialog
            title="HeroesAya Patient hinzufügen"
            open={this.state.openDialogs.addHeroesAyaPatient}
            handleSave={this.handleAddPatientSave}
            handleClose={() => this.handleClose("addHeroesAyaPatient")}
            Fields={SarcPatientFieldsHeroesAya}
            requiredFields={requiredPatientFieldsHeroesAya}
            selectOptions={{institutionOptions: institutionOptions || [], diagnosisOptions: []}}
          /> */}
          {(this.state.openDialogs.viewPTnm) &&
          <ViewDialog
            title="Erhebung pTNM"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewPTnm}
            handleClose={() => this.handleClose("viewPTnm")}
            Fields={SarcPatientPTnmFields}
            options={objectOptions}
            selectionBoxTitle="Erhebungsdatum"
            selectionVariableName="histology_date"
          />
          }
          {(this.state.openDialogs.viewStadium) &&
          <ViewDialog
            title="Stadien"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewStadium}
            handleClose={() => this.handleClose("viewStadium")}
            Fields={SarcPatientStadiumFields}
            options={objectOptions}
            selectionBoxTitle="Tumor-ID"
            selectionVariableName="tumorid"
          />
          }
          {(this.state.openDialogs.viewStudyIdentifier) &&
          <ViewDialog
            title="Studien Identifikator"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewStudyIdentifier}
            handleClose={() => this.handleClose("viewStudyIdentifier")}
            Fields={SarcPatientStudyIdentifierFields}
            options={objectOptions}
            selectionBoxTitle="STUDIE"
            selectionVariableName="studiencockpitId"
          />
          }
          {(this.state.openDialogs.viewBadoQuestionnaire) &&
          <ViewDialog
            title="Bado Befund"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewBadoQuestionnaire}
            handleClose={() => this.handleClose("viewBadoQuestionnaire")}
            Fields={SarcPatientBadoQuestionnaireFields}
            options={objectOptions}
            selectionBoxTitle="PATNR"
            selectionVariableName="patnr"
          />
          }
          {(this.state.openDialogs.viewOperation) &&
          <ViewDialog
            title="Operationen"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewOperation}
            handleClose={() => this.handleClose("viewOperation")}
            Fields={SarcPatientOperationFields}
            options={objectOptions}
            selectionBoxTitle="Prozedur-ID"
            selectionVariableName="procedureid"
            maxWidth="lg"
            // fullScreen
          />
          }
          {(this.state.openDialogs.viewSystemicTherapy) &&
          <ViewDialog
            title="Systemische Therapie"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewSystemicTherapy}
            handleClose={() => this.handleClose("viewSystemicTherapy")}
            Fields={SarcPatientSystemicTherapyFields}
            options={objectOptions}
            selectionBoxTitle="Beginn-Datum"
            selectionVariableName="begin"
            maxWidth="lg"
            // fullScreen
          />
          }
          {(this.state.openDialogs.viewMiscellaneousTherapy) &&
          <ViewDialog
            title="Sonstige Therapie"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewMiscellaneousTherapy}
            handleClose={() => this.handleClose("viewMiscellaneousTherapy")}
            Fields={SarcPatientMiscellaneousTherapyFields}
            options={objectOptions}
            selectionBoxTitle="Prozedur-ID"
            selectionVariableName="procedureid"
          />
          }
          {(this.state.openDialogs.viewRadiotherapy) &&
          <ViewDialog
            title="Strahlentherapie"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewRadiotherapy}
            handleClose={() => this.handleClose("viewRadiotherapy")}
            Fields={SarcPatientRadiotherapyFields}
            options={objectOptions}
            selectionBoxTitle="Beginn-Datum"
            selectionVariableName="begin"
            maxWidth="lg"
          />
          }
          {(this.state.openDialogs.viewNuclearTherapy) &&
          <ViewDialog
            title="Nuklearmedizinische Therapie"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewNuclearTherapy}
            handleClose={() => this.handleClose("viewNuclearTherapy")}
            Fields={SarcPatientNuclearTherapyFields}
            options={objectOptions}
            selectionBoxTitle="Prozedur-ID"
            selectionVariableName="procedureid"
          />
          }
          {(this.state.openDialogs.viewProgression) &&
          <ViewDialog
            title="Verlauf"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewProgression}
            handleClose={() => this.handleClose("viewProgression")}
            Fields={SarcPatientProgressionFields}
            options={objectOptions}
            selectionBoxTitle="Untersuchungsdat"
            selectionVariableName="examination_date"
            maxWidth="lg"
            // fullScreen
          />
          }
          {(this.state.openDialogs.viewVitalStatus) &&
          <ViewDialog
            title="Vitalstatus"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewVitalStatus}
            handleClose={() => this.handleClose("viewVitalStatus")}
            Fields={SarcPatientVitalStatusFields}
          />
          }
          {(this.state.openDialogs.viewSocio) &&
          <ViewDialog
            title="Soziofragebogen"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewSocio}
            handleClose={() => this.handleClose("viewSocio")}
            Fields={SarcPatientSocioFields}
            options={objectOptions}
            selectionBoxTitle="Ausgabedatum"
            selectionVariableName="issue_date"
          />
          }
          {(this.state.openDialogs.viewPRO) &&
          <ViewDialog
            title="Patient Reported Outcome"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewPRO}
            handleClose={() => this.handleClose("viewPRO")}
            // Fields={[SarcPatientPRODashFields, SarcPatientPROEfasFields, SarcPatientPROEortcFields, SarcPatientPROMstsuFields, SarcPatientPROMstsoFields, SarcPatientPROOdiFields, SarcPatientPROOhsFields, SarcPatientPROOksFields]}
            Fields={SarcbopProFields}
            // Fields1={SarcPatientPROEfasFields}
            // Fields2={SarcPatientPROEortcFields}
            // Fields3={SarcPatientPROMstsuFields}
            // Fields4={SarcPatientPROMstsoFields}
            // Fields5={SarcPatientPROOdiFields}
            // Fields6={SarcPatientPROOhsFields}
            // Fields7={SarcPatientPROOksFields}
            options={objectOptions}
            selectionBoxTitle="FB-Datum"
            selectionVariableName="fb_dat"
            // fullScreen
            maxWidth="lg"
          />
          }
          {(this.state.openDialogs.viewBloodSample) &&
          <ViewDialog
            title="Blutproben"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewBloodSample}
            handleClose={() => this.handleClose("viewBloodSample")}
            Fields={SarcPatientBloodSampleFields}
            options={objectOptions}
            selectionBoxTitle="Proben_ID"
            selectionVariableName="sampleid"
            maxWidth="lg"
          />
          }
          {(this.state.openDialogs.viewTissueSample) &&
          <ViewDialog
            title="Gewebeproben"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewTissueSample}
            handleClose={() => this.handleClose("viewTissueSample")}
            Fields={SarcTissueSampleFields}
            options={objectOptions}
            selectionBoxTitle="Proben-ID"
            selectionVariableName="sampleid"
            maxWidth="lg"
          />
          }
          {(this.state.openDialogs.viewGeneChecklist) &&
          <ViewDialog
            title="Genetischer Fragebogen"
            obj={this.state.objectData}
            open={this.state.openDialogs.viewGeneChecklist}
            handleClose={() => this.handleClose("viewGeneChecklist")}
            Fields={SarcPatientGeneChecklistFields}
            options={objectOptions}
            // selectionBoxTitle="Beratung Angeboten"
          />
          }
          {(this.state.openDialogs.addPatient) &&
          <EditDialog
            title="Patient hinzufügen"
            open={this.state.openDialogs.addPatient}
            handleSave={this.handleAddPatientSave}
            handleClose={() => this.handleClose("addPatient")}
            Fields={SarcPatientFields}
            requiredFields={requiredPatientFields}
            requiredOneFields={requiredOnePatientFields}
            selectOptions={{institutionOptions: institutionOptions || [], diagnosisOptions: []}}
            handleChange={this.handleChange}
          />
          }
          {/* {(this.state.openDialogs.addHeroesAyaPatient) &&
          <EditDialog
            title="HeroesAya Patient hinzufügen"
            open={this.state.openDialogs.addHeroesAyaPatient}
            handleSave={this.handleAddPatientSave}
            handleClose={() => this.handleClose("addHeroesAyaPatient")}
            Fields={HeroesAyaNewPatientFields}
            requiredFields={requiredPatientFields}
            requiredOneFields={requiredOnePatientFields}
            selectOptions={{institutionOptions: institutionOptions || [], diagnosisOptions: []}}
            handleChange={this.handleChange}
          />
          } */}
         
        </Grid>
      </Grid>
    )
  }
}

SarcBobIndex.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default withTheme(withSnackbar(SarcBobIndex));
