import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import TrialSelect from './TrialSelect.jsx';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function EcrfUserFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, readOnly } = props;
  console.log(obj, readOnly);
  const configurationStatusOptions = [{value: "in preparation", label: "in Vorbereitung", tooltip: "Antragsteller bereitet Antrag/Daten vor"}, {value: "checking", label: "zu prüfen durch DM", tooltip: "Datensatz muss von DM geprüft werden"}, {value: "user interaction necessary", label: "User Eingabe nötig", tooltip: "Antragsteller muss Antrag überarbeiten"}, {value: "all complete", label: "Alles vollständig", tooltip: "Alle Angaben und Nachweise der Person sind vollständig"}, {value: "misc", label: "Sonstiges", tooltip: "Bitte wenden Sie sich an das DM für Fragen."}];

  return (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={6}>
            <TrialSelect 
              selectedTrialId={obj.trial_id}
              style={{zIndex: 990}}
              // disabled={true}
              noSubmit
              handleChange={handleChange}
            />
            <TextField
              margin="dense"
              id="status"
              label="Status"
              value={obj.status}
              fullWidth
              variant="outlined"
              style={{zIndex: 989}}
              handleChange={handleChange}
              required={props.requiredFields && props.requiredFields.includes("person_status")}
              InputProps={{
                readOnly: readOnly,
              }}
              type="select"
              options={props.selectOptions.status}
              size="small"
              data-cy="status"
            />
            <TextField
              margin="dense"
              id="marvin_version"
              label="Marvin Version"
              value={obj.marvin_version}
              fullWidth
              variant="outlined"
              style={{zIndex: 988}}
              handleChange={handleChange}
              required={props.requiredFields && props.requiredFields.includes("marvin_version")}
              InputProps={{
                readOnly: readOnly,
              }}
              type="string"
              size="small"
              data-cy="marvin_version"
            />
            <Typography style={{color: 'red', fontSize: '20px'}}>
              Hinweis: Studien können nur in TOPAS angelegt und editiert werden. Sie müssen erst in die User-Verwaltung importiert werden, um sie nutzen zu können. Die Liste der Studien ist hier:
              <br/>
              <Link href="/trials" >
                http://w010-topas.inet.dkfz-heidelberg.de/trials
              </Link>
            </Typography>
          </Grid>
        </Grid>
  );
}
