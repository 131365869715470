import React from 'react';
import { Page, Text, View, Document, Image, Font, StyleSheet } from '@react-pdf/renderer';
// import ReactPdfTable from "react-pdf-table";
import logo from '../../assets/images/nct-heidelberg.jpg';
import checkmark from '../../assets/images/green_checkmark.png';

// Font.register({ family: 'Calibri', src: source });

const styles = StyleSheet.create({
  em:{
    fontStyle: 'bold'
  }, 
  table: {
    width: '100%',
    borderWidth: 2,
    display: 'flex',
    flexDirection: 'column',
    marginVertical: 12
  },
  tableRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap'
  },
  leftcell: {
    width: '30%',
    textAlign: 'left',
    fontSize: 12,
    padding: '5px',
  },
  rightcell: {
    width: '70%',
    textAlign: 'left',
    fontSize: 12,
    padding: '5px',
  }
})

export const EcrfApplicationPDF = props => {
  return (
    <Document title={"Antrag auf Studienzugang"}>
      <Page size="A4" style={{paddingLeft: '40px', paddingRight: '40px', paddingBottom: '20px'}}>
        <View style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingRight: '10px', paddingTop: '10px'}} fixed>
          <Image src={logo} alt="NCT Studienzentrale" style={{width: 197, height:50}}/>
        </View>
        <View style={{}}>
          <Text style={{fontSize: 13, fontFamily: 'Helvetica-Bold'}}>Formular Zugangsantrag für</Text>
          <Text style={{fontSize: 12, fontFamily: 'Helvetica-Bold'}}>eCRF Marvin der NCT Studienzentrale Heidelberg</Text>
          <Text style={{fontSize: 10, fontFamily: 'Helvetica-Bold', paddingTop: '10px', paddingBottom: '0px'}}>Persönliche Daten</Text>
        </View>
        <View style={styles.table} >
          {/* <View style={[styles.tableRow, styles.header]}>
            <Text style={[styles.headerText, styles.cell]}>Column 1 Header</Text>
            <Text style={[styles.headerText, styles.cell]}>Column 2 Header</Text>
            <Text style={[styles.headerText, styles.cell]}>Column 3 Header</Text>
            <Text style={[styles.headerText, styles.cell]}>Column 4 Header</Text>
          </View> */}
          <View style={[styles.tableRow]}>
            <Text style={[styles.cell, styles.leftcell]}>Antragsteller</Text>
            <Text style={[styles.cell, styles.rightcell]}>{((props.user.title) ? props.user.title : "") + props.user.first_name + " " + props.user.name}</Text>
          </View>
          <View style={[styles.tableRow]}>
            <Text style={[styles.cell, styles.leftcell]}>E-Mailadresse</Text>
            <Text style={[styles.cell, styles.rightcell]}>{props.user.email}</Text>
          </View>
          <View style={[styles.tableRow]}>
            <Text style={[styles.cell, styles.leftcell]}>Adresse</Text>
            <Text style={[styles.cell, styles.rightcell]}>{props.user.institution_name + ((props.user.department_name) ? "\n" + props.user.department_name : "") + "\n" + props.user.street + "\n" + props.user.zip + " " + props.user.city}</Text>
          </View>
          <View style={[styles.tableRow]}>
            <Text style={[styles.cell, styles.leftcell]}>Einverständnis erteilt</Text>
            <Image src={checkmark} style={{padding: '1px', marginLeft: '10px', width: '25px', height: '25px'}} />
          </View>
        </View>
        <View style={{}}>
          <Text style={{fontSize: 10, fontFamily: 'Helvetica-Bold'}}>Studiendaten</Text>
        </View>
        <View style={styles.table}>
          <View style={[styles.tableRow]}>
            <Text style={[styles.cell, styles.leftcell]}>Akronym der Studie</Text>
            <Text style={[styles.cell, styles.rightcell]}>{props.obj.ecrf_trial_configuration.short}</Text>
          </View>
          <View style={[styles.tableRow]}>
            <Text style={[styles.cell, styles.leftcell]}>Prüfstelle</Text>
            <Text style={[styles.cell, styles.rightcell]}>{(props.obj.site && props.obj.site.inst_description) ? props.obj.site.inst_description : "n.z."}</Text>
          </View>
          <View style={[styles.tableRow]}>
            <Text style={[styles.cell, styles.leftcell]}>Funktion in Studie</Text>
            <Text style={[styles.cell, styles.rightcell]}>{props.obj.ecrf_trial_function.name}</Text>
          </View>
          <View style={[styles.tableRow]}>
            <Text style={[styles.cell, styles.leftcell]}>Rolle in Studie</Text>
            <Text style={[styles.cell, styles.rightcell]}>{props.obj.ecrf_trial_role.name}</Text>
          </View>
        </View>
        <View style={{}}>
          <Text style={{fontSize: 10, fontFamily: 'Helvetica-Bold', paddingTop: '10px'}}>Hintergründe der Verarbeitung personenbezogener Daten</Text>
          <Text style={{fontSize: 9, fontFamily: 'Helvetica', paddingTop: '5px'}}>
            Die NCT Studienzentrale speichert Ihre übermittelten, personenbezogenen Daten (Name, Vorname) und Informationen über Ihren Arbeitsplatz (Adresse der Arbeitsstätte, Email Adresse), um Ihren personenbezogenen Zugang zum eCRF in MARVIN einzurichten und zur Kontaktaufnahme bei Rückfragen. Außerdem nutzt die NCT Studienzentrale Ihre Email Adresse, um Ihnen Informationen bzgl. des eCRFs in MARVIN zu übermitteln.
          </Text>
          <Text style={{fontSize: 9, fontFamily: 'Helvetica', paddingTop: '5px'}}>
            Ihre personenbezogenen Daten werden im Einklang mit den Bestimmungen der DSGVO und dem Bundesdatenschutzgesetz (BDSG) verarbeitet.
          </Text>
          <Text style={{fontSize: 10, fontFamily: 'Helvetica-Bold', paddingTop: '10px'}}>Protokollierung</Text>
          <Text style={{fontSize: 9, fontFamily: 'Helvetica', paddingTop: '5px'}}>
            MARVIN und das eCRF Userverwaltungstool erheben und speichern Daten über jeden User zu dessen Einrichtung, Löschung und Änderung des Accounts. In den Audit trails werden der durchführende Nutzer und alle Transaktionen mit Datum und Uhrzeit der Operation gespeichert.
          </Text>
          <Text style={{fontSize: 10, fontFamily: 'Helvetica-Bold', paddingTop: '10px'}}>Rechte auf Auskunft, Berichtigung, Löschung und Sperrung, Widerspruchsrecht</Text>
          <Text style={{fontSize: 9, fontFamily: 'Helvetica', paddingTop: '5px'}}>
          Gemäß DSGVO sind Sie berechtigt, Auskunft, Berichtigung oder Aktualisierung Ihrer personenbezogenen Daten zu verlangen. Sie können jederzeit von Ihrem Widerspruchsrecht Gebrauch machen und die erteilte Einwilligungserklärung abändern oder widerrufen. Sie können den Widerruf postalisch oder per Fax an den eCRF Account Support der NCT Studienzentrale übermitteln. Sobald der Widerspruch eingegangen ist, wird Ihr Zugang zu den eCRFs in MARVIN deaktiviert. Einige erhobene Daten bleiben aber erhalten, um den gesetzlichen Vorgaben zur Sicherstellung der GCP-konformen Dokumentation der Daten aus klinischen Studien zu genügen (siehe EU CTR 536/2014, EU MDR 2017/745, EU IVDR 2017/746 und AMG).
          </Text>
          <Text style={{fontSize: 10, fontFamily: 'Helvetica-Bold', paddingTop: '10px'}}>Vertraulichkeitserklärung</Text>
          <Text style={{fontSize: 9, fontFamily: 'Helvetica', paddingTop: '5px'}}>
          Ich verpflichte mich dazu, sämtliche Zugriffsinformationen für die Nutzung von eCRFs in MARVIN der NCT Studienzentrale Heidelberg absolut vertraulich zu behandeln und nicht an andere Personen weiterzugeben. Ich verpflichte mich sämtliche Informationen aus dem eCRF, insbesondere die Patientendaten gewissenhaft und streng vertraulich zu behandeln, d. h. sie insbesondere nicht unbefugten Dritten zugänglich zu machen und sie nicht für andere Zwecke zu vervielfältigen oder zu verarbeiten. Meine elektronische Unterschrift im System entspricht einer handschriftlichen Unterschrift. Mir ist bekannt, dass bei einem eventuellen Verstoß der Account umgehend und ohne Vorankündigung bis zur Klärung gesperrt werden kann.
          </Text>
          <Text style={{fontSize: 10, fontFamily: 'Helvetica'}}>
            Ich bin mit der oben beschriebenen Speicherung meiner persönlichen Daten und Aktivitäten gemäß gesetzlicher Vorgaben einverstanden.
          </Text>
          <Text style={{fontSize: 10, fontFamily: 'Helvetica', paddingTop: '15px'}}>
            Datum: ____________________ Unterschrift: ______________________________________________________
          </Text>

        </View>
        <Text style={{fontSize: 8, fontFamily: 'Helvetica-Bold', paddingTop: '5px', position: "absolute", left: 0, right: 0, bottom: 30, textAlign: 'center'}} fixed >
          Version 1.2
        </Text>
      </Page>
    </Document>
  );
};