import React, { useState } from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import UndoIcon from '@mui/icons-material/Undo';

export default function AuditTrailObjectFields(props) {
  // const classes = useStyles();
  const { obj, currentObject, handleChange, handleSelectChange, readOnly } = props;

  // var currentComponent = this;
  // console.log(props);
  console.log(obj);

  const [current_object, set_current_object] = useState(obj.current_object);
  const object_before = obj.object_before;
  if(object_before){
    delete object_before.created_at;
    delete object_before.updated_at;
  }
  if(current_object){
    delete current_object.created_at;
    delete current_object.updated_at;
  }
 
  
  const current_obj_arr_val = current_object!= null? Object.values(current_object): [];
  const obj_before_arr_val = object_before!=null? Object.values(object_before): [];
  // const current_obj_arr_keys = Object.keys(current_object);
  // const obj_before_arr_keys = Object.keys(object_before);
  const check = current_obj_arr_val.map((item, i) => {
    if(item != obj_before_arr_val[i]){
      return i;
    }
  });
  console.log(check);
  // const diff = obj_before_arr_val.filter((element) => !current_obj_arr_val.includes(element));
  // console.log(diff);
  const handleRevertData = function (key) {
  // this.setState({current_value: object_before[key]});
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={6}>
            <h4>Current Object</h4>
            {
              (current_object) && Object.keys(current_object).map((key, i) => {
                // const current_value = current_object[key];
                return(
                  // <Grid key={i} xs={6}>
                    <TextField
                      id="current_object"
                      label={key}
                      value={current_object[key] != null? current_object[key]: ""}
                      fullWidth
                      variant="outlined"
                      style={{margin:2}}
                      // onChange={handleChange}
                      // error={!obj.name}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      size="small"
                    />
                    
                  // </Grid>
                  
                )
              })
            }
          </Grid>
        
          <Grid xs={6}>
            <h4>Object Before</h4>
            {
              (object_before) && Object.keys(object_before).map((key, i) => {
                return(
                  <Box style = {{display: "flex", justifyContent: "space-between"}}>
                  <>
                    <UndoIcon style = {{display: (check.includes(i)) ? "block" : "none"}} onClick={() => handleRevertData(key)} color="primary"  variant="contained">
                      Ruckgängig
                    </UndoIcon> 
                    <TextField
                      id={"object_before" + i}
                      label={key}
                      value={object_before[key] != null? object_before[key]: ""}
                      fullWidth
                      variant="outlined"
                      style={{margin:2, backgroundColor: (check.includes(i)) ? 'yellow' : ''} }
                      // onChange={handleChange}
                      // error={!obj.name}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      size="small"
                    />
                    
                </>
                </Box>
                  
                )
              })
            }
          </Grid>
          
          {/* {
            Object.keys(object_changes).map((key, i) => {
              // console.log(key, i);
              return(
                <Grid key={i}>
                  <TextField
                id="current_object"
                label={key}
                value={current_object[key]}
                fullWidth
                variant="outlined"
                style={{zIndex: 1}}
                // onChange={handleChange}
                // error={!obj.name}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                size="small"
              />
                </Grid>
                
              )
              })
          } */}
          {/* <Grid item xs={12}> */}
             {/* <Paper sx={{p: 5, borderRadius: 2, margin: 1, padding: 2}} elevation={4}><pre><code>Current_Object:{(JSON.stringify(obj[1], null, 1)).replace(/{|}|\[|\]|"|,/gi, " ")}</code></pre></Paper>
            <Paper sx={{p: 2, borderRadius: 2, margin: 1}} elevation={4}><pre><code>Object_before:<br></br>{obj[0][0].object}</code></pre></Paper>
            <Paper sx={{p: 2, borderRadius: 2, margin: 1}} elevation={4}><pre><code>Object_changes:<br></br>{obj[0][0].object_changes}</code></pre></Paper>  */}
           
          {/* </Grid> */}
        </Grid>
      </Box>
  );
  
}
