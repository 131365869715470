import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';

import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';

export default function SarcPatientSystemicTherapyFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, readOnly } = props;

  var currentComponent = this;

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
        >
        {/* <TextField
          margin="dense"
          id="pseudonym"
          label="Pseudonym"
          value={obj.pseudonym}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        /> */}
        <TextField
          margin="dense"
          id="tumorid"
          label="Tumor-ID"
          value={obj.tumorid}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="intension"
          label="Intention"
          value={obj.intension}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="therapy_place"
          label="Therapieort"
          value={obj.therapy_place}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="therapy_type"
          label="Therapieart"
          value={obj.therapy_type}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="operation_role"
          label="Stellung Op"
          value={obj.operation_role}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="begin"
          label="Beginn-Datum"
          value={moment(obj.begin).format("YYYY-MM-DD")}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="date"
          size="small"
        />
        <TextField
          margin="dense"
          id="end"
          label="Ende-Datum"
          value={(obj.end) ? moment(obj.end).format('DD.MM.YYYY') : null}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="end_reason"
          label="Ende Grund"
          value={obj.end_reason}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="abort_reason"
          label="Abbruchsgrund"
          value={obj.abort_reason}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="procoll"
          label="Protokoll"
          value={obj.procoll}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="substance_1"
          label="Substanz 1"
          value={obj.substance_1}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="substance_2"
          label="Substanz 2"
          value={obj.substance_2}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="substance_3"
          label="Substanz 3"
          value={obj.substance_3}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="substance_4"
          label="Substanz 4"
          value={obj.substance_4}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="substance_5"
          label="Substanz 5"
          value={obj.substance_5}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="adverse_effect_1"
          label="Nebenwirkung 1"
          value={obj.adverse_effect_1}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="miscellaneous_adverse_effect_1"
          label="Sonstige Nebenwirkung 1"
          value={obj.miscellaneous_adverse_effect_1}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="degree_1"
          label="Schweregrad 1"
          value={obj.degree_1}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="adverse_effect_2"
          label="Nebenwirkung 2"
          value={obj.adverse_effect_2}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="miscellaneous_adverse_effect_2"
          label="Sonstige Nebenwirkung 2"
          value={obj.miscellaneous_adverse_effect_2}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="degree_2"
          label="Schweregrad 2"
          value={obj.degree_2}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="adverse_effect_3"
          label="Nebenwirkung 3"
          value={obj.adverse_effect_3}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="miscellaneous_adverse_effect_3"
          label="Sonstige Nebenwirkung 3"
          value={obj.miscellaneous_adverse_effect_3}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="degree_3"
          label="Schweregrad 3"
          value={obj.degree_3}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
        <TextField
          margin="dense"
          id="adverse_effect_4"
          label="Nebenwirkung 4"
          value={obj.adverse_effect_4}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="miscellaneous_adverse_effect_4"
          label="Sonstige Nebenwirkung 4"
          value={obj.miscellaneous_adverse_effect_4}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="degree_4"
          label="Schweregrad 4"
          value={obj.degree_4}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          onChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: true,
          }}
          type="text"
          size="small"
        />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_5"
            label="Nebenwirkung 5"
            value={obj.adverse_effect_5}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_5"
            label="Sonstige Nebenwirkung 5"
            value={obj.miscellaneous_adverse_effect_5}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_5"
            label="Schweregrad 5"
            value={obj.degree_5}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_6"
            label="Nebenwirkung 6"
            value={obj.adverse_effect_6}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_6"
            label="Sonstige Nebenwirkung 6"
            value={obj.miscellaneous_adverse_effect_6}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_6"
            label="Schweregrad 6"
            value={obj.degree_6}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_7"
            label="Nebenwirkung 7"
            value={obj.adverse_effect_7}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_7"
            label="Sonstige Nebenwirkung 7"
            value={obj.miscellaneous_adverse_effect_7}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_7"
            label="Schweregrad 7"
            value={obj.degree_7}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_8"
            label="Nebenwirkung 8"
            value={obj.adverse_effect_8}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_8"
            label="Sonstige Nebenwirkung 8"
            value={obj.miscellaneous_adverse_effect_8}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_8"
            label="Schweregrad 8"
            value={obj.degree_8}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_9"
            label="Nebenwirkung 9"
            value={obj.adverse_effect_9}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_9"
            label="Sonstige Nebenwirkung 9"
            value={obj.miscellaneous_adverse_effect_9}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_9"
            label="Schweregrad 9"
            value={obj.degree_9}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_10"
            label="Nebenwirkung 10"
            value={obj.adverse_effect_10}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_10"
            label="Sonstige Nebenwirkung 10"
            value={obj.miscellaneous_adverse_effect_10}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_10"
            label="Schweregrad 10"
            value={obj.degree_10}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_11"
            label="Nebenwirkung 11"
            value={obj.adverse_effect_11}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_11"
            label="Sonstige Nebenwirkung 11"
            value={obj.miscellaneous_adverse_effect_11}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_11"
            label="Schweregrad 11"
            value={obj.degree_11}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_12"
            label="Nebenwirkung 12"
            value={obj.adverse_effect_12}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_12"
            label="Sonstige Nebenwirkung 12"
            value={obj.miscellaneous_adverse_effect_12}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_12"
            label="Schweregrad 12"
            value={obj.degree_12}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_13"
            label="Nebenwirkung 13"
            value={obj.adverse_effect_13}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_13"
            label="Sonstige Nebenwirkung 13"
            value={obj.miscellaneous_adverse_effect_13}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_13"
            label="Schweregrad 13"
            value={obj.degree_13}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_14"
            label="Nebenwirkung 14"
            value={obj.adverse_effect_14}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_14"
            label="Sonstige Nebenwirkung 14"
            value={obj.miscellaneous_adverse_effect_14}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_14"
            label="Schweregrad 14"
            value={obj.degree_14}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_15"
            label="Nebenwirkung 15"
            value={obj.adverse_effect_15}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_15"
            label="Sonstige Nebenwirkung 15"
            value={obj.miscellaneous_adverse_effect_15}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_15"
            label="Schweregrad 15"
            value={obj.degree_15}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_16"
            label="Nebenwirkung 16"
            value={obj.adverse_effect_16}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_16"
            label="Sonstige Nebenwirkung 16"
            value={obj.miscellaneous_adverse_effect_16}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_16"
            label="Schweregrad 16"
            value={obj.degree_16}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_17"
            label="Nebenwirkung 17"
            value={obj.adverse_effect_17}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_17"
            label="Sonstige Nebenwirkung 17"
            value={obj.miscellaneous_adverse_effect_17}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_17"
            label="Schweregrad 17"
            value={obj.degree_17}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_18"
            label="Nebenwirkung 18"
            value={obj.adverse_effect_18}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_18"
            label="Sonstige Nebenwirkung 18"
            value={obj.miscellaneous_adverse_effect_18}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_18"
            label="Schweregrad 18"
            value={obj.degree_18}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_19"
            label="Nebenwirkung 19"
            value={obj.adverse_effect_19}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_19"
            label="Sonstige Nebenwirkung 19"
            value={obj.miscellaneous_adverse_effect_19}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_19"
            label="Schweregrad 19"
            value={obj.degree_19}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <TextField
            margin="dense"
            id="adverse_effect_20"
            label="Nebenwirkung 20"
            value={obj.adverse_effect_20}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="miscellaneous_adverse_effect_20"
            label="Sonstige Nebenwirkung 20"
            value={obj.miscellaneous_adverse_effect_20}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
          <TextField
            margin="dense"
            id="degree_20"
            label="Schweregrad 20"
            value={obj.degree_20}
            fullWidth
            variant="outlined"
            style={{zIndex: 1}}
            onChange={handleChange}
            // error={!obj.name}
            InputProps={{
              readOnly: true,
            }}
            type="text"
            size="small"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
