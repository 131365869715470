import React from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import {withStyles} from '@mui/styles'
import { withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';

import logo from '../../assets/images/Nct_01.svg.png';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const styles = {}

class MailLogin extends React.Component {

  constructor(props) {
    super(props);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get('email');
    const message = urlParams.get('message');
    this.state = {email: email, message: message};
    this.form = React.createRef();
  }

  handleChange = event => {
    let currentComponent = this;
    const target = event.target;
    const value = target.value;
    const name = target.id;
    currentComponent.setState({ [name]: value });
  };

  handleLogin = event => {
    event.preventDefault();
    const currentComponent = this;
    fetch('/ecrf_management_login', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Transaction': 'POST Example',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      body: JSON.stringify({
        session: {
          email: this.state.email,
        },
        // trial_id: this.state.trial.id,
      }),
      credentials: 'include',
    })
    .then(function(response){return response.json();})
    .then(function(data){
      console.log(data.response);
      if (data.response == 1) {
        currentComponent.props.enqueueSnackbar('Vielen Dank, die Email mit dem persönlichen Link wurde an die angegebene Adresse verschickt. Sie können diese Seite schließen.', {variant: 'success'});
      } else if (data.response == 2) {
        currentComponent.props.enqueueSnackbar('Benutzer wurde noch nicht aktiviert. Bitte sehen Sie in ihrem E-Mail Postfach nach.', {variant: 'error'});
      } else if (data.response == 4) {
        currentComponent.props.enqueueSnackbar('Es existiert ein TOPAS-Benutzer mit dieser E-Mail!', {variant: 'error'});
      } else {
        currentComponent.props.enqueueSnackbar('Es ist ein unbekannter Fehler aufgetreten. Bitte kontaktieren Sie einen Administrator', {variant: 'error'});
      }
    });
  }

  render(props) {
    // var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var validRegex = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/
    const valid = (this.state.email) ? this.state.email.match(validRegex) : false;
    console.log(valid);
    const saveable = true;
    if (this.state.message == "expired") {
      this.props.enqueueSnackbar('Temporärer Login ist abgelaufen.', {variant: 'error'});
      this.setState({message: null});
    } else if (this.state.message == "not_allowed") {
      this.props.enqueueSnackbar('Zugriff auf andere Benutzer ist nicht erlaubt.', {variant: 'error'});
      this.setState({message: null});
    } else if (this.state.message == "does_not_exist") {
      this.props.enqueueSnackbar('Benutzer existiert nicht.', {variant: 'error'});
      this.setState({message: null});
    }
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} style={{padding: 10}} justify="center" >
          <Box display="flex" flexDirection="row" justifyContent="center" flexShrink={true} flexWrap="wrap">
            <img src={logo} alt="NCT Studienzentrale" style={{width:90, height:35}}/>
            <Typography variant="h3" color="inherit" noWrap style={{marginLeft: 5}} alignCenter>
              TOPAS eCRF-Management
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} style={{padding: 10}} justifyContent="center"  alignItems="center">
          <Box display="flex" flexDirection="row" justifyContent="center" flexShrink={true} flexWrap="wrap">
            <Typography variant="h4" color="inherit" noWrap style={{marginLeft: 5}} alignCenter>
              Herzlich Willkommen
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} style={{padding: 10}} justifyContent="center" alignItems="center">
          <Box display="flex" flexDirection="row" justifyContent="center" flexShrink={true} flexWrap="wrap">
            <Typography variant="h6" color="inherit" noWrap style={{marginLeft: 5}} alignCenter>
              Um Fortzufahren geben Sie bitte Ihre dienstliche E-Mailadresse an. Ihnen wird umgehend ein Link zugeschickt. Der Link ist für zwölf Stunden gültig.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} style={{padding: 10}} justifyContent="center" >
          <Box display="flex" flexDirection="row" justifyContent="center" flexShrink={true} flexWrap="wrap">
            <form onSubmit={this.handleLogin}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="email"
                  label="E-Mail"
                  value={this.state.email}
                  fullWidth
                  variant="outlined"
                  style={{zIndex: 1, paddingBottom: '8px', width: '600px'}}
                  onChange={this.handleChange}
                  // error={!process.name}
                  InputLabelProps={{ shrink: true }}
                  type="email"
                />
              </FormControl>
              <Box display="flex" flexDirection="row" justifyContent="space-between" flexShrink={true} flexWrap="wrap">
                <Button
                  type="submit"
                  disabled={!valid}
                  // onClick={() => this.handleLogin(this.state)}
                  variant="contained"
                  color="primary">
                    Persönlichen Link an E-Mail Adresse versenden
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} style={{padding: 10}} justifyContent="center" alignItems="center">
          <Box display="flex" flexDirection="row" justifyContent="center" flexShrink={true} flexWrap="wrap">
            <Typography style={{color: 'red', fontSize: '20px'}}>
              Hinweis: Bitte eine personenbezogene, dienstliche E-Mailadresse angeben. Für Funktionsadressen können keine Zugänge eingerichtet werden.
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center" flexShrink={true} flexWrap="wrap">
            <Typography style={{color: 'red', fontSize: '20px'}}>
              Hinweis: Es wird nie ein Passwort nötig, damit Sie nicht noch ein Passwort verwalten müssen.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(withTheme(withSnackbar(MailLogin)));