import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputBase from '@mui/material/InputBase';
import TextField from './TextField.jsx';
import moment from 'moment'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from "@mui/icons-material/Add";
import Link from '@mui/material/Link';
// import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Select from 'react-select';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function HeroesAyaPatientDataFields(props) {
  // const classes = useStyles();
  const { obj, handleChange, handleSelectChange, handleOpenNewObject } = props;

  var currentComponent = this;
  console.log(obj);
 
  // obj = obj[0];
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        
        {/* <Box
          display="flex"
          flexDirection="row"
        > */}
        
        {(obj.id >= 1) &&
        <IconButton style= {{float: "right"}} onClick={props.handleOpenNewObject}>
          <Tooltip title="Neu Daten eintragen"><AddIcon/></Tooltip>
        </IconButton>
        }
        <TextField
          margin="dense"
          id="cohort"
          label="Kohorte"
          value={obj.cohort}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <TextField
          margin="dense"
          id="diagnosis"
          label="Diagnose"
          value={obj.diagnosis}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <Box
        display="flex"
        flexDirection="row"
        >
        <TextField
          margin="dense"
          id="local_pid"
          label="Lokale Pid"
          value={obj.local_pid}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <TextField
          margin="dense"
          id="time"
          label="Zeitpunkt"
          value={obj.time}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="datetime-local"
          size="small"
        />
        </Box>
        <Box
        display="flex"
        flexDirection="row"
        >
        <TextField
          margin="dense"
          id="neoadjuvant_therapien"
          label="Neoadjuvante Therapien"
          value={obj.neoadjuvant_therapien}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
      
        <TextField
          margin="dense"
          id="op_date"
          label="OP Datum"
          value={obj.op_date}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="date"
          size="small"
        />
        </Box>
        <TextField
          margin="dense"
          id="division_tissue"
          label="Aufteilung Gewebe"
          value={obj.division_tissue}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <TextField
          margin="dense"
          id="biopsy"
          label="Biopsie"
          value={obj.biopsy}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <Box
        display="flex"
        flexDirection="row"
        >
        <TextField
          margin="dense"
          id="biopsy_resection_time"
          label="Biopsie Resektionszeit "
          value={obj.biopsy_resection_time}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <TextField
          margin="dense"
          id="biopsy_pick_up_time_op"
          label="Biopsie Abholzeit im OP"
          value={obj.biopsy_pick_up_time_op}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        </Box>
        <Box
        display="flex"
        flexDirection="row"
        >
        <TextField
          margin="dense"
          id="biopsy_fresh_frozen"
          label="Biopsie Fresh frozen (1- 4)"
          value={obj.biopsy_fresh_frozen}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <TextField
          margin="dense"
          id="biopsy_frozen_time"
          label="Biopsie Einfrierzeit"
          value={obj.biopsy_frozen_time}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        </Box>
        <TextField
          margin="dense"
          id="biopsy_pick_up_fresh_frozen"
          label="Biopsie Abholung Fresh frozen"
          value={obj.biopsy_pick_up_fresh_frozen}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        
        <TextField
          margin="dense"
          id="biopsy_vital_tissue"
          label="Biopsie Vitales Gewebe"
          value={(obj.biopsy_vital_tissue)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <Box
        display="flex"
        flexDirection="row"
        >
        <TextField
          margin="dense"
          id="biopsy_dispatch_date"
          label="Biopsie Versandt"
          value={(obj.biopsy_dispatch_date)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="date"
          size="small"
        />
        <TextField
          margin="dense"
          id="op_resecttion"
          label="OP Resektat"
          value={(obj.op_resecttion)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        </Box>
        <Box
        display="flex"
        flexDirection="row"
        >
        <TextField
          margin="dense"
          id="op_resection_time"
          label="OP Resektionszeit."
          value={(obj.op_resection_time)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <TextField
          margin="dense"
          id="op_pick_up_time_op"
          label="OP Abholzeit im OP."
          value={(obj.op_pick_up_time_op)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        </Box>
        <Box
        display="flex"
        flexDirection="row"
        >
        <TextField
          margin="dense"
          id="op_fresh_frozen"
          label="OP Fresh frozen (1-4)"
          value={(obj.op_fresh_frozen)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <TextField
          margin="dense"
          id="op_frozen_time"
          label="OP Einfrierzeit"
          value={(obj.op_frozen_time)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        </Box>
        <TextField
          margin="dense"
          id="op_vital_tissue"
          label="OP Vitales Gewebe"
          value={(obj.op_vital_tissue)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <Box
        display="flex"
        flexDirection="row"
        >
        <TextField
          margin="dense"
          id="op_dispatch_date"
          label="OP Versandt"
          value={(obj.op_dispatch_date)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="date"
          size="small"
        />
        <TextField
          margin="dense"
          id="edta_blood"
          label="EDTA Blut"
          value={(obj.edta_blood)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        </Box>
        <Box
        display="flex"
        flexDirection="row"
        >
        <TextField
          margin="dense"
          id="blood_box_number"
          label="Blut Boxnummer"
          value={(obj.blood_box_number)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <TextField
          margin="dense"
          id="blood_dispatch_date"
          label="Blut Versandt"
          value={(obj.blood_dispatch_date)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="date"
          size="small"
        />
        </Box>
        <TextField
          margin="dense"
          id="blood_paxgene"
          label="Blut Paxgene"
          value={(obj.blood_paxgene)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <TextField
          margin="dense"
          id="comment"
          label="Kommentar"
          value={(obj.comment)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="text"
          size="small"
        />
        <TextField
          margin="dense"
          id="place"
          label="Standort"
          value={(obj.place)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <TextField
          margin="dense"
          id="pcm_seeded"
          label="PCM seeded"
          value={(obj.pcm_seeded)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        />
        <TextField
          margin="dense"
          id="pcm_status"
          label="PCM status"
          value={(obj.pcm_status)}
          fullWidth
          variant="outlined"
          style={{zIndex: 1}}
          handleChange={handleChange}
          // error={!obj.name}
          InputProps={{
            readOnly: obj.new_patient ? false : true,
          }}
          type="string"
          size="small"
        /> 
      </Grid>
    </Grid>
  );
}
