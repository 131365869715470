import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@mui/styles'
import MuiTextField from '@mui/material/TextField'
// import Select from 'react-select';
import Select, { StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import MenuItem from '@mui/material/MenuItem';
import { style } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FormLabel } from '@mui/material';
import { InputAdornment } from '@mui/material';
import chroma from 'chroma-js';
import moment from 'moment';


function TextField(props) {
  // console.log(props.type)
  (props.type == "select") ? console.log(props) : null;
  
  function handleReactSelectChange(selected, meta) {
    // console.log("handleReactSelectChange");
    // console.log(selected, meta);
    const fakeEvent = {target: {type: "react-select", id: meta.name, value: Array.isArray(selected) ? selected.map(sel => sel.value) : selected.value}, targetId: (props.targetId) ? props.targetId : null}
    props.handleChange(fakeEvent )
    // const newSelected = selected.map(sel => sel.value);
    // let newObj = obj;
    // newObj[name] = newSelected;
    // setObj({...newObj});
  }

  const selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // console.log( data, isDisabled, isFocused, isSelected, props.theme.palette);
      // console.log(data.backgroundColor);
      return {
        ...styles,
        backgroundColor: (data.backgroundColor && isFocused) ? chroma(data.backgroundColor).darken().hex() : (data.backgroundColor && !isFocused) ? data.backgroundColor : (!data.backgroundColor && !isFocused) ? props.theme.palette.neutral.main : (!data.backgroundColor && isFocused) ? chroma(props.theme.palette.neutral.main).darken().hex() : "white",
        minHeight: 20,
        color: "black",
      }
    }
  }

  const formatCreate = (inputValue) => {
    return (<p> Global suchen: {inputValue}</p>);
  };
 
  const {id, value, type, handleChange, handleInputValueChange, onCreateOption, InputProps = {}, ...other } = props;
  // console.log(props);
  // console.log(props.theme);
  // console.log(selectStyles);

  if (InputProps.readOnly || props.disabled) {
    InputProps.disableUnderline = true
  }
  // if (props.style && props.backGroundColor) {
  //   const style = {...props.style, backgroundColor: props.backGroundColor}
  // }
  // console.log(style);
  // if (props.type == "checkbox") {
  //   // console.log(props.required);
  // }
  // if (props.type == "checkbox") {
  //   // console.log(props.required);
  // }
  
  if (!props.hidden) {
    // console.log("not hidden");
    // if ((type == "date") || (type == "text") || (type == "number") || ((type == "email" || type == "date" || (type == "string")) && !(InputProps.readOnly || props.disabled)) || (type == "password")) {
    if ((type == "date") || (type == "datetime-local") || (type == "text") || (type == "number") || ((type == "email" || type == "date" || type == "datetime-local" || (type == "string")) && !(InputProps.readOnly || props.disabled)) || (type == "password")) {
      if(type == "datetime-local" && (InputProps.readOnly || props.disabled)){
        return (
          <MuiTextField
            InputProps={{
              ...InputProps,
            }}
            variant="outlined"
            type="text"
            value={moment(value).format("DD.MM.YYYY, h:mm:ss")}
            sx={{padding: '2px'}}
            id={id}
            size="small"
            error={(props.required && !value)}
            onChange={(!props.disabled) ? handleChange : null}
            InputLabelProps={{ shrink: true }}
            {...other}
          />
        )
      }
      else {
        return (
          <MuiTextField
            InputProps={{
              ...InputProps,
            }}
            variant="outlined"
              // (InputProps.readOnly || props.disabled) ? "standard" : "outlined"}
            type={type}
            value={value}
            sx={{padding: '2px'}}
            // margin="dense"
            id={id}
            size="small"
            error={(props.required && !value)}
            onChange={(!props.disabled) ? handleChange : null}
            // key={id + "_" + value}
            //onClick={(e) => InputProps.readOnly && type == "email"? window.location.href = "mailto:" + e.target.value  : null }
            // style={style}
            InputLabelProps={{ shrink: true }}
            {...other}
          />
        )
      }
      
    } else if ((type == "string" || type == null || type == undefined) && (InputProps.readOnly || props.disabled)) {
      return (
        <MuiTextField
          id={id}
          size="small"
          variant="standard"
          value={value}
          sx={{padding: '2px'}}
          InputProps={{
            ...InputProps,
          }}
          InputLabelProps={{ shrink: true }}
          key={id + "_" + value}
          type="text"
          {...other}
        />
      )
    } else if (type == "email" && (InputProps.readOnly || props.disabled)) {
      return (
        <MuiTextField
          id={id}
          size="small"
          variant="standard"
          value={""}
          sx={{padding: '2px'}}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <Link href={'mailto:' + value} target="_blank" underline='hover' fullWidth>
                  <Typography>
                    {value}
                  </Typography>
                </Link>
              </InputAdornment>
            ),
            ...InputProps,
          }}
          disabled
          key={id + "_" + value}
          {...other}>
        </MuiTextField>
      );
    } else if (type == "date" && (InputProps.readOnly || props.disabled)) {
      return (
        <MuiTextField
          id={id}
          size="small"
          variant="standard"
          value={moment(value).format("DD.MM.YYYY")}
          sx={{padding: '2px'}}
          InputProps={{
            ...InputProps,
          }}
          key={id + "_" + value}
          type="text"
        />

      );
    } else if (type == "datetime-local" && (InputProps.readOnly || props.disabled)){
      return (
        <MuiTextField
          id={id}
          size="small"
          variant="standard"
          value={moment(value).format("DD.MM.YYYY, h:mm:ss")}
          sx={{padding: '2px'}}
          InputProps={{
            ...InputProps,
          }}
          key={id + "_" + value}
          type="text"
        />
      );
    } else if (type == "checkbox") {
      return (
        <FormControlLabel
          disabled={InputProps.readOnly || props.disabled}
          onChange={(!props.disabled) ? handleChange : null}
          checked={props.value}
          name={props.id}
          required={props.required}
          sx={{color: (props.required && (props.value == null || props.value == "" || props.value == false)) ? "red" : "black"}}
          control={
            <Checkbox
              onChange={(!props.disabled) ? handleChange : null}
              error={true}
            />
          }
          label={props.label}
          key={id + "_" + value}
          {...other}
        // error={true}
        />
      )
    } else if (type == "select") {
      
      let lab = null;
      let selectedOption = {};
      if (value != undefined) {
        selectedOption = (props.options && props.options.length > 0) ? props.options.find(option => option.value == value) : {};
        lab = (selectedOption) ? selectedOption.label : "";
      }
      // (props.options) ? null : console.log(props.options, props.id);
      const optionMenuItems = props.options.map(option => {
        return (
          <MenuItem value={option.value} styles={{backgroundColor: option.backgroundColor, ...option.styles}}>{option.label}</MenuItem>
        )
      });
      
      if (InputProps.readOnly || props.disabled) {
        return (
          <MuiTextField
            variant="standard"
            type={"string"}
            value={(selectedOption != null) ? selectedOption.label : null}
            id={id}
            size="small"
            sx={{padding: '2px', backgroundColor: (selectedOption != null && selectedOption.backgroundColor != null) ? selectedOption.backgroundColor : "#FFFFF"}}
            error={(props.required && !value)}
            key={id + "_" + value}
            // style={style}
            InputLabelProps={{ shrink: true }}
            // style={{backgroundColor: "black"}}
            disabled
            // {...other}
          />
        )
      } else if (props.label) {
        // console.log(selectedOption)
        return (
          <FormControl variant="outlined" margin="dense" fullWidth error={(props.required && !value)}>
            <InputLabel filled shrink margin="dense" variant="outlined" error={(props.required && !value)} required={props.required} >{props.label}</InputLabel>
            <Select
              id={id}
              name={id}
              value={(selectedOption != null) ? selectedOption : null}
              // value={{value: "closed", label: "Geschlossen", statusColor: "#FF4917"}}
              options={props.options}
              // size="small"
              required={props.required}
              // style={{...style, width: "100%"}}
              // onChange={props.onChange}
              onChange={(!props.disabled) ? handleReactSelectChange : null}
              // onChange={handleReactSelectChange}
              error={(props.required && !value)}
              sx={{padding: '2px'}}
              // {...other}
              // styles={{
              //   menu: provided => ({ ...provided, zIndex: 9999 })
              // }}
              menuPortalTarget={document.body}
              key={id + "_" + value}
              // classNames={{
              //   option: (state) =>
              //     backgroundColor: "black",
              // }}
              styles={selectStyles}
            >
              {optionMenuItems}
            </Select>
          </FormControl>
        )
      } else if (!props.label) {
        return (
          <Select
            id={id}
            name={id}
            value={(selectedOption != null) ? selectedOption : null}
            options={props.options}
            // style={{...style, width: "100%"}}
            onChange={(!props.disabled) ? handleReactSelectChange : null}
            // onChange={handleReactSelectChange}
            error={(props.required && !value)}
            styles={selectStyles}
            sx={{padding: '2px'}}
            key={id + "_" + value}
            {...other}
          >
            {optionMenuItems}
          </Select>
        )
      } else {
        console.log(props.label);
      }
    } else if (type == "cselect") {
      // console.log("cselect!");
      let lab = null;
      let selectedOption = {};
      if (value != undefined) {
        selectedOption = (props.options && props.options.length > 0) ? props.options.find(option => option.value == value) : {};
        lab = (selectedOption) ? selectedOption.label : "";
      }
      
      if (InputProps.readOnly || props.disabled) {
        return (
          <MuiTextField
            InputProps={{
              ...InputProps,
            }}
            variant="outlined"
            type={type}
            value={(selectedOption != null) ? selectedOption.label : ""}
            id={id}
            size="small"
            error={(props.required && !value)}
            sx={{padding: '2px'}}
            key={id + "_" + value}
            // style={style}
            InputLabelProps={{ shrink: true }}
            data-cy={props.dataName}
          />
        )
      } else if (props.label) {
        // console.log("has label")
        return (
          <FormControl variant="outlined" margin="dense" fullWidth error={(props.required && !value)}>
            <InputLabel filled shrink margin="dense" variant="outlined" error={(props.required && !value)} required={props.required} >{props.label}</InputLabel>
            <CreatableSelect
              value={value}
              onChange={(!props.disabled) ? handleReactSelectChange : null}
              // onChange={handleReactSelectChange}
              options={props.options}
              onCreateOption={onCreateOption}
              // formatCreateLabel={formatCreate}
              inputValue={props.inputValue}
              onInputChange={(newValue, actionMeta) => props.handleInputValueChange(newValue, actionMeta)}
              autoFocus={props.autoFocus}
              // defaultMenuIsOpen={true}
              onBlur={null}
              required={props.required}
              key={id + "_" + value}
              // data-cy={props.dataName}
              // isValidNewOption={this.showAdd}
              // {...other}
            />
            {/* <Select
              id={id}
              name={id}
              value={value}
              options={props.options}
              size="small"
              required={props.required}
              // style={{...style, width: "100%"}}
              // onChange={props.onChange}
              onChange={handleChange}
              error={(props.required && !value)}
              {...other}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
            >
              {optionMenuItems}
            </Select> */}
          </FormControl>
        )
      } else if (!props.label) {
        return (
          <Select
            id={id}
            name={id}
            value={value}
            variant="outlined"
            options={props.options}
            // style={{...style, width: "100%"}}
            onChange={(!props.disabled) ? handleReactSelectChange : null}
            // onChange={handleReactSelectChange}
            error={(props.required && !value)}
            styles={selectStyles}
            sx={{padding: '2px'}}
            key={id + "_" + value}
            {...other}
          >
            {optionMenuItems}
          </Select>
        )
      } else {
        console.log(props.label);
      }
    } else if (type == "mselect") {
      
      let lab = null;
      let selectedOption = {};
      if (value != undefined) {
        selectedOption = (props.options && props.options.length > 0) ? props.options.filter(option => (Array.isArray(value)) ? value.includes(option.value) : value == option.value) : {};
        lab = (selectedOption) ? selectedOption.label : "";
      }
      // console.log(selectedOption, props.options, value);
      if (InputProps.readOnly || props.disabled) {
        return (
          <MuiTextField
            InputProps={{
              ...InputProps,
            }}
            variant="standard"
            type={type}
            value={(selectedOption != null) ? selectedOption.label : ""}
            id={id}
            size="small"
            error={(props.required && !value)}
            // style={style}
            InputLabelProps={{ shrink: true }}
            sx={{padding: '2px'}}
            data-cy={props.dataName}
            key={id + "_" + value}
          />
        )
      } else if (props.label) {
        console.log(props.required);

        return (
          <FormControl variant="outlined" margin="dense" fullWidth error={(props.required && !value)}>
            <InputLabel filled shrink margin="dense" variant="outlined" error={(props.required && !value)} required={props.required} >{props.label}</InputLabel>
            <Select
              // defaultValue={selectedOption}
              value={selectedOption}
              isMulti={props.multiple}
              name={props.id}
              options={props.options}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(!props.disabled) ? handleReactSelectChange : null}
              // onChange={handleReactSelectChange}
              required={props.required}
              menuPortalTarget={document.body}
              styles={selectStyles}
              sx={{padding: '2px'}}
              key={id + "_" + value}
            />
            {/* <Select
              value={value}
              onChange={handleChange}
              options={props.options}
              onCreateOption={onCreateOption}
              // formatCreateLabel={formatCreate}
              inputValue={props.inputValue}
              onInputChange={(newValue, actionMeta) => props.handleInputValueChange(newValue, actionMeta)}
              autoFocus={props.autoFocus}
              // defaultMenuIsOpen={true}
              onBlur={null}
              // data-cy={props.dataName}
              // isValidNewOption={this.showAdd}
              // {...other}minheight: 20
            /> */}

          </FormControl>
        )
      } else if (!props.label) {
        return (
          <Select
            id={id}
            name={id}
            value={value}
            options={props.options}
            // style={{...style, width: "100%"}}
            onChange={(!props.disabled) ? handleReactSelectChange : null}
            // onChange={handleReactSelectChange}
            error={(props.required && !value)}
            styles={{  ...colourStyles }}
            sx={{padding: '2px'}}
            key={id + "_" + value}
            {...other}
          >
            {optionMenuItems}
          </Select>
        )
      } else {
        console.log(props.label);
      }
    }
  }
};

TextField.propTypes = {
  // classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  InputProps: PropTypes.object,
}

export default withTheme(TextField)
